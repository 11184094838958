import { Respondent } from "./respondent";
import { InjectorInstance } from "../core/injector-instance";
import { EndingPageResponseService } from "../services/ending-page-response-factory.service";
var EndingOutputBase = /** @class */ (function () {
    function EndingOutputBase(options) {
        this.$type = "";
        this.isTest = false;
        this.respondent = new Respondent();
        this.dropCookie = false;
        this.__logicHandled = false;
        options = options || {};
        this.isTest = options.isTest;
        this.respondent = new Respondent(options.respondent || {});
        this.setStatusResponseType = options.setStatusResponseType;
        this.dropCookie = options.dropCookie;
    }
    EndingOutputBase.createInstance = function (options) {
        var endingOutputDataTypeRegistryCollection = InjectorInstance.get(EndingPageResponseService);
        return endingOutputDataTypeRegistryCollection.createInstance(options);
    };
    Object.defineProperty(EndingOutputBase.prototype, "logicHandled", {
        get: function () {
            return this.__logicHandled;
        },
        enumerable: true,
        configurable: true
    });
    EndingOutputBase.prototype.hasPages = function () {
        return (function (arg) {
            return arg.pages !== undefined;
        })(this);
    };
    return EndingOutputBase;
}());
export { EndingOutputBase };
