import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";

import { Category } from "../../../../models/survey-engine/category";
import { CategoricalAnswer, CategoricalAnswerCollection, ICategoricalAnswer, ICategoricalAnswerCollection } from "../../../../models/survey-engine/core/categorical-answer";
import { MultiAnswer } from "../../../../models/survey-engine/multi-answer";
import { MultiQuestion } from "../../../../models/survey-engine/multi-question";
import { CategoricalQuestionBaseComponent } from "../core/categorical-question-base.component";

import "../../../../extensions/string-extensions";
import { Message } from "../../../../localization/message";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";

@Component({
  selector: "app-multi-question",
  templateUrl: "./multi-question.component.html",
  styleUrls: ["./multi-question.component.scss"]
})
export class MultiQuestionComponent extends CategoricalQuestionBaseComponent implements OnInit, OnDestroy {
  searchText: string;
  protected setQuestionAnswer(value: any) {
    const self = this;
    self.__questionAnswer = new MultiAnswer();
    value = value || {};
    const answer: ICategoricalAnswerCollection = new CategoricalAnswerCollection();
    Object.keys(value).forEach(code => {
      answer.add(
        new CategoricalAnswer({
          code: Number(code),
          value: "",
          checked: value[code],
          categoryId: self.getCategory(code).id
        })
      );
    });
    (self.__questionAnswer as MultiAnswer).answerType=AnswerType.Multi;
    (self.__questionAnswer as MultiAnswer).values = answer; //self.__answers;
  }
  get minAnswersErrorMessage(): string {
    return this.__minAnswersErrorMessage;
  }

  get maxAnswersErrorMessage(): string {
    return this.__maxAnswersErrorMessage;
  }

  get categories(): Array<Category> {
    const categories: Array<Category> = [];
    (this.question as MultiQuestion).categories.$values.forEach(c => {
      categories.push(c as Category);
    });
    return categories;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const self = this;

    if (self.question.response as MultiAnswer) {
      self.__answers = new CategoricalAnswerCollection((self.question.response as MultiAnswer).values);
    } else {
      self.categories.forEach((category: Category) => {
        self.__answers.$values.push(
          new CategoricalAnswer({
            categoryId: category.id,
            code: category.code
          })
        );
      });
    }

    const validators = [];
    if ((self.question as MultiQuestion).validators.required) {
      validators.push(CustomMultiValidators.required);
    }

    Object.keys((self.question as MultiQuestion).validators).forEach(validator => {
      if (validator !== "required" && (self.question as MultiQuestion).validators[validator] && CustomMultiValidators[validator]) {
        validators.push(CustomMultiValidators[validator].apply(undefined, [(self.question as MultiQuestion).validators[validator]]));
      }
    });

    self.ctrl.setValidators(validators);

    self.categories.forEach((category: Category) => {
      const answer = self.getAnswerByCategoryId(category.id);
      const ctrl: FormControl = self.getCtrl(category.code);

      ctrl.valueChanges.subscribe((value: boolean) => {
        answer.checked = value;
        if (category.isExclusive && value) {
          self.categories
            .filter((c: Category) => !c.isExclusive)
            .forEach((c: Category) => {
              const _ctrl: FormControl = self.getCtrl(c.code);
              const _answer = self.getAnswer(c.code);
              _answer.checked = false;
              _answer.value ="";
              _ctrl.setValue(false);
            });
        }
        if (!category.isExclusive && value) {
          self.categories
            .filter((c: Category) => c.isExclusive)
            .forEach((c: Category) => {
              let _ctrl: FormControl = self.getCtrl(c.code);
              const _answer = self.getAnswer(c.code);
              _answer.checked = false;
              _answer.value = "";
              _ctrl.setValue(false);
            
            });
        }
      });
      if (answer.checked) {
        ctrl.setValue(answer.checked);
      }

      if (category.isOther && self.otherCtrls) {
        const otherCtrl: FormControl = self.otherCtrls[category.code];
        otherCtrl.valueChanges.subscribe((value: string) => {
          ctrl.setValue(!!value);
          answer.value = value;
        });
        if (answer.value) {
          otherCtrl.setValue(answer.value);
        }
      }
    });
    self.__minAnswersErrorMessage = String.format(Message.MinAnswersExceededMessage,  (this.question as MultiQuestion).validators.minAnswers);
    self.__maxAnswersErrorMessage = String.format(Message.MaxAnswersExceededMessage,  (this.question as MultiQuestion).validators.maxAnswers);
    self.triggerQuestionLoaded();
  }

  getCtrl(code: number | string): FormControl {
    const self = this;
    code = code.toString();
    const fg = self.ctrl as FormGroup;
    if (fg.contains(code)) {
      return fg.get(code) as FormControl;
    }
    return new FormControl();
  }

  getAnswer(code: string | number): ICategoricalAnswer {
    const results = this.__answers.$values.filter(item => item.code === code);
    const _answer: ICategoricalAnswer = results.length ? results[0] : {};
    return _answer;
  }

  getAnswerByCategoryId(id: number): ICategoricalAnswer {
    const results = this.__answers.$values.filter(item => item.categoryId === id);
    const _answer: ICategoricalAnswer = results.length ? results[0] : {};
    return _answer;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected __answers: CategoricalAnswerCollection = new CategoricalAnswerCollection();

  private __minAnswersErrorMessage: string;
  private __maxAnswersErrorMessage: string;
}

class CustomMultiValidators {
  static required(fg: FormGroup): { [key: string]: boolean } | null {
    const valid = Object.keys(fg.controls).some(k => fg.get(k).value);
    return valid
      ? null
      : {
          required: true
        };
  }

  static minAnswers(nr: number): ValidatorFn {
    return (fg: FormGroup): { [key: string]: boolean } | null => {
      const count = Object.keys(fg.controls).filter(k => fg.get(k).value).length;
      return count >= nr
        ? null
        : {
            minAnswers: true
          };
    };
  }

  static maxAnswers(nr: number): ValidatorFn {
    return (fg: FormGroup): { [key: string]: boolean } | null => {
      const count = Object.keys(fg.controls).filter(k => fg.get(k).value).length;
      return count <= nr
        ? null
        : {
            maxAnswers: true
          };
    };
  }
}
