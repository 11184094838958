import { ErrorHandler, Injector, NgZone } from '@angular/core';
import { LoggerService } from '../services/logger.service';
import { MatSnackBar } from '@angular/material';
import { SnackBarAlertComponent } from '../snack-bar-alert/snack-bar-alert.component';
import { AlertType } from '../enums/alert-type';
import { Message } from '../localization/message';
import { Exception } from '../core/exception';
import { SessionStorageService, LocalStorageService } from 'ngx-store';
import { ErrorLoggerService, ErrorResponse } from '../services/error-logger.service';
var GlobalErrorHandler = /** @class */ (function () {
    function GlobalErrorHandler(injector, zone) {
        this.injector = injector;
        this.zone = zone;
        this.loggerService = this.injector.get(LoggerService);
        this.sessionStorageService = this.injector.get(SessionStorageService);
        this.localStorageService = this.injector.get(LocalStorageService);
        this.errorLoggerService = this.injector.get(ErrorLoggerService);
        this.snackBar = this.injector.get(MatSnackBar);
    }
    GlobalErrorHandler.prototype.handleError = function (exception) {
        var self = this;
        // in case of promise error
        if (!!exception.rejection) {
            exception = exception.rejection;
        }
        self.captureError(exception);
        if (!!exception && !!exception.data && !!exception.data.errorCode) {
            // User flow Exceptions
        }
        else {
            self.loggerService.error(exception);
        }
        var data = {
            alertType: AlertType.Error,
            text: self.isTest && exception instanceof Exception ? exception.message : Message.GenericErrorMessage,
            code: exception instanceof Exception ? exception.code : null,
            url: self.url,
            title: "Message",
            isTest: self.isTest
        };
        this.zone.run(function () {
            self.snackBar.openFromComponent(SnackBarAlertComponent, { data: data });
        });
    };
    GlobalErrorHandler.prototype.captureError = function (exception) {
        var self = this;
        var input = new ErrorResponse();
        var respondent = self.sessionStorageService.get(self.isTest ? "sc.respondent.test" : "sc.respondent");
        var user = self.localStorageService.get(self.isTest ? "sc.profile.test" : "sc.profile");
        var url = self.sessionStorageService.get("sc.landing-url");
        if (!!respondent || !!user) {
            if (!!respondent) {
                input.respondentId = respondent.id || 0;
                input.sessionId = respondent.sessionId || 0;
                input.surveyId = respondent.surveyId || 0;
            }
            if (!!user) {
                input.userId = user.userId || 0;
            }
        }
        if (!!exception.data) {
            input.errorFrom = "API";
            if (!!exception.data.errorCode) {
                input.message = exception.message || "";
                input.errorDetails = exception.details || "";
                input.errorStack = "";
            }
            else if (!!exception.data.exceptionMessage) {
                input.message = exception.data.exceptionMessage || "";
                input.errorDetails = exception.data.exceptionType || "";
                input.errorStack = exception.data.stackTrace || "";
            }
            else {
                input.message = exception.data.Message || "";
                input.errorDetails = exception.data.ExceptionMethod || exception.data.InnerException || "";
                input.errorStack = exception.data.StackTraceString || "";
            }
            if (!input.message) {
                input.message = exception.message || "";
                input.errorDetails = exception.details || "";
                input.errorStack = exception.stack || "";
            }
        }
        else {
            input.errorFrom = "UI";
            if (!exception.message) {
                input.message = exception.toString() || "";
            }
            else {
                input.message = exception.message || "";
                input.errorDetails = exception.details || "";
                input.errorStack = exception.stack || "";
            }
        }
        input.isTest = self.isTest || false;
        input.errorCode = exception.code || 0;
        input.url = url || "";
        self.errorLoggerService.saveErrorResponse(input);
    };
    Object.defineProperty(GlobalErrorHandler.prototype, "url", {
        get: function () {
            return this.isTest ? this.sessionStorageService.get("sc.landing-url") : "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GlobalErrorHandler.prototype, "isTest", {
        get: function () {
            return this.sessionStorageService.get("sc.is-test");
        },
        enumerable: true,
        configurable: true
    });
    return GlobalErrorHandler;
}());
export { GlobalErrorHandler };
