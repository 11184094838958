import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InjectorInstance } from '../core/injector-instance';
import { API_BASE } from '../core/globals';
import { LoggerService } from '../services/logger.service';
import { Utilities } from '../utils/utilities';


@Injectable({
  providedIn: 'root'
})
export class AppInsightsHttpInterceptor implements HttpInterceptor {

  constructor(
    private loggerService: LoggerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const path: string = Utilities.getRequestPath(req.url);
    this.loggerService.sendLogToAppInsights(`${req.method}_${path}`, req.body);
    return next.handle(req);
  }
}
