import { Injectable, Type } from '@angular/core';

import { ENDING_PAGE_RESPONSE_REGISTRY } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { EndingOutputBase, IEndingOutputBase } from '../models/ending-output-base';
import { SetStatusResponseType } from '../enums/ending-page-response-type.enum';


export interface IEndingOutputBaseTypeRegistry {
  key?: string;
  type?: Type<EndingOutputBase>;
}

@Injectable({
  providedIn: "root"
})
export class EndingPageResponseService {
  constructor() {
    this.__registeredTypes = InjectorInstance.get<EndingPageResponseRegistryCollection>(ENDING_PAGE_RESPONSE_REGISTRY);
  }
  createInstance(options?: IEndingOutputBase): EndingOutputBase {
    options = options || ({} as IEndingOutputBase);
    options.setStatusResponseType = options.setStatusResponseType || 0;
    let key = SetStatusResponseType[options.setStatusResponseType].toString();
    key = this.normalizedKey(key || "");
    if (!!!key || !this.__hasType(key)) {
      return null;
    }

    let type: Type<EndingOutputBase> = null;
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        type = item.type;
        return false;
      }
    });

    return new type(options);
  }

  registerType(registry?: IEndingOutputBaseTypeRegistry): boolean {
    registry = registry || {};
    registry.key = this.normalizedKey(registry.key || "");
    if (!!!registry.key) {
      return false;
    }
    if (!this.__hasType(registry.key)) {
      this.__registeredTypes.push(registry);
      return true;
    }
    return false;
  }

  private __hasType(key: string): boolean {
    let ok: boolean = false;
    key = this.normalizedKey(key || "");
    if (!!!key) {
      return false;
    }
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        ok = true;
        return false;
      }
    });

    return ok;
  }

  private normalizedKey(key: string): string {
    return key.toString().toLowerCase();
  }

  private __registeredTypes: EndingPageResponseRegistryCollection;
}

export class EndingPageResponseRegistryCollection extends Array<IEndingOutputBaseTypeRegistry> {
  constructor(array?: Array<IEndingOutputBaseTypeRegistry>) {
    super();
    Object.setPrototypeOf(this, EndingPageResponseRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as IEndingOutputBaseTypeRegistry);
    });
  }
}
