import { Injectable, Type } from '@angular/core';

import { QuestionBaseComponent } from '../components/survey-page/survey-components/core/question-base.component';
import { QUESTION_REGISTRY } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { IQuestion, QuestionBase } from '../models/survey-engine/core/question-base';
import { QuestionType } from '../models/survey-engine/core/question-type.enum';

export interface IQuestionTypeRegistry {
  key?: string;
  componentType?: Type<QuestionBaseComponent>;
  // $type?: string;
  type?: Type<QuestionBase>;
}

@Injectable({
  providedIn: "root"
})
export class QuestionFactoryService {
  constructor() {
    this.__registeredTypes = InjectorInstance.get<QuestionRegistryCollection>(QUESTION_REGISTRY);
  }
  createInstance(options?: IQuestion): QuestionBase {
    options = options || ({} as IQuestion);
    options.questionType = options.questionType || 0;
    let key = QuestionType[options.questionType].toString();
    key = this.normalizedKey(key || "");
    if (!!!key || !this.__hasType(key)) {
      return null;
    }

    let type: Type<QuestionBase> = null;
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        type = item.type;
        return false;
      }
    });

    return new type(options);
  }

  resolve(key: string): Type<QuestionBaseComponent> {
    key = this.normalizedKey(key || "");
    if (!!!key || !this.__hasType(key)) {
      return null;
    }

    let type: Type<QuestionBaseComponent> = null;
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        type = item.componentType;
        return false;
      }
    });

    return type;
  }

  registerType(registry?: IQuestionTypeRegistry): boolean {
    registry = registry || {};
    registry.key = this.normalizedKey(registry.key || "");
    if (!!!registry.key) {
      return false;
    }
    if (!this.__hasType(registry.key)) {
      this.__registeredTypes.push(registry);
      return true;
    }
    return false;
  }

  private __hasType(key: string): boolean {
    let ok: boolean = false;
    key = this.normalizedKey(key || "");
    if (!!!key) {
      return false;
    }
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        ok = true;
        return false;
      }
    });

    return ok;
  }

  private normalizedKey(key: string): string {
    return key.toString().toLowerCase();
  }

  private __registeredTypes: QuestionRegistryCollection;
}

export class QuestionRegistryCollection extends Array<IQuestionTypeRegistry> {
  constructor(array?: Array<IQuestionTypeRegistry>) {
    super();
    Object.setPrototypeOf(this, QuestionRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as IQuestionTypeRegistry);
    });
  }
}
