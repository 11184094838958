import { SampleCubeUserTypeRegistryCollection } from "../../services/samplecube-user-factory.service";
import { FedSampleCubeUser } from "../../models/fed-samplecube-user";
import { SampleCubeUser } from "../../models/samplecube-user";
var ɵ0 = function () {
    return new SampleCubeUserTypeRegistryCollection([
        {
            key: "FedSampleCubeUser",
            type: FedSampleCubeUser
        },
        {
            key: "SampleCubeUser",
            type: SampleCubeUser
        }
    ]);
};
var SampleCubeUserModule = /** @class */ (function () {
    function SampleCubeUserModule() {
    }
    return SampleCubeUserModule;
}());
export { SampleCubeUserModule };
export { ɵ0 };
