import { NgModule } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material";
import { ICON_MAP } from "./core/globals";
import {
  IconRegistryCollection,
  IIconRegistry,
  IconFactoryService
} from "./services/icon-factory.service";

@NgModule({
  providers: [
    {
      provide: ICON_MAP,
      useFactory: function() {
        return new IconRegistryCollection([
          {
            name: "info",
            path: "assets/fonts/material/baseline-info-24px.svg"
          },
          {
            name: "warning",
            path: "assets/fonts/material/baseline-report_problem-24px.svg"
          },
          {
            name: "error",
            path: "assets/fonts/material/baseline-error-24px.svg"
          }
        ]);
      }
    }
  ]
})
export class IconMapModule {

  constructor(
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private iconFactoryService: IconFactoryService
  ) {
    const self = this;

    self.init();
  }

  private init() {
    const self = this;

    self.iconFactoryService.registeredIcons.forEach((icon: IIconRegistry) => {
      self.iconRegistry.addSvgIcon(
        icon.name,
        self.sanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }
}
