import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { RECONFIRM_CONTACT_TIMEOUT, RECONFIRM_CONTACT_USE_CACHE
  , RECONFIRM_CONTACT_API_GET_URL, RECONFIRM_CONTACT_API_UPDATE_URL, RECONFIRM_CONTACT_EMAIL_PATTERN } from './core/globals';

@NgModule({
  providers: [    
    {
      provide: RECONFIRM_CONTACT_TIMEOUT,
      useValue: environment.reconfirmContact.timeout
    },
    {
      provide: RECONFIRM_CONTACT_USE_CACHE,
      useValue: environment.reconfirmContact.cache
    }, 
    {
      provide: RECONFIRM_CONTACT_API_GET_URL,
      useValue: environment.reconfirmContact.apiGetUrl
    }, 
    {
      provide: RECONFIRM_CONTACT_API_UPDATE_URL,
      useValue: environment.reconfirmContact.apiUpdateUrl
    }, 
    {
      provide: RECONFIRM_CONTACT_EMAIL_PATTERN,
      useValue: environment.reconfirmContact.emailPattern
    }
  ]
})
export class ReconfirmContactConfigModule { }
