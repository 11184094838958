import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { LoggerService } from '../services/logger.service';
import { Utilities } from '../utils/utilities';
import * as i0 from "@angular/core";
import * as i1 from "../services/logger.service";
var LoggerHttpInterceptor = /** @class */ (function () {
    function LoggerHttpInterceptor(loggerService) {
        this.loggerService = loggerService;
    }
    LoggerHttpInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var path = Utilities.getRequestPath(req.url);
        this.loggerService.log("before_" + req.method + "_" + path, { request: JSON.stringify(req.body) });
        var resp = next.handle(req);
        return resp
            .pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                var response = event;
                _this.loggerService.log("after_" + req.method + "_" + path, { request: JSON.stringify(req.body), response: JSON.stringify(response) });
            }
        }, function (error) {
            _this.loggerService.log("failed_" + req.method + "_" + path, { request: JSON.stringify(req.body), error: JSON.stringify(error) });
            _this.loggerService.error(JSON.stringify(error), JSON.stringify(req.body));
        }));
    };
    LoggerHttpInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function LoggerHttpInterceptor_Factory() { return new LoggerHttpInterceptor(i0.inject(i1.LoggerService)); }, token: LoggerHttpInterceptor, providedIn: "root" });
    return LoggerHttpInterceptor;
}());
export { LoggerHttpInterceptor };
