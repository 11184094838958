import { EndingInputData } from '../../models/ending';
import { FedData } from '../../models/fed-data';
import { PureSpectrumData } from '../../models/pure-spectrum-data';
import { SSIData } from '../../models/ssi-data';
import { EndingDataTypeRegistryCollection } from '../../services/ending-data-factory.service';
var ɵ0 = function () {
    return new EndingDataTypeRegistryCollection([
        {
            key: "FedData",
            type: FedData
        },
        {
            key: "PureSpectrumData",
            type: PureSpectrumData
        },
        {
            key: "SSIData",
            type: SSIData
        },
        {
            key: "EndingInputData",
            type: EndingInputData
        }
    ]);
};
var EndingInputDataModule = /** @class */ (function () {
    function EndingInputDataModule() {
    }
    return EndingInputDataModule;
}());
export { EndingInputDataModule };
export { ɵ0 };
