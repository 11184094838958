import { Injector } from '@angular/core';
import { AppInsightsService } from './app-insights.service';
import { InjectorInstance } from '../core/injector-instance';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
var LoggerService = /** @class */ (function () {
    function LoggerService(injector) {
        this.injector = injector;
        if (!InjectorInstance.hasInstance && this.injector) {
            InjectorInstance.setInstance(this.injector);
        }
        this.appInsightsService = InjectorInstance.get(AppInsightsService);
        this.locationService = InjectorInstance.get(Location);
    }
    LoggerService.prototype.error = function (exception) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        var params = [];
        params.push("path: " + this.path);
        params.push(exception);
        optionalParams.forEach(function (param) {
            params.push(param);
        });
        console.warn.apply(undefined, params);
        var debugData = {};
        optionalParams.forEach(function (value) {
            var key = Object.keys({ value: value })[0];
            debugData[key] = value;
        });
        this.sendErrorToAppInsights(exception, debugData);
    };
    LoggerService.prototype.log = function (message) {
        var optionalParams = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            optionalParams[_i - 1] = arguments[_i];
        }
        var params = [];
        params.push("path: " + this.path);
        params.push(message);
        optionalParams.forEach(function (param) {
            params.push(param);
        });
        console.log.apply(undefined, params);
    };
    LoggerService.prototype.trackPageView = function (name) {
        this.appInsightsService.logPageView(name, this.path);
    };
    LoggerService.prototype.sendLogToAppInsights = function (message, debugData) {
        debugData = debugData || {};
        debugData['path'] = this.path;
        this.appInsightsService.logEvent(message, debugData);
    };
    LoggerService.prototype.sendErrorToAppInsights = function (error, debugData) {
        debugData = debugData || {};
        debugData['path'] = this.path;
        this.appInsightsService.logException(error, debugData);
    };
    Object.defineProperty(LoggerService.prototype, "path", {
        get: function () {
            var path = this.locationService.path();
            var parts = path.split('?');
            if (parts.length === 2) {
                path = parts[0];
            }
            return path === '' ? '/' : path;
        },
        enumerable: true,
        configurable: true
    });
    LoggerService.ngInjectableDef = i0.defineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(i0.inject(i0.INJECTOR)); }, token: LoggerService, providedIn: "root" });
    return LoggerService;
}());
export { LoggerService };
