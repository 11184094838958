import { LanguageCountry } from "./enums/language-country-code";
var ɵ0 = function () {
    return [
        LanguageCountry.US,
        LanguageCountry.CA,
        LanguageCountry.GB,
        LanguageCountry.AU,
        LanguageCountry.IN,
        LanguageCountry.JP,
        LanguageCountry.KR,
        LanguageCountry.BR,
        LanguageCountry.DE,
        LanguageCountry.FR,
        LanguageCountry.ES,
        LanguageCountry.AR,
        LanguageCountry.MX,
        LanguageCountry.IT,
        LanguageCountry.CN
    ];
};
var MBDCountryRegistryModule = /** @class */ (function () {
    function MBDCountryRegistryModule() {
    }
    return MBDCountryRegistryModule;
}());
export { MBDCountryRegistryModule };
export { ɵ0 };
