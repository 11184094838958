import { Component, Inject, OnInit } from '@angular/core';
import {MatSnackBarRef, MAT_SNACK_BAR_DATA} from '@angular/material';
import { AlertData } from '../interfaces/alert-data';
import { AlertType } from '../enums/alert-type';

@Component({
  selector: 'app-snack-bar-alert',
  templateUrl: './snack-bar-alert.component.html',
  styleUrls: ['./snack-bar-alert.component.scss']
})
export class SnackBarAlertComponent implements OnInit {

  get title(): string {
    return this.data.title || "";
  }

  get text(): string {
    return this.data.text || "Empty text";
  }

  get code(): string {
    return (this.data.code || "").toString();
  }

  get url(): string {
    return this.data.url || "";
  }

  get isTest(): boolean {
    return !!this.data.isTest;
  }

  get isKnownError(): boolean {
    return this.data.alertType === AlertType.Error && !!this.code;
  }

  get iconName(): string {
    return AlertType[(this.data.alertType || 1)].toLowerCase();
  }

  get iconClass(): string {
    return `icon-${this.iconName}`;
  }

  constructor(
    private ref: MatSnackBarRef<SnackBarAlertComponent>
    , @Inject(MAT_SNACK_BAR_DATA) public data: AlertData
  ) { }

  dismiss() {
    this.ref.dismiss();
  }

  ngOnInit() {
  }

}
