import { CategoryBase, ICategoryBase } from './core/category-base';
import { CategoryType } from './core/category-type.enum';

export interface ICategory extends ICategoryBase {
  leftText?: string;
  rightText?: string;
  isOther?: boolean;
  isExclusive?: boolean;
  code?: number;
}

export class Category extends CategoryBase implements ICategory {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.CategoryApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  leftText: string;
  rightText: string;
  isOther: boolean;
  isExclusive: boolean;
  code: number;

  constructor(options?: ICategory) {
    super(options as ICategoryBase);

    this.categoryType = CategoryType.Category;

    options = options || ({} as ICategory);

    this.isExclusive = options.isExclusive || false;
    this.isOther = options.isOther || false;
    this.leftText = options.leftText || "";
    this.rightText = options.rightText || "";
    this.code = options.code || 0;
  }
}

// export interface ICategoryCollection {
//   $type?: string;
//   $values?: Array<ICategory>;
// }

// export class CategoryCollection implements ICategoryCollection {
//   $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.CategoryCollectionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
//   $values: Array<ICategory> = [];

//   constructor(options?: Array<ICategory> | ICategoryCollection) {
//     const self = this;
//     let array: Array<ICategory> = null;
//     if (!!options && !!(options as ICategoryCollection).$values) {
//       array = (options as ICategoryCollection).$values || [];
//     } else {
//       array = (options as Array<ICategory>) || [];
//     }
//     array.forEach(item => {
//       if (!(item instanceof Category)) {
//         item = new Category(item as ICategory);
//       }
//       this.$values.push(item as Category);
//     });
//   }
// }
