import { ENDING_DATA } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { EndingDataTypeRegistryCollection, IEndingDataTypeRegistry } from '../services/ending-data-factory.service';
import { Utilities } from '../utils/utilities';
import { Parameter, ParameterCollection } from './parameter';
import { Respondent } from './respondent';

export abstract class EndingInputDataBase {
  abstract $type: string = "";

  // urlData: ParameterCollection = [];
  respondent: Respondent = new Respondent();
  absUrl: string = "";
  extraParameters: ParameterCollection = new ParameterCollection();
  isTest: boolean = false;
  relevantId: string = "";
  fraudProfileScore: number = 0;
  country: string = "";

  protected get logicHandled(): boolean {
    return this.__logicHandled;
  }

  static processParameters(urlParams: { [name: string]: string | number | boolean }): EndingInputDataBase {
    let instance: EndingInputDataBase = null;

    if (!!!this.__registeredTypes) {
      this.__registeredTypes = InjectorInstance.get<EndingDataTypeRegistryCollection>(ENDING_DATA);
    }
    instance = new (this.__registeredTypes.resolve("EndingInputData")).type();

    this.__registeredTypes.forEach((registeredType: IEndingDataTypeRegistry) => {
      const concrete: EndingInputDataBase = new registeredType.type();
      concrete.handleLogic(urlParams);
      if (concrete.logicHandled) {
        instance = concrete;
        return;
      }
    });
    const absUrl: string = Utilities.absoluteUrl;

    instance.absUrl = absUrl;
    instance.extraParameters.$values.push(
      new Parameter({ key: "absUrl", value: absUrl }),
      new Parameter({
        key: "clientReferralInfo",
        value: window.document.referrer
      })
    );

    if (!!urlParams["rid"]) {
      instance.respondent.respondentToken = urlParams["rid"] as string;
    }
    else if (!!urlParams["sub_id"]) {
      instance.respondent.respondentToken = urlParams["sub_id"] as string;
    }
    else if (!!urlParams["clickid"]) {
      instance.respondent.respondentToken = urlParams["clickid"] as string;
    }
    else if (!!urlParams["request_uuid"]) {
      instance.respondent.respondentToken = urlParams["request_uuid"] as string;
    }
    instance.respondent.deviceType = Utilities.getDeviceType();
    instance.respondent.returnStatusId = <number>urlParams["rs"];
    return instance;
  }
  public abstract parseUrl(): string;
  protected abstract handleLogic(urlParams: { [name: string]: string | number | boolean });

  protected __logicHandled: boolean = false;

  private static __registeredTypes: EndingDataTypeRegistryCollection;
}

export class EndingInputData extends EndingInputDataBase {
  $type: string = "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusRequestApiDTO, SampleCube.UserFlow.API.Models.EndingPage";

  protected handleLogic(urlParams: { [name: string]: string | number | boolean }) { }
  public parseUrl(): string {
    return "";
  }
}
