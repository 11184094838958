var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CategoryBase } from './core/category-base';
import { CategoryType } from './core/category-type.enum';
var Category = /** @class */ (function (_super) {
    __extends(Category, _super);
    function Category(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.CategoryApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
        _this.categoryType = CategoryType.Category;
        options = options || {};
        _this.isExclusive = options.isExclusive || false;
        _this.isOther = options.isOther || false;
        _this.leftText = options.leftText || "";
        _this.rightText = options.rightText || "";
        _this.code = options.code || 0;
        return _this;
    }
    return Category;
}(CategoryBase));
export { Category };
// export interface ICategoryCollection {
//   $type?: string;
//   $values?: Array<ICategory>;
// }
// export class CategoryCollection implements ICategoryCollection {
//   $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.CategoryCollectionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
//   $values: Array<ICategory> = [];
//   constructor(options?: Array<ICategory> | ICategoryCollection) {
//     const self = this;
//     let array: Array<ICategory> = null;
//     if (!!options && !!(options as ICategoryCollection).$values) {
//       array = (options as ICategoryCollection).$values || [];
//     } else {
//       array = (options as Array<ICategory>) || [];
//     }
//     array.forEach(item => {
//       if (!(item instanceof Category)) {
//         item = new Category(item as ICategory);
//       }
//       this.$values.push(item as Category);
//     });
//   }
// }
