export interface IRespondent {
  id?: number;
  isTest?: boolean;
  surveyId?: number;
  vendorId?: number;
  deviceId?: number;
  returnStatusId?: number;
  termQualificationId?: number;
  termQuotaId?: number;
  termSecurityId?: number;
  sessionId?: number;
  originalStatusId?: number;
  deviceType?: number;
  isProfileComplete?: number;
  respondentToken?: string;
  vendorRespondentId?: string;
  vendorVariable2?: string;
  vendorVariable3?: string;
  country: string;
  duid: string;
  scUserId?: number;
  surveyCountry: string;
  skipAge:boolean;
  skipGender:boolean;
  skipZip:boolean;
}

export class Respondent implements IRespondent {
  $type: string = "SampleCube.UserFlow.API.Models.RespondentApiModel, SampleCube.UserFlow.API.Models";
  id: number = 0;
  isTest: boolean;
  surveyId: number = 0;
  vendorId: number = 0;
  deviceId: number = 0;
  returnStatusId: number = 0;
  termQualificationId: number = 0;
  termQuotaId: number = 0;
  termSecurityId: number = 0;
  sessionId: number = 0;
  originalStatusId: number = 0;
  deviceType: number = 0;
  isProfileComplete: number = 0;
  respondentToken: string = '';
  vendorRespondentId: string = '';
  vendorVariable2: string = '';
  vendorVariable3: string = '';
  country: string = '';
  duid: string = "";
  scUserId: number = 0;
  surveyCountry: string = '';
  skipAge:boolean=null;
  skipGender:boolean=null;
  skipZip:boolean=null;

  constructor(options?: IRespondent) {
    options = options || {} as IRespondent;

    this.id = options.id || 0;
    this.isTest = options.isTest || false;
    this.surveyId = options.surveyId || 0;
    this.vendorId = options.vendorId || 0;
    this.deviceId = options.deviceId || 0;
    this.returnStatusId = options.returnStatusId || 0;
    this.termQualificationId = options.termQualificationId || 0;
    this.termQuotaId = options.termQuotaId || 0;
    this.termSecurityId = options.termSecurityId || 0;
    this.sessionId = options.sessionId || 0;
    this.originalStatusId = options.originalStatusId || 0;
    this.deviceType = options.deviceType || 0;
    this.isProfileComplete = options.isProfileComplete || 0;
    this.respondentToken = options.respondentToken || '';
    this.vendorRespondentId = options.vendorRespondentId || '';
    this.vendorVariable2 = options.vendorVariable2 || '';
    this.vendorVariable3 = options.vendorVariable3 || '';
    this.country = options.country || '';
    this.duid = options.duid || '';
    this.scUserId = options.scUserId || 0;
    this.surveyCountry = options.surveyCountry || '';
    this.skipAge=options.skipAge || null;
    this.skipGender=options.skipGender || null;
    this.skipZip=options.skipZip || null;
  }
}
