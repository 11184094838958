import { API_BASE } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { Exception, ApiError } from '../core/exception';
import { SessionStorageService } from 'ngx-store/src/ngx-store';
import { CheesecakeStep } from '../enums/cheesecake-step';

export class Utilities {
  static getDeviceType() {
    const ua = navigator.userAgent.toLowerCase();
    const device = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)
      ? "tablet"
      : /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(ua)
        ? "phone"
        : "desktop";
    if (device === "phone") {
      return 2;
    } else if (device === "tablet") {
      return 3;
    } else {
      return 1;
    }
  }

  static getRequestPath(url: string): string {
    const rootUri: string = InjectorInstance.get<string>(API_BASE);
    return url.replace(rootUri, "");
  }
  static getCurrentPath(): string {
    return window.location.pathname;
  }
  static getQueryString(): string {
    const url: string = Utilities.absoluteUrl;
    const parts: string[] = url.split("?");
    if (parts.length === 2) {
      return parts[1];
    }
    return "";
  }

  static toQueryString(obj: { [name: string]: string | number | boolean }) {
    let qs: string = "",
      key: string;
    // tslint:disable-next-line:forin
    for (key in obj) {
      qs += (qs !== "" ? "&" : "") + `${key.toLowerCase()}=${obj[key]}`;
    }
    return qs;
  }

  static parseQuery(): { [name: string]: string | number | boolean } {
    const obj: { [name: string]: string | number | boolean } = {};
    const url: string = Utilities.absoluteUrl;
    try {
      const parts: string[] = url.split("?");
      if (parts.length === 2) {
        const queryString: string = parts[1];
        const pairs: string[] = queryString.split("&");
        pairs.forEach(keyValuePair => {
          const pair: string[] = keyValuePair.split("=");
          let key: string = pair[0].trim().toLowerCase();
          if ((key && key != '') && pair[1]) {
            let value: string | number | boolean = pair[1].trim();

            if (!!key) {
              key = key.toString().replace("%20", "");
            }
            if (!!value) {
              value = value.toString().replace("%20", "");
            }
            if ("number" === typeof value) {
              obj[key] = <number>value;
            } else if ("string" === typeof value) {
              obj[key] = <string>value;
            } else if ("boolean" === typeof value) {
              obj[key] = <boolean>value;
            }
          }
        });
      }
      return obj;
    } catch (ex) {
      throw new Exception({
        message: ex.message,
        data: new ApiError({
          debugData: "",
          details: url,
          errorCode: 40,
          message: "InvalidURL"
        })
      });
    }
  }
  static objectToQueryString(obj: { [name: string]: string | number | boolean }) {
    let qs: string = "",
      key: string;
    // tslint:disable-next-line:forin
    for (key in obj) {
      qs += (qs !== "" ? "&" : "") + `${key.toLowerCase()}=${obj[key]}`;
    }
    return qs;
  }

  static get absoluteUrl() {
    // window.ABS_URL = window.ABS_URL.replace("{ABS_URL}", "");
    // return window.ABS_URL || window.location.href;
    let url = "";
    const sessionStorageService = InjectorInstance.get<SessionStorageService>(SessionStorageService);
    const step = sessionStorageService.get("sc.step")  as CheesecakeStep || CheesecakeStep.Landed;
    switch (step) {
      case CheesecakeStep.Landed:
      case CheesecakeStep.LinkedInResponse:
      case CheesecakeStep.LinkedInFailed:
      case CheesecakeStep.MBDGoogleStart: {
        sessionStorageService.set("sc.landing-url", window.location.href);
        break;
      }
    }   

    url = sessionStorageService.get("sc.landing-url");
    window.ABS_URL = window.ABS_URL.replace("{ABS_URL}", "");
    const url2 =window.ABS_URL || url || window.location.href;
    return url2;
  }

  static get urlHandler() {
    if (window.ABS_URL.indexOf("?") > -1) {
      return true;
    }
    else {
      return false;
    }
  }
  static parseQueryUrl(url: string): { [name: string]: string | number | boolean } {
    const obj: { [name: string]: string | number | boolean } = {};
    try {
      const parts: string[] = url.split("?");
      if (parts.length === 2) {
        const queryString: string = parts[1];
        const pairs: string[] = queryString.split("&");
        pairs.forEach(keyValuePair => {
          const pair: string[] = keyValuePair.split("=");
          let key: string = pair[0].trim().toLowerCase();
          let value: string | number | boolean = pair[1].trim();

          if (!!key) {
            key = key.toString().replace("%20", "");
          }
          if (!!value) {
            value = value.toString().replace("%20", "");
          }
          if ("number" === typeof value) {
            obj[key] = <number>value;
          } else if ("string" === typeof value) {
            obj[key] = <string>value;
          } else if ("boolean" === typeof value) {
            obj[key] = <boolean>value;
          }
        });
      }
      return obj;
    } catch (ex) {
      throw new Exception({
        message: ex.message,
        data: new ApiError({
          debugData: "",
          details: url,
          errorCode: 40,
          message: "InvalidURL2"
        })
      });
    }
  }
  static getQueryStringUrl(url: string): string {
    const parts: string[] = url.split("?");
    if (parts.length === 2) {
      return parts[1];
    }
    return "";
  }
}
