import { IRedirectCheckQualificationResponse, CheckQualificationResponseBase, ICheckQualificationResponse, IRedirectRoutedCheckQualificationResponse } from './check-qualification-response-base';

export interface ICheckQualificationLinkedInResponse extends IRedirectRoutedCheckQualificationResponse {
  linkedInTerminate: boolean;
  isRouterSession:boolean;
  clientSurveyLiveURL:string;
}

export class CheckQualificationLinkedInResponse extends CheckQualificationResponseBase implements ICheckQualificationLinkedInResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationLinkedInResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  redirectUrl: string;
  respondentId: number;
  respondentToken: string;
  surveyId: number;
  scUserId: number;
  linkedInTerminate: boolean;
  isRouterSession:boolean;
  clientSurveyLiveURL:string;

  constructor(options?: ICheckQualificationLinkedInResponse) {
    super(options as ICheckQualificationResponse);
    options = options || ({} as ICheckQualificationLinkedInResponse);
    this.redirectUrl = options.redirectUrl || "";
    this.respondentId = options.respondentId || 0;
    this.respondentToken = options.respondentToken || "";
    this.surveyId = options.surveyId || 0;
    this.scUserId = options.scUserId || 0;
    this.linkedInTerminate = options.linkedInTerminate || false;
    this.isRouterSession=options.isRouterSession || false;
    this.clientSurveyLiveURL=options.clientSurveyLiveURL || '';
  }
}
