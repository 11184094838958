import { IPageCollection, PageCollection } from "./survey-engine/page";
import { EndingInputDataBase } from "./ending";
import { IEndingOutputBase, EndingOutputBase } from "./ending-output-base";
import { URLType } from "../enums/url-type";

export interface IAfterComplete extends  IEndingOutputBase{
  pages: IPageCollection;
  redirectUrl: string;
  urlType:URLType;
}

export class SetStatusAfterCompleteOutputData extends EndingOutputBase implements IAfterComplete {
  $type: string =
    "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusAfterCompleteResponseApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
  pages: IPageCollection;
  redirectUrl: string;
  urlType:URLType;
  constructor(options?: IAfterComplete) {
    super(options as IEndingOutputBase);
    options = options || ({} as IAfterComplete);
    this.pages = new PageCollection(options.pages || {});
    this.redirectUrl = options.redirectUrl || "";
    this.urlType=options.urlType || URLType.None;
  }
}
