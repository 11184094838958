import { NgModule } from '@angular/core';

import { environment } from '../environments/environment';
import { SAMPLE_CHAIN_KEY,SAMPLE_CHAIN_PRIVATE_KEY, SAMPLE_CHAIN_LIB, SAMPLE_CHAIN_TIMEOUT, SAMPLE_CHAIN_USE_CACHE, SAMPLE_CHAIN_API_URL } from './core/globals';

@NgModule({
  providers: [
    {
      provide: SAMPLE_CHAIN_KEY,
      useValue: environment.sampleChain.key
    },
    {
      provide: SAMPLE_CHAIN_PRIVATE_KEY,
      useValue: environment.sampleChain.privateKey
    },
    {
      provide: SAMPLE_CHAIN_LIB,
      useValue: environment.sampleChain.lib
    },
    {
      provide: SAMPLE_CHAIN_TIMEOUT,
      useValue: environment.sampleChain.timeout
    },
    {
      provide: SAMPLE_CHAIN_USE_CACHE,
      useValue: environment.sampleChain.cache
    }, {
      provide: SAMPLE_CHAIN_API_URL,
      useValue: environment.sampleChain.apiUrl
    }
  ]
})
export class SampleChainConfigModule { }
