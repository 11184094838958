import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var NextBackService = /** @class */ (function () {
    // get pageIndex$(): Observable<number> {
    //   return this.pageIndex.asObservable();
    // }
    // get nextEnabled$(): Observable<boolean> {
    //   return this.nextEnabled.asObservable();
    // }
    // get backEnabled$(): Observable<boolean> {
    //   return this.backEnabled.asObservable();
    // }
    // Service message commands
    function NextBackService() {
        this.pageIndex = new Subject();
        this.pageIndex$ = this.pageIndex.asObservable();
        this.nextEnabled = new Subject();
        this.nextEnabled$ = this.nextEnabled.asObservable();
        this.backEnabled = new Subject();
        this.backEnabled$ = this.backEnabled.asObservable();
        this.nextIsVisible = new Subject();
        this.nextIsVisible$ = this.nextIsVisible.asObservable();
        this.backIsVisible = new Subject();
        this.backIsVisible$ = this.backIsVisible.asObservable();
        this.pageIsSubmit = new Subject();
        this.pageIsSubmit$ = this.pageIsSubmit.asObservable();
        this.pageResponse = new Subject();
        this.pageResponse$ = this.pageResponse.asObservable();
    }
    NextBackService.prototype.submitPage = function (response) {
        this.pageIsSubmit.next(true);
        this.pageResponse.next(response);
    };
    NextBackService.prototype.next = function (index) {
        this.goTo(index + 1);
    };
    NextBackService.prototype.back = function (index) {
        this.goTo(index - 1);
    };
    NextBackService.prototype.goTo = function (index) {
        this.pageIndex.next(index);
    };
    NextBackService.prototype.showNext = function (show) {
        this.nextIsVisible.next(show);
    };
    NextBackService.prototype.showBack = function (show) {
        this.backIsVisible.next(show);
    };
    NextBackService.prototype.enableNext = function (enable) {
        this.nextEnabled.next(enable);
    };
    NextBackService.prototype.enableBack = function (enable) {
        this.backEnabled.next(enable);
    };
    NextBackService.ngInjectableDef = i0.defineInjectable({ factory: function NextBackService_Factory() { return new NextBackService(); }, token: NextBackService, providedIn: "root" });
    return NextBackService;
}());
export { NextBackService };
