var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var EndingDataTypeRegistryCollection = /** @class */ (function (_super) {
    __extends(EndingDataTypeRegistryCollection, _super);
    function EndingDataTypeRegistryCollection(array) {
        var _this = _super.call(this) || this;
        Object.setPrototypeOf(_this, EndingDataTypeRegistryCollection.prototype);
        var self = _this;
        array = array || [];
        array.forEach(function (item) {
            self.push(item);
        });
        return _this;
    }
    EndingDataTypeRegistryCollection.prototype.resolve = function (key) {
        var registry = null;
        this.forEach(function (item) {
            if (item.key === key) {
                registry = item;
                return;
            }
        });
        return registry;
    };
    return EndingDataTypeRegistryCollection;
}(Array));
export { EndingDataTypeRegistryCollection };
