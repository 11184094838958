import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { HttpServiceBase } from "../core/http-service-base";

@Injectable({
  providedIn: "root"
})
export class ErrorLoggerService extends HttpServiceBase {
  constructor() {
    super();
  }

  async saveErrorResponse(exception?: IErrorResponse, errorCallback?: (error: Error) => void): Promise<boolean> {
    const self = this;
    const response: boolean = await self.post<IErrorResponse, boolean>("/sessions/capture-error-response", exception, null, errorCallback);
    return response;
  }

  public __respondentId: number;
  public __sessionId: number;
  public __surveyId: number;
  public __userId: number;
}

export interface IErrorResponse {
  message?: string,
  errorDetails?: string,
  errorStack?: string,
  errorCode?: number,
  url?: string,
  isTest?: boolean,
  surveyId?: number,
  sessionId?: number,
  respondentId?: number,
  userId?: number,
  errorFrom?: string
}

export class ErrorResponse implements IErrorResponse {
  message?: string = "";
  errorCode?: number = 0;
  url?: string = "";
  isTest?: boolean = false;
  surveyId?: number = 0;
  sessionId?: number = 0;
  respondentId?: number = 0;
  userId?: number = 0;
  errorFrom?: string = "UI";
  errorDetails?: string = "";
  errorStack?: string = "";

  constructor(options?: IErrorResponse) {
    options = options || {} as IErrorResponse;
    this.message = options.message || this.message;
    this.errorCode = options.errorCode || this.errorCode;
    this.url = options.url || this.url;
    this.isTest = options.isTest || this.isTest;
    this.surveyId = options.surveyId || this.surveyId;
    this.sessionId = options.sessionId || this.sessionId;
    this.respondentId = options.respondentId || this.respondentId;
    this.userId = options.userId || this.userId;
    this.errorFrom = options.errorFrom || this.errorFrom;
  }
}
