var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';
import { tap, catchError, timeout } from 'rxjs/operators';
import { InjectorInstance } from './injector-instance';
import { API_BASE } from './globals';
import { APICallType } from '../enums/call-type.enum';
var HttpServiceBase = /** @class */ (function () {
    function HttpServiceBase() {
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.rootUri = InjectorInstance.get(API_BASE);
        this.http = InjectorInstance.get(HttpClient);
    }
    HttpServiceBase.prototype.get = function (path, headers, errorCallback, callType, timeOut, withCredentials) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.executeAction(Method.Get, path, {}, headers, errorCallback, callType, timeOut, withCredentials).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    HttpServiceBase.prototype.delete = function (path, headers, errorCallback, callType) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.executeAction(Method.Delete, path, {}, headers, errorCallback, callType).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    HttpServiceBase.prototype.post = function (path, request, headers, errorCallback, callType, timeOut) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.executeAction(Method.Post, path, request, headers, errorCallback, callType, timeOut).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    HttpServiceBase.prototype.put = function (path, request, headers, errorCallback, callType) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.executeAction(Method.Put, path, request, headers, errorCallback, callType).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    HttpServiceBase.prototype.executeAction = function (method, path, request, headers, errorCallback, callType, timeOut, withCredentials) {
        if (timeOut === void 0) { timeOut = null; }
        if (withCredentials === void 0) { withCredentials = null; }
        var self = this;
        var url = "" + self.rootUri + path;
        var options = {
            headers: self.getRequestHeaders(headers)
        };
        if (!!withCredentials) {
            options['withCredentials'] = withCredentials;
        }
        if (callType === APICallType.ThirdPartyAPI) {
            url = path;
            options.headers = null;
        }
        var call = null;
        switch (method) {
            case Method.Post:
                call = self.executePost(url, request, options);
                break;
            case Method.Put:
                call = self.executePut(url, request, options);
                break;
            case Method.Delete:
                call = self.executeDelete(url, options);
                break;
            default:
                call = self.executeGet(url, options);
                break;
        }
        if (!!timeOut) {
            return call.pipe(tap(function (result) {
                return of(result);
            }), timeout(timeOut * 1000), catchError(function (error) {
                if ('function' === typeof errorCallback) {
                    errorCallback(error);
                }
                return of({});
            }));
        }
        return call.pipe(tap(function (result) {
            return of(result);
        }), catchError(function (error) {
            if ('function' === typeof errorCallback) {
                errorCallback(error);
            }
            return of({});
        }));
    };
    HttpServiceBase.prototype.executeGet = function (url, options) {
        return this.http.get(url, options);
    };
    HttpServiceBase.prototype.executeDelete = function (url, options) {
        return this.http.delete(url, options);
    };
    HttpServiceBase.prototype.executePost = function (url, request, options) {
        return this.http.post(url, request, options);
    };
    HttpServiceBase.prototype.executePut = function (url, request, options) {
        return this.http.put(url, request, options);
    };
    HttpServiceBase.prototype.getRequestHeaders = function (headers) {
        var self = this;
        headers = headers || new HttpHeaders();
        var _headers = {};
        this.headers.keys().forEach(function (headerName) {
            var headerValue = self.headers.get(headerName);
            _headers[headerName] = headerValue;
        });
        headers.keys().forEach(function (headerName) {
            var headerValue = self.headers.get(headerName);
            _headers[headerName] = headerValue;
        });
        return new HttpHeaders(_headers);
    };
    return HttpServiceBase;
}());
export { HttpServiceBase };
var Method;
(function (Method) {
    Method[Method["Get"] = 0] = "Get";
    Method[Method["Post"] = 1] = "Post";
    Method[Method["Put"] = 2] = "Put";
    Method[Method["Delete"] = 3] = "Delete";
})(Method || (Method = {}));
