import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material";
import { IconRegistryCollection, IconFactoryService } from "./services/icon-factory.service";
var ɵ0 = function () {
    return new IconRegistryCollection([
        {
            name: "info",
            path: "assets/fonts/material/baseline-info-24px.svg"
        },
        {
            name: "warning",
            path: "assets/fonts/material/baseline-report_problem-24px.svg"
        },
        {
            name: "error",
            path: "assets/fonts/material/baseline-error-24px.svg"
        }
    ]);
};
var IconMapModule = /** @class */ (function () {
    function IconMapModule(sanitizer, iconRegistry, iconFactoryService) {
        this.sanitizer = sanitizer;
        this.iconRegistry = iconRegistry;
        this.iconFactoryService = iconFactoryService;
        var self = this;
        self.init();
    }
    IconMapModule.prototype.init = function () {
        var self = this;
        self.iconFactoryService.registeredIcons.forEach(function (icon) {
            self.iconRegistry.addSvgIcon(icon.name, self.sanitizer.bypassSecurityTrustResourceUrl(icon.path));
        });
    };
    return IconMapModule;
}());
export { IconMapModule };
export { ɵ0 };
