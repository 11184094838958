import { API_BASE } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { Exception, ApiError } from '../core/exception';
import { SessionStorageService } from 'ngx-store/src/ngx-store';
import { CheesecakeStep } from '../enums/cheesecake-step';
var Utilities = /** @class */ (function () {
    function Utilities() {
    }
    Utilities.getDeviceType = function () {
        var ua = navigator.userAgent.toLowerCase();
        var device = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua)
            ? "tablet"
            : /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(ua)
                ? "phone"
                : "desktop";
        if (device === "phone") {
            return 2;
        }
        else if (device === "tablet") {
            return 3;
        }
        else {
            return 1;
        }
    };
    Utilities.getRequestPath = function (url) {
        var rootUri = InjectorInstance.get(API_BASE);
        return url.replace(rootUri, "");
    };
    Utilities.getCurrentPath = function () {
        return window.location.pathname;
    };
    Utilities.getQueryString = function () {
        var url = Utilities.absoluteUrl;
        var parts = url.split("?");
        if (parts.length === 2) {
            return parts[1];
        }
        return "";
    };
    Utilities.toQueryString = function (obj) {
        var qs = "", key;
        // tslint:disable-next-line:forin
        for (key in obj) {
            qs += (qs !== "" ? "&" : "") + (key.toLowerCase() + "=" + obj[key]);
        }
        return qs;
    };
    Utilities.parseQuery = function () {
        var obj = {};
        var url = Utilities.absoluteUrl;
        try {
            var parts = url.split("?");
            if (parts.length === 2) {
                var queryString = parts[1];
                var pairs = queryString.split("&");
                pairs.forEach(function (keyValuePair) {
                    var pair = keyValuePair.split("=");
                    var key = pair[0].trim().toLowerCase();
                    if ((key && key != '') && pair[1]) {
                        var value = pair[1].trim();
                        if (!!key) {
                            key = key.toString().replace("%20", "");
                        }
                        if (!!value) {
                            value = value.toString().replace("%20", "");
                        }
                        if ("number" === typeof value) {
                            obj[key] = value;
                        }
                        else if ("string" === typeof value) {
                            obj[key] = value;
                        }
                        else if ("boolean" === typeof value) {
                            obj[key] = value;
                        }
                    }
                });
            }
            return obj;
        }
        catch (ex) {
            throw new Exception({
                message: ex.message,
                data: new ApiError({
                    debugData: "",
                    details: url,
                    errorCode: 40,
                    message: "InvalidURL"
                })
            });
        }
    };
    Utilities.objectToQueryString = function (obj) {
        var qs = "", key;
        // tslint:disable-next-line:forin
        for (key in obj) {
            qs += (qs !== "" ? "&" : "") + (key.toLowerCase() + "=" + obj[key]);
        }
        return qs;
    };
    Object.defineProperty(Utilities, "absoluteUrl", {
        get: function () {
            // window.ABS_URL = window.ABS_URL.replace("{ABS_URL}", "");
            // return window.ABS_URL || window.location.href;
            var url = "";
            var sessionStorageService = InjectorInstance.get(SessionStorageService);
            var step = sessionStorageService.get("sc.step") || CheesecakeStep.Landed;
            switch (step) {
                case CheesecakeStep.Landed:
                case CheesecakeStep.LinkedInResponse:
                case CheesecakeStep.LinkedInFailed:
                case CheesecakeStep.MBDGoogleStart: {
                    sessionStorageService.set("sc.landing-url", window.location.href);
                    break;
                }
            }
            url = sessionStorageService.get("sc.landing-url");
            window.ABS_URL = window.ABS_URL.replace("{ABS_URL}", "");
            var url2 = window.ABS_URL || url || window.location.href;
            return url2;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Utilities, "urlHandler", {
        get: function () {
            if (window.ABS_URL.indexOf("?") > -1) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Utilities.parseQueryUrl = function (url) {
        var obj = {};
        try {
            var parts = url.split("?");
            if (parts.length === 2) {
                var queryString = parts[1];
                var pairs = queryString.split("&");
                pairs.forEach(function (keyValuePair) {
                    var pair = keyValuePair.split("=");
                    var key = pair[0].trim().toLowerCase();
                    var value = pair[1].trim();
                    if (!!key) {
                        key = key.toString().replace("%20", "");
                    }
                    if (!!value) {
                        value = value.toString().replace("%20", "");
                    }
                    if ("number" === typeof value) {
                        obj[key] = value;
                    }
                    else if ("string" === typeof value) {
                        obj[key] = value;
                    }
                    else if ("boolean" === typeof value) {
                        obj[key] = value;
                    }
                });
            }
            return obj;
        }
        catch (ex) {
            throw new Exception({
                message: ex.message,
                data: new ApiError({
                    debugData: "",
                    details: url,
                    errorCode: 40,
                    message: "InvalidURL2"
                })
            });
        }
    };
    Utilities.getQueryStringUrl = function (url) {
        var parts = url.split("?");
        if (parts.length === 2) {
            return parts[1];
        }
        return "";
    };
    return Utilities;
}());
export { Utilities };
