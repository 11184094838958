import { Component, OnDestroy, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";

import { NumericQuestion } from "../../../../models/survey-engine/numeric-question";
import { OpenQuestionBaseComponent } from "../core/open-end-question-base.component";

import "../../../../extensions/string-extensions";
import { Message } from "../../../../localization/message";
import { OpenAnswer } from "../../../../models/survey-engine/open-answer";

@Component({
  selector: "app-numeric-question",
  templateUrl: "./numeric-question.component.html",
  styleUrls: ["./numeric-question.component.scss"]
})
export class NumericQuestionComponent extends OpenQuestionBaseComponent implements OnInit, OnDestroy {
  get minValueErrorMessage(): string {
    return this.__minValueErrorMessage;
  }

  get maxValueErrorMessage(): string {
    return this.__maxValueErrorMessage;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const self = this;

    const validators = [];
    if ((self.question as NumericQuestion).validators.required) {
      validators.push(Validators.required);
    }

    Object.keys((self.question as NumericQuestion).validators).forEach(validator => {
      if (validator !== "required" && (self.question as NumericQuestion).validators[validator]) {
        if (validator === "pattern") {
          validators.push(Validators[validator].apply(undefined, [new RegExp((self.question as NumericQuestion).validators["pattern"], "i")]));
        } else {
          validators.push(Validators[validator].apply(undefined, [(self.question as NumericQuestion).validators[validator]]));
        }
      }
    });

    self.ctrl.setValidators(validators);

    self.__minValueErrorMessage = String.format(Message.MinValueExceededMessage, "Value", (this.question as NumericQuestion).validators.min);
    self.__maxValueErrorMessage = String.format(Message.MaxValueExceededMessage, "Value", (this.question as NumericQuestion).validators.max);
    if (self.question.response && (self.question.response as OpenAnswer).value) {
      let response = (self.question.response as OpenAnswer).value;
      self.ctrl.setValue(response);
    }
    self.triggerQuestionLoaded();
  }  
  
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private __minValueErrorMessage: string;
  private __maxValueErrorMessage: string;
}
