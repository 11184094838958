import { NgModule } from "@angular/core";
import { CategoryType } from './models/survey-engine/core/category-type.enum';
import { Category } from "./models/survey-engine/category";
import { CATEGORY_REGISTRY } from "./core/globals";
import { CategoryTypeRegistryCollection } from "./services/category-factory.service";

@NgModule({
  providers: [
    {
      provide: CATEGORY_REGISTRY,
      useFactory: function() {
        return new CategoryTypeRegistryCollection([
          {
            key: CategoryType[CategoryType.Category],
            type:Category
          }
        ]);
      }
    }
  ]
})
export class CategoryRegistryModule {}
