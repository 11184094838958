import { Injectable, Type } from '@angular/core';

import { ANSWER_REGISTRY } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { AnswerType } from '../models/survey-engine/core/answer-type.enum';
import { IQuestionAnswer, QuestionAnswerBase } from '../models/survey-engine/core/question-answer-base';

export interface IAnswerTypeRegistry {
  key?: string;
  type?: Type<QuestionAnswerBase>;
}

@Injectable({
  providedIn: "root"
})
export class AnswerFactoryService {
  constructor() {
    this.__registeredTypes = InjectorInstance.get<AnswerRegistryCollection>(ANSWER_REGISTRY);
  }
  createInstance(options?: IQuestionAnswer): QuestionAnswerBase {
    options = options || ({} as IQuestionAnswer);
    options.answerType = options.answerType || 0;
    let key = AnswerType[options.answerType].toString();
    key = this.normalizedKey(key || "");
    if (!!!key || !this.__hasType(key)) {
      return null;
    }

    let type: Type<QuestionAnswerBase> = null;
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        type = item.type;
        return false;
      }
    });

    return new type(options);
  }

  registerType(registry?: IAnswerTypeRegistry): boolean {
    registry = registry || {};
    registry.key = this.normalizedKey(registry.key || "");
    if (!!!registry.key) {
      return false;
    }
    if (!this.__hasType(registry.key)) {
      this.__registeredTypes.push(registry);
      return true;
    }
    return false;
  }

  private __hasType(key: string): boolean {
    let ok: boolean = false;
    key = this.normalizedKey(key || "");
    if (!!!key) {
      return false;
    }
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        ok = true;
        return false;
      }
    });

    return ok;
  }

  private normalizedKey(key: string): string {
    return key.toString().toLowerCase();
  }

  private __registeredTypes: AnswerRegistryCollection;
}

export class AnswerRegistryCollection extends Array<IAnswerTypeRegistry> {
  constructor(array?: Array<IAnswerTypeRegistry>) {
    super();
    Object.setPrototypeOf(this, AnswerRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as IAnswerTypeRegistry);
    });
  }
}
