import {  SAMPLECUBE_USER_TYPES } from "../../core/globals";
import { NgModule } from "@angular/core";
import { SampleCubeUserTypeRegistryCollection } from "../../services/samplecube-user-factory.service";
import { FedSampleCubeUser } from "../../models/fed-samplecube-user";
import { SampleCubeUser } from "../../models/samplecube-user";

@NgModule({
  providers: [
    {
      provide: SAMPLECUBE_USER_TYPES,
      useFactory: function() {
        return new SampleCubeUserTypeRegistryCollection([
          {
            key: "FedSampleCubeUser",
            type: FedSampleCubeUser
          },
          {
            key: "SampleCubeUser",
            type: SampleCubeUser
          }
        ]);
      }
    }
  ]
})
export class SampleCubeUserModule {}
