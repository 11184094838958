import { NgModule } from '@angular/core';

import { environment } from '../environments/environment';
import { COMSCORE_IMAGE_URL,COMSCORE_TIMEOUT} from './core/globals';

@NgModule({
  providers: [
    {
      provide: COMSCORE_IMAGE_URL,
      useValue: environment.comScore.lib
	},
	{
		provide: COMSCORE_TIMEOUT,
		useValue: environment.comScore.timeout
	  }
  ]
})
export class ComScoreConfigModule {}
