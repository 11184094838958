import { Injectable, Type } from '@angular/core';

import { CHECK_QUALIFICATION_RESPONSE_REGISTRY } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { CheckQualificationResponseBase, ICheckQualificationResponse } from '../models/survey-engine/check-qualification-response-base';
import { CheckQualificationResponseType } from '../enums/check-qualification-response-type.enum';


export interface ICheckQualificationResponseTypeRegistry {
  key?: string;
  type?: Type<CheckQualificationResponseBase>;
}

@Injectable({
  providedIn: "root"
})
export class CheckQualificationResponseService {
  constructor() {
    this.__registeredTypes = InjectorInstance.get<CheckQualificationResponseRegistryCollection>(CHECK_QUALIFICATION_RESPONSE_REGISTRY);
  }
  createInstance(options?: ICheckQualificationResponse): CheckQualificationResponseBase {
    options = options || ({} as ICheckQualificationResponse);
    options.checkQualificationResponseType = options.checkQualificationResponseType || 0;
    let key = CheckQualificationResponseType[options.checkQualificationResponseType].toString();
    key = this.normalizedKey(key || "");
    if (!!!key || !this.__hasType(key)) {
      return null;
    }

    let type: Type<CheckQualificationResponseBase> = null;
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        type = item.type;
        return false;
      }
    });

    return new type(options);
  }

  registerType(registry?: ICheckQualificationResponseTypeRegistry): boolean {
    registry = registry || {};
    registry.key = this.normalizedKey(registry.key || "");
    if (!!!registry.key) {
      return false;
    }
    if (!this.__hasType(registry.key)) {
      this.__registeredTypes.push(registry);
      return true;
    }
    return false;
  }

  private __hasType(key: string): boolean {
    let ok: boolean = false;
    key = this.normalizedKey(key || "");
    if (!!!key) {
      return false;
    }
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        ok = true;
        return false;
      }
    });

    return ok;
  }

  private normalizedKey(key: string): string {
    return key.toString().toLowerCase();
  }

  private __registeredTypes: CheckQualificationResponseRegistryCollection;
}

export class CheckQualificationResponseRegistryCollection extends Array<ICheckQualificationResponseTypeRegistry> {
  constructor(array?: Array<ICheckQualificationResponseTypeRegistry>) {
    super();
    Object.setPrototypeOf(this, CheckQualificationResponseRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as ICheckQualificationResponseTypeRegistry);
    });
  }
}
