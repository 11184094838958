import { IRedirectCheckQualificationResponse, CheckQualificationResponseBase, ICheckQualificationResponse } from './check-qualification-response-base';

export interface ICheckQualificationRedirectResponse extends IRedirectCheckQualificationResponse {
}

export class CheckQualificationRedirectResponse extends CheckQualificationResponseBase implements ICheckQualificationRedirectResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationRedirectResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  redirectUrl: string;

  constructor(options?: IRedirectCheckQualificationResponse) {
    super(options as ICheckQualificationResponse);
    options = options || ({} as ICheckQualificationRedirectResponse);
    this.redirectUrl = options.redirectUrl || "";
  }
}
