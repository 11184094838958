import { SupplierSurveyAllocation, ISupplierSurveyAllocation } from "./supplier-survey-allocation";
import { Respondent, IRespondent } from "./respondent";
import { UserResponseCollection, IUserResponseCollection } from "./user-response-data";
import { ParameterCollection, IParameterCollection } from "./parameter";
import { InjectorInstance } from "../core/injector-instance";
import { EndingInputDataBase } from "./ending";
import { IAfterComplete } from "./set-status-after-complete-output-data";
import { EndingPageResponseService, EndingPageResponseRegistryCollection } from "../services/ending-page-response-factory.service";
import { SetStatusResponseType } from "../enums/ending-page-response-type.enum";

export interface IEndingOutputBase {
  isTest?: boolean;
  respondent?: Respondent;
  setStatusResponseType: SetStatusResponseType;
  dropCookie: boolean;
}

export abstract class EndingOutputBase implements IEndingOutputBase {
  abstract $type: string = "";

  isTest: boolean = false;
  respondent: Respondent = new Respondent();
  setStatusResponseType: SetStatusResponseType;
  dropCookie:boolean = false;
  constructor(options?: IEndingOutputBase) {
    options = options || ({} as IEndingOutputBase);
    this.isTest = options.isTest;
    this.respondent = new Respondent(options.respondent || {} as IRespondent);
    this.setStatusResponseType = options.setStatusResponseType;
    this.dropCookie = options.dropCookie;
  }

  public static createInstance(options?: IEndingOutputBase) {
    const endingOutputDataTypeRegistryCollection = InjectorInstance.get<EndingPageResponseService>(EndingPageResponseService);
    return endingOutputDataTypeRegistryCollection.createInstance(options);
  }


  protected get logicHandled(): boolean {
    return this.__logicHandled;
  }
  
  hasPages(): boolean {
    return (function (arg: any): arg is IAfterComplete {
      return arg.pages !== undefined;
    })(this);
  }

  protected __logicHandled: boolean = false;
}
