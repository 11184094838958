import { CheckQualificationResponseBase, ICheckQualificationResponse } from './check-qualification-response-base';

export interface ICheckQualificationMBDGoogleResponse extends ICheckQualificationResponse // extends IRedirectRoutedCheckQualificationResponse 
{
  mbdGoogleUrl:string;
  isRouterSession:boolean;
  clientSurveyLiveURL:string;
}

export class CheckQualificationMBDGoogleResponse extends CheckQualificationResponseBase implements ICheckQualificationMBDGoogleResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationMBDGoogleResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  mbdGoogleUrl:string;
  isRouterSession:boolean;
  clientSurveyLiveURL:string;
  constructor(options?: ICheckQualificationMBDGoogleResponse) {
    super(options as ICheckQualificationResponse);
    options = options || ({} as ICheckQualificationMBDGoogleResponse);   
    this.mbdGoogleUrl=options.mbdGoogleUrl || '';
    this.isRouterSession=options.isRouterSession || false;
    this.clientSurveyLiveURL=options.clientSurveyLiveURL || '';
  }
}
