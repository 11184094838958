import { Component, OnDestroy, OnInit } from '@angular/core';

import { ComponentBaseComponent } from './core/component-base.component';
import { CheesecakeStep } from './enums/cheesecake-step';
import { Utilities } from './utils/utilities';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends ComponentBaseComponent implements OnInit, OnDestroy {
  constructor() {
    super("Surveys");
  }

  get url(): string {
    return "";
  }

  ngOnInit() {
    super.ngOnInit();  
    if (this.path.indexOf("/ending") > -1) {
      let url = "/ending";
      if (!Utilities.urlHandler) {
        url += "?" + Utilities.getQueryString();
      }
      return this.navigateTo(url, true);
    } else if (this.path.indexOf("/mbd-google") > -1) {
      this.setQueryParams();
      this.saveQueryParams();
      let url = "/mbd-google";
      if (!Utilities.urlHandler) {
        url += "?" + Utilities.getQueryString();
      }
      return this.navigateTo(url, true);
    }

    this.setQueryParams();
    if (this.sessionParams["sid"] && this.urlParams["sid"] && this.urlParams["sid"].toString() !== this.sessionParams["sid"]) {
      this.sessionStorageService.clear();
    }
    if (this.urlParams["chktestlink"] !== this.sessionParams["chktestlink"]) {
      this.sessionStorageService.clear();
    }

    this.saveQueryParams();
    if (this.sessionStorageService.get("sc.step") === CheesecakeStep.LinkedInResponse && window.location.href.toLowerCase().indexOf("user_cancelled") > 0) {
      this.sessionStorageService.set("sc.step", CheesecakeStep.LinkedInFailed);
    }
    const flowStep = this.sessionStorageService.get("sc.step") as CheesecakeStep;
    this.step = flowStep ? flowStep : this.step;
    this.sessionStorageService.set("sc.step", this.step);
    switch (this.step) {
      case CheesecakeStep.CreateProfile: {
        this.navigateTo("/survey/demographics", true);
        break;
      }
      case CheesecakeStep.ShowQualifications: {
        this.navigateTo("/survey", true);
        break;
      }
      case CheesecakeStep.LinkedInStart: {
        this.navigateTo("/linkedin-auth", true);
        break;
      }
      // case CheesecakeStep.LinkedInResponse: {
      //   this.navigateTo("/linkedin-auth/:params", true);
      //   break;
      // }
      case CheesecakeStep.LinkedInResponse: {
        this.navigateTo("/linkedin-auth?" + Utilities.getQueryString(), true);
        break;
      }
      case CheesecakeStep.LinkedInFailed: {
        this.navigateTo("/linkedin-auth?" + Utilities.getQueryString(), true);
        break;
      }
      case CheesecakeStep.AfterComplete: {
        this.navigateTo("/survey/after-complete?" + Utilities.getQueryString(), true);
        break;
      }
      case CheesecakeStep.AfterCompleteReconfirmContact: {
        this.navigateTo("/survey/after-complete-reconfirm-contact?" + Utilities.getQueryString(), true);
        break;
      }
      case CheesecakeStep.Landed:
      case CheesecakeStep.CreateSession:
      default: {
        this.navigateTo("/start", true);
        break;
      }
    }
  }

  ngOnDestroy() {
    super.ngOnInit();
  }

  private setQueryParams() {
    const params = Utilities.parseQuery();
    this.urlParams = params;
  }

  private saveQueryParams() {
    const params = this.urlParams;
    params["absurl"] = Utilities.absoluteUrl;
    params["referralurl"] = window.document.referrer;
    const keys = Object.keys(params);
    if (keys && keys.length) {
      this.sessionStorageService.set("sc.params", params);
    }

    if (!!!this.storage["sc.landing-url"]) {
      // this.sessionStorageService.set("sc.landing-url", Utilities.absoluteUrl);
      this.sessionStorageService.set("sc.landing-url", window.location.href);
    }
    if (!!!this.storage["sc.is-test"]) {
      let ok = !!this.urlParams["rid"] && (this.urlParams["rid"] as string).indexOf("test") >= 0;
      ok = ok || Utilities.absoluteUrl.indexOf("chktestlink=true") >= 0;
      this.sessionStorageService.set("sc.is-test", ok);
    }
  }
  private step: CheesecakeStep = CheesecakeStep.Landed;
}
