export interface IParameter {
  key?: string;
  value?: string;
}

export class Parameter implements IParameter {
  $type: string = "SampleCube.UserFlow.API.Models.ParameterApiModel, SampleCube.UserFlow.API.Models";
  key: string;
  value: string;
  constructor(options?: IParameter) {
    options = options || {} as IParameter;
    if (!!options.key) {
      this.key = options.key;
    }
    if (!!options.value) {
      this.value = options.value;
    }
  }
}
export interface IParameterCollection {
  $values: Array<IParameter>;
}
export class ParameterCollection implements IParameterCollection {
  $type: string = "SampleCube.UserFlow.API.Models.ParameterCollectionApiModel, SampleCube.UserFlow.API.Models";
  $values: Array<Parameter> = [];

  constructor(options?: Array<IParameter> | IParameterCollection) {
    const self = this;
    let array: Array<IParameter> = null;
    if (!!options && !!(options as IParameterCollection).$values) {
      array = (options as IParameterCollection).$values || [];
    } else {
      array = options as Array<IParameter> || [];
    }
    if (array && array.length > 0) {
      array.forEach(item => {
        if (!(item instanceof Parameter)) {
          item = new Parameter(item as IParameter);
        }
        this.$values.push(item as Parameter);
      });
    }
  }

  toQueryString(): string {
    let qs: string = "";
    this.$values.forEach(param => {
      qs += (qs !== '' ? "&" : "") + `${param.key.toLowerCase()}=${param.value}`;
    });
    return qs;
  }

}
