import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var TopLoaderService = /** @class */ (function () {
    function TopLoaderService() {
        this.loaderSubject = new Subject();
        this.loaderState = this.loaderSubject.asObservable();
    }
    TopLoaderService.prototype.show = function () {
        this.loaderSubject.next({ show: true });
    };
    TopLoaderService.prototype.hide = function () {
        this.loaderSubject.next({ show: false });
    };
    TopLoaderService.ngInjectableDef = i0.defineInjectable({ factory: function TopLoaderService_Factory() { return new TopLoaderService(); }, token: TopLoaderService, providedIn: "root" });
    return TopLoaderService;
}());
export { TopLoaderService };
