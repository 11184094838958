import { SampleCubeUserBase } from "./samplecube-user";

export class FedSampleCubeUser extends SampleCubeUserBase {
  $type: string =
  "SampleCube.UserFlow.API.Models.FedSampleCubeUserApiModel, SampleCube.UserFlow.API.Models";

  fulcrumSupplierCode: string = '';

  protected handleLogic(options: {
    [name: string]: string | number | boolean;
  } | any) {
    const self = this;
    for (const p in options) {
      if (options.hasOwnProperty(p)) {
        const keys: string[] = Object.keys(self);
        keys.forEach((key: string) => {
          if (key.toLowerCase() === p) {
            self[key] = options[p];
            self.logicHandled = true;
            return;
          }
        });
      }
    }
  }
}
