import { Injectable, Type } from "@angular/core";
import { InjectorInstance } from '../core/injector-instance';
import { CATEGORY_REGISTRY } from '../core/globals';
import { CategoryBase } from "../models/survey-engine/core/category-base";
import { ICategory } from "../models/survey-engine/category";
import { CategoryType } from "../models/survey-engine/core/category-type.enum";

export interface ICategoryTypeRegistry {
  key?: string;
  type?: Type<CategoryBase>;
}

@Injectable({
  providedIn: "root"
})
export class CategoryFactoryService {
  constructor() {
    this.__registeredTypes = InjectorInstance.get<CategoryTypeRegistryCollection>(CATEGORY_REGISTRY);
  }

  createInstance(options?: ICategory): CategoryBase {
    options = options || {} as ICategory;
    options.categoryType = options.categoryType || 0;
    let key = CategoryType[options.categoryType].toString();
    key = this.normalizedKey(key || "");
    if (!!!key || !this.__hasType(key)) {
      return null;
    }

    let type: Type<CategoryBase> = null;
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        type = item.type;
        return false;
      }
    });

    return new type(options);
  }

  registerType(registry?: ICategoryTypeRegistry): boolean {
    registry = registry || {};
    registry.key = this.normalizedKey(registry.key || "");
    if (!!!registry.key) {
      return false;
    }
    if (!this.__hasType(registry.key)) {
      this.__registeredTypes.push(registry);
      return true;
    }
    return false;
  }

  private __hasType(key: string): boolean {
    let ok: boolean = false;
    key = this.normalizedKey(key || "");
    if (!!!key) {
      return false;
    }
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        ok = true;
        return false;
      }
    });

    return ok;
  }

  private normalizedKey(key: string): string {
    return key.toString().toLowerCase();
  }

  private __registeredTypes: CategoryTypeRegistryCollection;
}

export class CategoryTypeRegistryCollection extends Array<ICategoryTypeRegistry> {
  constructor(array?: Array<ICategoryTypeRegistry>) {
    super();
    Object.setPrototypeOf(this, CategoryTypeRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as ICategoryTypeRegistry);
    });
  }
}
