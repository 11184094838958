import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { QuestionAnswerBase } from '../models/survey-engine/core/question-answer-base';

@Injectable({
  providedIn: "root"
})
export class QuestionAnswerService {

  registerQuestion(name: string): QuestionAnswerRegistry {
    this.__registered[name] = new QuestionAnswerRegistry();
    return this.__registered[name];
  }

  getQuestionAnswerRegistry(name: string): QuestionAnswerRegistry {
    return this.__registered[name];
  }

  // getQualificationData(): IQuestionAnswerDTO {
  //   let questionCommonData: IQuestionAnswerDTO;
  //   questionCommonData.isTest = false;
  //   questionCommonData.respondId = 1;
  //   questionCommonData.respondentId = "1";
  //   questionCommonData.respondentToken = "";
  //   questionCommonData.surveyId = 1;
  //   questionCommonData.vendorId = 1;
  //   return questionCommonData;
  // }

  private __registered: { [name: string]: QuestionAnswerRegistry } = {};
}

export class QuestionAnswerRegistry {

  setAnswer(answer: QuestionAnswerBase) {
    this.answer.next(answer);
  }

  private answer = new Subject<QuestionAnswerBase>();

  answer$: Observable<QuestionAnswerBase> = this.answer.asObservable();

}
