var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PageCollection } from "./survey-engine/page";
import { EndingOutputBase } from "./ending-output-base";
import { URLType } from "../enums/url-type";
var SetStatusAfterCompleteOutputData = /** @class */ (function (_super) {
    __extends(SetStatusAfterCompleteOutputData, _super);
    function SetStatusAfterCompleteOutputData(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusAfterCompleteResponseApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
        options = options || {};
        _this.pages = new PageCollection(options.pages || {});
        _this.redirectUrl = options.redirectUrl || "";
        _this.urlType = options.urlType || URLType.None;
        return _this;
    }
    return SetStatusAfterCompleteOutputData;
}(EndingOutputBase));
export { SetStatusAfterCompleteOutputData };
