var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit, ElementRef, Renderer } from "@angular/core";
import { Validators } from "@angular/forms";
import { SingleImageAnswer } from "../../../../models/survey-engine/single-image-answer";
import "../../../../extensions/string-extensions";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";
import { CategoricalQuestionBaseComponent } from "../core/categorical-question-base.component";
import { NextBackService } from "../../../../services/next-back.service";
import { InjectorInstance } from "../../../../core/injector-instance";
import { LocalStorageService, SessionStorageService } from "ngx-store";
var VideoQuestionComponent = /** @class */ (function (_super) {
    __extends(VideoQuestionComponent, _super);
    function VideoQuestionComponent(elementRef, renderer, localStorage, sessionStorage) {
        var _this = _super.call(this) || this;
        _this.elementRef = elementRef;
        _this.renderer = renderer;
        _this.localStorage = localStorage;
        _this.sessionStorage = sessionStorage;
        _this.progressBarValue = 0;
        _this.answerId = 0;
        _this.respondent = null;
        _this.count = 0;
        _this.inputData = {};
        _this.imageId = 0;
        _this.__nextBackService = InjectorInstance.get(NextBackService);
        return _this;
    }
    VideoQuestionComponent.prototype.setQuestionAnswer = function (value) {
        var self = this;
        self.__questionAnswer = new SingleImageAnswer({
            value: self.getCategory(value).id
        });
        self.__questionAnswer.answerType = AnswerType.SinglePunchImage;
    };
    VideoQuestionComponent.prototype.vidplay = function (cid) {
        var video = document.getElementById("Video1_" + cid.toString() + "");
        if (video) {
            video.play();
        }
    };
    Object.defineProperty(VideoQuestionComponent.prototype, "categories", {
        get: function () {
            var categories = [];
            this.question.categories.$values.forEach(function (c) {
                categories.push(c);
            });
            console.log(categories);
            return categories;
        },
        enumerable: true,
        configurable: true
    });
    VideoQuestionComponent.prototype.updateProgressBar = function (obj) {
        var videoDesc = {
            respondentToken: this.respondent.respondentToken.toString(),
            videoDuration: null,
            currentTime: null
        };
        var qualificationid = this.inputData.categoryId.toString();
        if (this.localStorage.get("sc.videoDescriptionEnable_" + videoDesc.respondentToken + "_" + qualificationid.toString() + "") == true) {
            this.__nextBackService.enableNext(true);
        }
        var button = document.getElementById("play_" + qualificationid + "");
        button.className = '';
        var progressBar = document.getElementById('progressbar_' + qualificationid + '');
        var mediaPlayer = document.getElementById("Video1_" + qualificationid + "");
        if (mediaPlayer == null || mediaPlayer.duration == null && mediaPlayer.duration != "NaN")
            return;
        var videoData = this.localStorage.get("sc.videoDescription_" + videoDesc.respondentToken + "_" + qualificationid.toString() + "");
        if (!!videoData && this.count == 0) {
            if (videoData.currentTime != mediaPlayer.currentTime) {
                mediaPlayer.currentTime = videoData.currentTime;
            }
            videoDesc.currentTime = mediaPlayer.currentTime;
            this.localStorage.set("sc.videoDescription_" + videoDesc.respondentToken + "_" + qualificationid.toString() + "", videoDesc);
        }
        else {
            videoDesc.videoDuration = mediaPlayer.duration;
            videoDesc.currentTime = mediaPlayer.currentTime;
            this.localStorage.set("sc.videoDescription_" + videoDesc.respondentToken + "_" + qualificationid.toString() + "", videoDesc);
        }
        var percentage = Math.floor((100 / mediaPlayer.duration) * mediaPlayer.currentTime);
        progressBar.value = percentage;
        progressBar.innerHTML = percentage + '% played';
        this.progressBarValue = progressBar.value;
        var abc = document.getElementById("progress-value_" + qualificationid + "");
        var num = mediaPlayer.duration - mediaPlayer.currentTime;
        var hours = Math.floor(num / 60);
        var minutes = Math.floor(num % 60);
        abc.textContent = hours + ":" + minutes;
        if (!!progressBar && progressBar.value == 100) {
            var button_1 = document.getElementById("play_" + qualificationid + "");
            button_1.className = 'play';
            var buttonValue = this.localStorage.set("sc.videoDescriptionEnable_" + videoDesc.respondentToken + "_" + qualificationid.toString() + "", true);
            videoDesc.currentTime = 0;
            this.localStorage.set("sc.videoDescription_" + videoDesc.respondentToken + "_" + qualificationid.toString() + "", videoDesc);
            this.__nextBackService = InjectorInstance.get(NextBackService);
            this.__nextBackService.enableNext(true);
        }
        this.count++;
    };
    VideoQuestionComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        var self = this;
        var validators = [];
        if (self.question.validators.required) {
            validators.push(Validators.required);
        }
        self.triggerQuestionLoaded();
        this.question.categories.$values.forEach(function (c) {
            _this.inputData.categoryId = c.id;
            _this.inputData.questionId = c.questionId;
            _this.inputData.code = c.code;
            _this.inputData.videoPath = c.leftText;
        });
        this.setQuestionAnswer(this.inputData.code);
        self.ctrl.setValue(this.inputData.code);
        setTimeout(function () {
            var video = _this.elementRef.nativeElement.querySelector(".Video1_" + _this.inputData.categoryId + "");
            video.addEventListener('timeupdate', _this.updateProgressBar.bind(_this));
            var canvas = document.getElementById("canvas_" + _this.inputData.categoryId + "");
            var ctx = canvas.getContext('2d');
            video.addEventListener('play', function () {
                var $this = this; //cache
                (function loop() {
                    if (!$this.paused && !$this.ended) {
                        canvas.height = $this.videoHeight;
                        canvas.width = $this.videoWidth;
                        ctx.drawImage($this, 0, 0, canvas.width, canvas.height);
                        setTimeout(loop, 1000 / 30); // drawing at 30fps
                    }
                })();
            }, 0);
        }, 1);
        this.respondent = this.sessionStorage.get("sc.respondent");
        if (!this.respondent) {
            this.respondent = this.sessionStorage.get("sc.respondent.test");
        }
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            // navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            //     this.video.nativeElement.srcObject = stream;
            //     this.video.nativeElement.play();
            // });
        }
    };
    VideoQuestionComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    VideoQuestionComponent.prototype.categoryChanged = function (option, i) {
    };
    return VideoQuestionComponent;
}(CategoricalQuestionBaseComponent));
export { VideoQuestionComponent };
