import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { CORE_SURVEY_DROP_COOKIE_LIB, CORE_SURVEY_DROP_COOKIE_TIMEOUT } from './core/globals';

@NgModule({
  providers: [
    {
      provide: CORE_SURVEY_DROP_COOKIE_LIB,
      useValue: environment.coreSurveyDropCookie.lib
    },
    {
      provide: CORE_SURVEY_DROP_COOKIE_TIMEOUT,
      useValue: environment.coreSurveyDropCookie.timeout
    }
  ]
})
export class CoreSurveyDropCookieConfigModule { }
