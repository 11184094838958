import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "start",
        loadChildren: "app/components/start-page/start-page.module#StartPageModule"
      },
      {
        path: "survey",
        loadChildren: "app/components/survey-page/survey-page.module#SurveyPageModule"
      },
      {
        path: "ending",
        loadChildren: "app/components/end-page/end-page.module#EndPageModule"
      },
      {
        path: "ending/:params",
        loadChildren: "app/components/end-page/end-page.module#EndPageModule"
      },
      {
        path: "linkedin-auth",
        loadChildren: "app/components/linked-in-page/linked-in-page.module#LinkedInPageModule"
      },
      {
        path: "linkedin-auth/:params",
        loadChildren: "app/components/linked-in-page/linked-in-page.module#LinkedInPageModule"
      },
      {
        path: "mbd-google",
        loadChildren: "app/components/mbd-google-page/mbd-google-page.module#MBDGooglePageModule"
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
