import { OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Category } from '../../../../models/survey-engine/category';
import { CategoryBase } from '../../../../models/survey-engine/core/category-base';
import { QuestionBaseComponent } from './question-base.component';

export abstract class CategoricalQuestionBaseComponent extends QuestionBaseComponent implements OnInit, OnDestroy {
  abstract get categories(): Array<CategoryBase>;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const self = this;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  clickHandler(code: string, $event: Event): void {
    const self = this;
    const category: Category = self.getCategory(code);
    if (category.isOther) {
      $event.preventDefault();
    }
  }

  get otherCtrls(): { [key: string]: FormControl } {
    const self = this;
    const obj: { [key: string]: FormControl } = {};
    self.categories
      .filter((c: Category) => c.isOther)
      .forEach((c: Category) => {
        const key = `${self.question.name}_${c.code}_other`;
        if (self.form.contains(key)) {
          obj[c.code] = self.form.get(key) as FormControl;
        }
      });
    return Object.keys(obj).length ? obj : null;
  }

  getCategoryById(id: number): Category {
    const categories = this.categories.filter((c: Category) => c.id === id);
    return categories.length ? (categories[0] as Category) : null;
  }
  getCategory(code: string): Category {
    let category: Category = null;
    this.categories.forEach((c: Category) => {
      if (c.code.toString() === code.toString()) {
        category = c;
        return;
      }
    });
    return category;
  }
}
