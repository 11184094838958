import { OnInit } from '@angular/core';
import { MatSnackBarRef } from '@angular/material';
import { AlertType } from '../enums/alert-type';
var SnackBarAlertComponent = /** @class */ (function () {
    function SnackBarAlertComponent(ref, data) {
        this.ref = ref;
        this.data = data;
    }
    Object.defineProperty(SnackBarAlertComponent.prototype, "title", {
        get: function () {
            return this.data.title || "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SnackBarAlertComponent.prototype, "text", {
        get: function () {
            return this.data.text || "Empty text";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SnackBarAlertComponent.prototype, "code", {
        get: function () {
            return (this.data.code || "").toString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SnackBarAlertComponent.prototype, "url", {
        get: function () {
            return this.data.url || "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SnackBarAlertComponent.prototype, "isTest", {
        get: function () {
            return !!this.data.isTest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SnackBarAlertComponent.prototype, "isKnownError", {
        get: function () {
            return this.data.alertType === AlertType.Error && !!this.code;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SnackBarAlertComponent.prototype, "iconName", {
        get: function () {
            return AlertType[(this.data.alertType || 1)].toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SnackBarAlertComponent.prototype, "iconClass", {
        get: function () {
            return "icon-" + this.iconName;
        },
        enumerable: true,
        configurable: true
    });
    SnackBarAlertComponent.prototype.dismiss = function () {
        this.ref.dismiss();
    };
    SnackBarAlertComponent.prototype.ngOnInit = function () {
    };
    return SnackBarAlertComponent;
}());
export { SnackBarAlertComponent };
