var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OpenQuestionBase } from './core/open-question-base';
import { QuestionType } from './core/question-type.enum';
var CalendarQuestion = /** @class */ (function (_super) {
    __extends(CalendarQuestion, _super);
    function CalendarQuestion(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.CalendarQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
        _this.validators = (function (year, month) {
            return {
                required: true,
                pattern: "0([1-9]{1})|1([0-2]{1})\\/(19|20|21)[0-9]{2}",
                minDate: Date.UTC(year - 100, month),
                maxDate: Date.UTC(year, month)
            };
        })(new Date().getUTCFullYear(), new Date().getUTCMonth());
        options = options || {};
        _this.questionType = QuestionType.Calendar;
        options.validators = options.validators || { required: true, pattern: "0([1-9]{1})|1([0-2]{1})\\/(19|20|21)[0-9]{2}" };
        _this.validators.minDate = options.validators.minDate;
        _this.validators.maxDate = options.validators.maxDate;
        return _this;
    }
    return CalendarQuestion;
}(OpenQuestionBase));
export { CalendarQuestion };
