import { InjectorInstance } from '../../core/injector-instance';
import { CheckProfileResponseService } from '../../services/check-profile-response-factory.service';
import { IPageCollection } from '../survey-engine/page';
import { ProfileResponseType } from './profile-response-type.enum';

export interface ICheckProfileResponse {
  $type?: string;
  profileResponseType: ProfileResponseType;
  coreSurveyDropCookie: boolean;
}

export interface IRoutedResponse extends ISurveyCheckProfileResponse, IPolfishResponse, IRoutedProfileResponse {

}

export interface IRoutedProfileResponse {
  respondentId: number;
  respondentToken: string;
  surveyId: number;
  surveyToken: string;
  isPIDRouting: boolean;
  isRecontact: boolean;
  originalUserId?: number;
}

export interface IPolfishResponse {
  isPollfishEnabled: boolean;
  isPLRouterEnabled: boolean;
  clientInvokeUrl: string;
}

export interface ISurveyCheckProfileResponse {
  pages: IPageCollection;
  isSampleChainEnabled: boolean;
  verifyRelevantId: boolean;
  sampleChainDestinationPlatformId: string;
  sampleChainDestinationSurveyNumber: string;
  verifyRFGFingerPrint:boolean;
}

export abstract class CheckProfileResponseBase implements ICheckProfileResponse {
  abstract $type?: string = "";
  profileResponseType: ProfileResponseType;
  coreSurveyDropCookie: boolean;
  verifyRFGFingerPrint: boolean;
  constructor(options?: ICheckProfileResponse) {
    options = options || ({} as ICheckProfileResponse);
    this.profileResponseType = options.profileResponseType;
    this.coreSurveyDropCookie = options.coreSurveyDropCookie || false;
  }

  public static createInstance(options?: ICheckProfileResponse) {
    const checkProfileResponse = InjectorInstance.get<CheckProfileResponseService>(CheckProfileResponseService);
    return checkProfileResponse.createInstance(options);
  }

  hasPages(): boolean {
    return (function (arg: any): arg is ISurveyCheckProfileResponse {
      return arg.pages !== undefined;
    })(this);
  }
}
