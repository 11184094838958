import { Injector } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { APP_INSIGHTS_CONFIG } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import * as i0 from "@angular/core";
var AppInsightsService = /** @class */ (function () {
    function AppInsightsService(injector) {
        this.injector = injector;
        if (!InjectorInstance.hasInstance && this.injector) {
            InjectorInstance.setInstance(this.injector);
        }
        this.injector = this.injector || InjectorInstance;
        this.config = this.injector.get(APP_INSIGHTS_CONFIG);
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }
    }
    AppInsightsService.prototype.logPageView = function (name, url, properties, measurements, duration) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    };
    AppInsightsService.prototype.logEvent = function (name, properties, measurements) {
        AppInsights.trackEvent(name, properties, measurements);
    };
    AppInsightsService.prototype.logException = function (exception, properties) {
        AppInsights.trackException(exception, undefined, properties);
    };
    AppInsightsService.ngInjectableDef = i0.defineInjectable({ factory: function AppInsightsService_Factory() { return new AppInsightsService(i0.inject(i0.INJECTOR)); }, token: AppInsightsService, providedIn: "root" });
    return AppInsightsService;
}());
export { AppInsightsService };
