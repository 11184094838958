import { NgModule } from '@angular/core';

import { CHECK_PROFILE_RESPONSE_REGISTRY } from './core/globals';
import { ProfileResponseType } from './models/profile/profile-response-type.enum';
import { RedirectProfileResponse } from './models/profile/redirect-profile-response';
import { RoutedProfileResponse } from './models/profile/routed-profile-response';
import { TargetedProfileResponse } from './models/profile/targeted-profile-response';
import { CheckProfileResponseRegistryCollection } from './services/check-profile-response-factory.service';

@NgModule({
  providers: [
    {
      provide: CHECK_PROFILE_RESPONSE_REGISTRY,
      useFactory: function() {
        return new CheckProfileResponseRegistryCollection([
          {
            key: ProfileResponseType[ProfileResponseType.Targeted],
            type: TargetedProfileResponse
          },
          {
            key: ProfileResponseType[ProfileResponseType.Redirect],
            type: RedirectProfileResponse
          },
          {
            key: ProfileResponseType[ProfileResponseType.Routed],
            type: RoutedProfileResponse
          }
        ]);
      }
    }
  ]
})
export class CheckProfileResponseRegistryModule {}
