import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PageResponseDTO } from '../models/page-response-dto';

@Injectable({
  providedIn: "root"
})
export class NextBackService {
  // get pageIndex$(): Observable<number> {
  //   return this.pageIndex.asObservable();
  // }

  // get nextEnabled$(): Observable<boolean> {
  //   return this.nextEnabled.asObservable();
  // }

  // get backEnabled$(): Observable<boolean> {
  //   return this.backEnabled.asObservable();
  // }

  // Service message commands
  constructor() {
  }
  public submitPage(response:PageResponseDTO){
    this.pageIsSubmit.next(true);
    this.pageResponse.next(response);
  }

  next(index: number) {
    this.goTo(index + 1);
  }

  back(index: number) {
    this.goTo(index - 1);
  }

  goTo(index: number) {
    this.pageIndex.next(index);
  }

  showNext(show: boolean) {
    this.nextIsVisible.next(show);
  }

  showBack(show: boolean) {
    this.backIsVisible.next(show);
  }

  enableNext(enable: boolean) {
    this.nextEnabled.next(enable);
  }

  enableBack(enable: boolean) {
    this.backEnabled.next(enable);
  }

  private pageIndex = new Subject<number>();
  pageIndex$: Observable<number> = this.pageIndex.asObservable();

  private nextEnabled = new Subject<boolean>();
  nextEnabled$: Observable<boolean> = this.nextEnabled.asObservable();

  private backEnabled = new Subject<boolean>();
  backEnabled$: Observable<boolean> = this.backEnabled.asObservable();

  private nextIsVisible = new Subject<boolean>();
  nextIsVisible$: Observable<boolean> = this.nextIsVisible.asObservable();

  private backIsVisible = new Subject<boolean>();
  backIsVisible$: Observable<boolean> = this.backIsVisible.asObservable();

  private pageIsSubmit=new Subject<boolean>();
  pageIsSubmit$: Observable<boolean> = this.pageIsSubmit.asObservable();

  private pageResponse=new Subject<PageResponseDTO>();
  pageResponse$: Observable<PageResponseDTO> = this.pageResponse.asObservable();
}
