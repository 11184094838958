var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SAMPLECUBE_USER_TYPES } from "../core/globals";
import { InjectorInstance } from "../core/injector-instance";
var SampleCubeUserBase = /** @class */ (function () {
    function SampleCubeUserBase() {
        this.$type = "";
        this.isTest = false;
        this.userId = 0;
        this.userMainId = 0;
        this.languageId = 0;
        this.identificatinoCode = 0;
        this.age = 0;
        this.deviceId = 0;
        this.mob = 0;
        this.yob = 0;
        this.ipNumber = 0;
        this.duidHashCode = 0;
        this.vendorUserIdHashCode = 0;
        this.originalVendorId = 0;
        this.originalLanguageId = 0;
        this.visitedCount = 0;
        this.vendorId = 0;
        this.ipAddress = "";
        this.gender = "";
        this.zipCode = "";
        this.duid = "";
        this.vendorUserId = "";
        this.profileCreationDate = new Date();
        this.updateTimeStamp = new Date();
        this.isActive = false;
        this.isBeacon = false;
        this.emailHash = "";
        this.qualityScore = 0;
        this.logicHandled = false;
    }
    SampleCubeUserBase.instanceFrom = function (options) {
        if (!options) {
            return null;
        }
        var instance = null;
        if (!!!this.__registeredTypes) {
            this.__registeredTypes = InjectorInstance.get(SAMPLECUBE_USER_TYPES);
        }
        instance = new (this.__registeredTypes.resolve("SampleCubeUser")).type();
        for (var p in options) {
            if (options.hasOwnProperty(p)) {
                if (instance.hasOwnProperty(p)) {
                    instance[p] = options[p];
                }
            }
        }
        this.__registeredTypes.forEach(function (registeredType) {
            var concrete = new registeredType.type();
            concrete.handleLogic(options);
            if (concrete.logicHandled) {
                instance = concrete;
                return;
            }
        });
        instance.isTest = (options["chktestlink"] || "false") === "true";
        instance.languageId = (!options["lid"]) ? 0 : options["lid"];
        instance.qualityScore = (!options["qscore"]) ? 0 : options["qscore"];
        return instance;
    };
    return SampleCubeUserBase;
}());
export { SampleCubeUserBase };
var SampleCubeUser = /** @class */ (function (_super) {
    __extends(SampleCubeUser, _super);
    function SampleCubeUser() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SampleCubeUserApiModel, SampleCube.UserFlow.API.Models";
        return _this;
    }
    SampleCubeUser.prototype.handleLogic = function (options) { };
    return SampleCubeUser;
}(SampleCubeUserBase));
export { SampleCubeUser };
