<div class="modal" tabindex="-1" role="dialog">
  <button mat-button mat-mini-fab color="primary" class="close" (click)="dismiss()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <mat-icon [svgIcon]="iconName" [ngClass]="iconClass"></mat-icon>
          <span>{{title}}</span>
        </h5>
      </div>
      <div class="modal-body">
        <p>
          <span *ngIf="isKnownError"><i>Error {{code}}:&nbsp;</i></span>
          <span>{{data.text}}</span>
          <br />
          <span *ngIf="isTest"><code>{{url}}</code></span>
        </p>
      </div>
    </div>
  </div>
</div>