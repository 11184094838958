
import { EndingInputDataBase } from "./ending";
import { IPageCollection, PageCollection } from "./survey-engine/page";
import { ISurveyCheckProfileResponse } from "./profile/check-profile-response-base";
import { URLType } from "../enums/url-type";
import { IEndingOutputBase, EndingOutputBase } from "./ending-output-base";

export interface ISetStatusRouted extends IEndingOutputBase {
  pages?: IPageCollection;
  respondentId: number;
  respondentToken: string;
  surveyId: number;
  surveyToken: string;
  isPollfishEnabled: boolean;
  isPLRouterEnabled: boolean;
  clientInvokeUrl: string;
  isSampleChainEnabled: boolean;
  verifyRelevantId: boolean;
  isRecontact: boolean;
  isPIDRouting: boolean;
  originalUserId?: number;
  sampleChainDestinationPlatformId: string;
  sampleChainDestinationSurveyNumber: string;
  verifyRFGFingerPrint: boolean;
}

export class SetStatusRoutedOutputData extends EndingOutputBase implements ISetStatusRouted {
  $type: string =
    "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusRoutedResponseApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
  pages: IPageCollection = new PageCollection();
  respondentId: number;
  respondentToken: string;
  surveyId: number;
  surveyToken: string;
  isPollfishEnabled: boolean;
  isPLRouterEnabled: boolean;
  clientInvokeUrl: string;
  isSampleChainEnabled: boolean;
  verifyRelevantId: boolean;
  isRecontact: boolean;
  isPIDRouting: boolean;
  originalUserId?: number = 0;
  sampleChainDestinationPlatformId: string;
  sampleChainDestinationSurveyNumber: string;
  verifyRFGFingerPrint: boolean;

  constructor(options?: ISetStatusRouted) {
    super(options as IEndingOutputBase);
    options = options || ({} as ISetStatusRouted);
    this.pages = new PageCollection(options.pages || ({} as IPageCollection));
    this.respondentId = options.respondentId;
    this.respondentToken = options.respondentToken;
    this.surveyId = options.surveyId;
    this.surveyToken = options.surveyToken;
    this.isPLRouterEnabled = options.isPLRouterEnabled;
    this.isPollfishEnabled = options.isPollfishEnabled;
    this.clientInvokeUrl = options.clientInvokeUrl;
    this.isSampleChainEnabled = options.isSampleChainEnabled;
    this.verifyRelevantId = options.verifyRelevantId;
    this.isRecontact = options.isRecontact;
    this.isPIDRouting = options.isPIDRouting;
    this.originalUserId = options.originalUserId || 0;
    this.sampleChainDestinationPlatformId = options.sampleChainDestinationPlatformId || "";
    this.sampleChainDestinationSurveyNumber = options.sampleChainDestinationSurveyNumber || "";
    this.verifyRFGFingerPrint = options.verifyRFGFingerPrint;
  }
}
