var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EndingInputDataBase } from "./ending";
var PureSpectrumData = /** @class */ (function (_super) {
    __extends(PureSpectrumData, _super);
    function PureSpectrumData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.EndingPage.DTOs.PureSpectrumSetStatusRequestApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
        _this.returnStatus = "";
        _this.sHash = "";
        _this.userId = "";
        return _this;
    }
    PureSpectrumData.prototype.parseUrl = function () {
        return "";
    };
    PureSpectrumData.prototype.handleLogic = function (urlParams) {
        if (!!urlParams["ps_rstatus"]) {
            this.returnStatus = urlParams["ps_rstatus"];
            this.__logicHandled = true;
        }
        if (!!urlParams["ps_s_hash"]) {
            this.sHash = urlParams["ps_s_hash"];
            this.__logicHandled = true;
        }
        if (!!urlParams["ps_psid"]) {
            this.userId = urlParams["ps_psid"];
            this.__logicHandled = true;
        }
    };
    return PureSpectrumData;
}(EndingInputDataBase));
export { PureSpectrumData };
