<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container">
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->
  <!-- <mat-toolbar class="topbar telative"> -->
    <!-- ============================================================== -->
    <!-- Logo - style you can find in header.scss -->
    <!-- ============================================================== -->
    <!-- <div class="navbar-header">
      <a class="navbar-brand" href="javascript:void(0)"> -->
        <!-- Logo icon -->
        <!-- <b> -->
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Light Logo icon -->
          <!-- <img src="assets/img/logo.png" alt="SampleCube_Logo" height="64px" class="light-logo"> -->
        <!-- </b> -->
        <!--End Logo icon -->
      <!-- </a>
    </div> -->
    <!-- ============================================================== -->
    <!-- sidebar toggle -->
    <!-- ============================================================== -->

  <!-- </mat-toolbar> -->
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">

    <mat-sidenav-content class="page-wrapper">

      <div class="page-content">

        <router-outlet>
          <app-top-loader></app-top-loader>
        </router-outlet>

      </div>

    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
