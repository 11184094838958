var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { ComponentBaseComponent } from './core/component-base.component';
import { CheesecakeStep } from './enums/cheesecake-step';
import { Utilities } from './utils/utilities';
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent() {
        var _this = _super.call(this, "Surveys") || this;
        _this.step = CheesecakeStep.Landed;
        return _this;
    }
    Object.defineProperty(AppComponent.prototype, "url", {
        get: function () {
            return "";
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.path.indexOf("/ending") > -1) {
            var url = "/ending";
            if (!Utilities.urlHandler) {
                url += "?" + Utilities.getQueryString();
            }
            return this.navigateTo(url, true);
        }
        else if (this.path.indexOf("/mbd-google") > -1) {
            this.setQueryParams();
            this.saveQueryParams();
            var url = "/mbd-google";
            if (!Utilities.urlHandler) {
                url += "?" + Utilities.getQueryString();
            }
            return this.navigateTo(url, true);
        }
        this.setQueryParams();
        if (this.sessionParams["sid"] && this.urlParams["sid"] && this.urlParams["sid"].toString() !== this.sessionParams["sid"]) {
            this.sessionStorageService.clear();
        }
        if (this.urlParams["chktestlink"] !== this.sessionParams["chktestlink"]) {
            this.sessionStorageService.clear();
        }
        this.saveQueryParams();
        if (this.sessionStorageService.get("sc.step") === CheesecakeStep.LinkedInResponse && window.location.href.toLowerCase().indexOf("user_cancelled") > 0) {
            this.sessionStorageService.set("sc.step", CheesecakeStep.LinkedInFailed);
        }
        var flowStep = this.sessionStorageService.get("sc.step");
        this.step = flowStep ? flowStep : this.step;
        this.sessionStorageService.set("sc.step", this.step);
        switch (this.step) {
            case CheesecakeStep.CreateProfile: {
                this.navigateTo("/survey/demographics", true);
                break;
            }
            case CheesecakeStep.ShowQualifications: {
                this.navigateTo("/survey", true);
                break;
            }
            case CheesecakeStep.LinkedInStart: {
                this.navigateTo("/linkedin-auth", true);
                break;
            }
            // case CheesecakeStep.LinkedInResponse: {
            //   this.navigateTo("/linkedin-auth/:params", true);
            //   break;
            // }
            case CheesecakeStep.LinkedInResponse: {
                this.navigateTo("/linkedin-auth?" + Utilities.getQueryString(), true);
                break;
            }
            case CheesecakeStep.LinkedInFailed: {
                this.navigateTo("/linkedin-auth?" + Utilities.getQueryString(), true);
                break;
            }
            case CheesecakeStep.AfterComplete: {
                this.navigateTo("/survey/after-complete?" + Utilities.getQueryString(), true);
                break;
            }
            case CheesecakeStep.AfterCompleteReconfirmContact: {
                this.navigateTo("/survey/after-complete-reconfirm-contact?" + Utilities.getQueryString(), true);
                break;
            }
            case CheesecakeStep.Landed:
            case CheesecakeStep.CreateSession:
            default: {
                this.navigateTo("/start", true);
                break;
            }
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnInit.call(this);
    };
    AppComponent.prototype.setQueryParams = function () {
        var params = Utilities.parseQuery();
        this.urlParams = params;
    };
    AppComponent.prototype.saveQueryParams = function () {
        var params = this.urlParams;
        params["absurl"] = Utilities.absoluteUrl;
        params["referralurl"] = window.document.referrer;
        var keys = Object.keys(params);
        if (keys && keys.length) {
            this.sessionStorageService.set("sc.params", params);
        }
        if (!!!this.storage["sc.landing-url"]) {
            // this.sessionStorageService.set("sc.landing-url", Utilities.absoluteUrl);
            this.sessionStorageService.set("sc.landing-url", window.location.href);
        }
        if (!!!this.storage["sc.is-test"]) {
            var ok = !!this.urlParams["rid"] && this.urlParams["rid"].indexOf("test") >= 0;
            ok = ok || Utilities.absoluteUrl.indexOf("chktestlink=true") >= 0;
            this.sessionStorageService.set("sc.is-test", ok);
        }
    };
    return AppComponent;
}(ComponentBaseComponent));
export { AppComponent };
