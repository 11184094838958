import { InjectorInstance } from '../../../core/injector-instance';
import { AnswerFactoryService } from '../../../services/answer-factory.service';
import { AnswerType } from './answer-type.enum';

export interface IQuestionAnswer {
  $type?: string;
  questionId?: number;
  // value?: any;
  answerType?: AnswerType;
}

export abstract class QuestionAnswerBase implements IQuestionAnswer {
  abstract $type: string = "";
  questionId: number = 0;
   // value: any;
  answerType?: AnswerType;

  constructor(options?: IQuestionAnswer) {
    options = options || ({} as IQuestionAnswer);
    this.questionId = options.questionId || 0;
     // this.value = options.value || null;
    this.answerType = options.answerType || 0;
  }

  public static createInstance(options?: IQuestionAnswer) {
    const answerFactoryService = InjectorInstance.get<AnswerFactoryService>(AnswerFactoryService);
    return answerFactoryService.createInstance(options);
  }
}

export interface IQuestionAnswerCollection {
  $type?: string;
  $values?: Array<IQuestionAnswer>;
  add?: (item: IQuestionAnswer) => void;
}

export class QuestionAnswerCollection implements IQuestionAnswerCollection {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.QuestionAnswerCollectionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  $values: Array<IQuestionAnswer> = [];

  constructor(options?: Array<IQuestionAnswer> | IQuestionAnswerCollection) {
    Object.setPrototypeOf(this, QuestionAnswerCollection.prototype);
    const self = this;
    let array: Array<IQuestionAnswer> = null;
    if (!!options && !!(options as IQuestionAnswerCollection).$values) {
      array = (options as IQuestionAnswerCollection).$values || [];
    } else {
      array = (options as Array<IQuestionAnswer>) || [];
    }
    if (array && array.length) {
      array.forEach(item => {
        if (!(item instanceof QuestionAnswerBase)) {
          item = QuestionAnswerBase.createInstance(item);
        }
        this.$values.push(item as QuestionAnswerBase);
      });
    }
  }

  add(item: IQuestionAnswer) {
    this.$values.push(item);
  }
}
