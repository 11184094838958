import { EndingInputDataBase } from '../models/ending';
import { Type } from "@angular/core";

export interface IEndingDataTypeRegistry {
  key?: string;
  type?: Type<EndingInputDataBase>;
}

export class EndingDataTypeRegistryCollection extends Array<IEndingDataTypeRegistry> {
  constructor(array?: Array<IEndingDataTypeRegistry>) {
    super();
    Object.setPrototypeOf(this, EndingDataTypeRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as IEndingDataTypeRegistry);
    });
  }

  resolve(key: string): IEndingDataTypeRegistry {
    let registry: IEndingDataTypeRegistry = null;
    this.forEach((item: IEndingDataTypeRegistry) => {
      if (item.key === key) {
        registry = item;
        return;
      }
    });
    return registry;
  }
}
