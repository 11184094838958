import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class PageLoadService {

  constructor() {
    const self = this;
    self.loaded.next(false);
  }
  registerQuestion(name: string): QuestionLoadRegistry {
    const self = this;
    self.__registered[name] = new QuestionLoadRegistry();
    self.__loadedQuestions[name] = false;
    self.__questionLoadSubscriptions.push(
      self.__registered[name].loaded$.subscribe(loaded => {
        self.__loadedQuestions[name] = loaded;
        const pageLoaded = !(Object.keys(self.__loadedQuestions)
                            .some(qName => !self.__loadedQuestions[qName]));
        self.loaded.next(pageLoaded);
      })
    );
    return self.__registered[name];
  }
  getQuestionLoadRegistry(name: string): QuestionLoadRegistry {
    return this.__registered[name];
  }
  unsubscribe() {
    const self = this;
    self.__questionLoadSubscriptions.forEach(subscription => {
      if (!subscription.closed) {
        subscription.unsubscribe();
      }
    });
  }
  private loaded = new Subject<boolean>();
  loaded$: Observable<boolean> = this.loaded.asObservable();
  private __questionLoadSubscriptions: Subscription[] = [];

  private __registered: { [name: string]: QuestionLoadRegistry } = {};
  private __loadedQuestions: { [name: string]: boolean } = {};
}

export class QuestionLoadRegistry {

  constructor() {
    this.loaded.next(false);
  }
  triggerQuestionLoaded() {
    this.loaded.next(true);
  }
  private loaded = new Subject<boolean>();
  loaded$: Observable<boolean> = this.loaded.asObservable();

}
