import { Injectable, Injector } from '@angular/core';
import { AppInsightsService } from './app-insights.service';
import { InjectorInstance } from '../core/injector-instance';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private appInsightsService: AppInsightsService;
  private locationService: Location;

  constructor(
    private injector?: Injector
  ) {
    if (!InjectorInstance.hasInstance && this.injector) {
      InjectorInstance.setInstance(this.injector);
    }
    this.appInsightsService = InjectorInstance.get<AppInsightsService>(AppInsightsService);
    this.locationService = InjectorInstance.get<Location>(Location);
  }

  error(exception?: any, ...optionalParams: any[]): void {
    const params: any[] = [];
    params.push(`path: ${this.path}`);
    params.push(exception);
    optionalParams.forEach(param => {
      params.push(param);
    });
    console.warn.apply(undefined, params);
    const debugData: {[name: string]: any} = {};
    optionalParams.forEach(value => {
      const key: string = Object.keys({value})[0];
      debugData[key] = value;
    });
    this.sendErrorToAppInsights(exception, debugData);
  }

  log(message?: any, ...optionalParams: any[]): void {
    const params: any[] = [];
    params.push(`path: ${this.path}`);
    params.push(message);
    optionalParams.forEach(param => {
      params.push(param);
    });
    console.log.apply(undefined, params);
  }

  trackPageView(name: string) {
    this.appInsightsService.logPageView(name, this.path);
  }

  sendLogToAppInsights(message?: any, debugData?: {[name: string]: any}) {
    debugData = debugData || {};
    debugData['path'] = this.path;
    this.appInsightsService.logEvent(message, <{[name: string]: string}>debugData);
  }

  sendErrorToAppInsights(error: Error, debugData?: {[name: string]: any}) {
    debugData = debugData || {};
    debugData['path'] = this.path;
    this.appInsightsService.logException(error, <{[name: string]: string}>debugData);
  }

  private get path(): string {
    let path: string = this.locationService.path();
    const parts: string[] = path.split('?');
    if (parts.length === 2) {
      path = parts[0];
    }
    return path === '' ? '/' : path;
  }
}
