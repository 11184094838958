import { IRedirectCheckQualificationResponse, CheckQualificationResponseBase, ICheckQualificationResponse } from './check-qualification-response-base';

export interface ICheckQualificationClientResponse extends IRedirectCheckQualificationResponse {
}

export class CheckQualificationClientResponse extends CheckQualificationResponseBase implements ICheckQualificationClientResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationClientResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  redirectUrl: string;

  constructor(options?: ICheckQualificationClientResponse) {
    super(options as ICheckQualificationResponse);
    options = options || ({} as ICheckQualificationClientResponse);
    this.redirectUrl = options.redirectUrl || "";
  }
}
