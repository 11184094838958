
import { EndingInputDataBase } from "./ending";
import { IPageCollection } from "./survey-engine/page";
import { ISurveyCheckProfileResponse } from "./profile/check-profile-response-base";
import { URLType } from "../enums/url-type";
import { IEndingOutputBase, EndingOutputBase } from "./ending-output-base";

export interface ISetStatusRedirect extends IEndingOutputBase {
  redirectUrl?: string;
  urlType?: URLType;
}

export class SetStatusRedirectOutputData extends EndingOutputBase implements ISetStatusRedirect {
  $type: string =
    "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusRedirectResponseApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
  redirectUrl: string = "";
  urlType: URLType = URLType.None;

  constructor(options?: ISetStatusRedirect) {
    super(options as IEndingOutputBase);
    options = options || ({} as ISetStatusRedirect);
    this.redirectUrl = options.redirectUrl || "";
    this.urlType = options.urlType || URLType.None;
  }
}
