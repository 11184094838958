import { IQuestionAnswer, QuestionAnswerBase } from './core/question-answer-base';

export interface ISingleImageAnswer extends IQuestionAnswer {
  value: number;
}

export class SingleImageAnswer extends QuestionAnswerBase implements ISingleImageAnswer {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.SinglePunchImageAnswerApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  value: number = 0;
  constructor(options?: ISingleImageAnswer) {
    super(options as IQuestionAnswer);
    options = options || ({ value: 0 } as ISingleImageAnswer);
    this.value = options.value || 0;
  }
}
