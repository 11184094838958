import { IQuestionAnswer, QuestionAnswerBase } from './core/question-answer-base';

export interface ISingleAnswer extends IQuestionAnswer {
  value: number;
}

export class SingleAnswer extends QuestionAnswerBase implements ISingleAnswer {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.SingleAnswerApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  value: number = 0;
  constructor(options?: ISingleAnswer) {
    super(options as IQuestionAnswer);
    options = options || ({ value: 0 } as ISingleAnswer);
    this.value = options.value || 0;
  }
}
