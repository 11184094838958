import { IQuestionAnswer, QuestionAnswerBase } from './core/question-answer-base';

export interface IOpenAnswer extends IQuestionAnswer {
  value: string;
}

export class OpenAnswer extends QuestionAnswerBase implements IOpenAnswer {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.OpenAnswerApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  value: string = "";
  constructor(options?: IOpenAnswer) {
    super(options as IQuestionAnswer);
    options = options || ({ value: "" } as IOpenAnswer);
    this.value = options.value || "";
  }
}
