import { InjectorInstance } from '../../core/injector-instance';
import { CheckQualificationResponseService } from '../../services/check-qualification-response-factory.service';
import { IPageCollection } from './page';
import { CheckQualificationResponseType } from '../../enums/check-qualification-response-type.enum';
import { ISurveyCheckProfileResponse } from '../profile/check-profile-response-base';

export interface ICheckQualificationResponse {
  $type?: string;
  checkQualificationResponseType: CheckQualificationResponseType;
}

export interface IRedirectCheckQualificationResponse extends ICheckQualificationResponse {
  redirectUrl?: string;
}

export interface IRedirectRoutedCheckQualificationResponse extends IRedirectCheckQualificationResponse {
  respondentId: number;
  respondentToken: string;
  surveyId: number;
  scUserId: number;
}

export abstract class CheckQualificationResponseBase implements ICheckQualificationResponse {
  abstract $type?: string = "";
  checkQualificationResponseType: CheckQualificationResponseType;

  constructor(options?: ICheckQualificationResponse) {
    options = options || ({} as ICheckQualificationResponse);
    this.checkQualificationResponseType = options.checkQualificationResponseType;
  }

  public static createInstance(options?: ICheckQualificationResponse) {
    const checkQualificationResponse = InjectorInstance.get<CheckQualificationResponseService>(CheckQualificationResponseService);
    return checkQualificationResponse.createInstance(options);
  }


  hasPages(): boolean {
    return (function (arg: any): arg is ISurveyCheckProfileResponse {
      return arg.pages !== undefined;
    })(this);
  }
}
