import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { TopLoaderService } from '../services/top-loader.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/top-loader.service";
var LoaderHttpInterceptor = /** @class */ (function () {
    function LoaderHttpInterceptor(loaderService) {
        this.loaderService = loaderService;
    }
    LoaderHttpInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        this.showLoader();
        return next.handle(req)
            .pipe(tap(function (event) {
            if (event instanceof HttpResponse) {
                _this.onEnd();
            }
        }, function (err) {
            _this.onEnd();
        }));
    };
    LoaderHttpInterceptor.prototype.onEnd = function () {
        this.hideLoader();
    };
    LoaderHttpInterceptor.prototype.showLoader = function () {
        this.loaderService.show();
    };
    LoaderHttpInterceptor.prototype.hideLoader = function () {
        this.loaderService.hide();
    };
    LoaderHttpInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function LoaderHttpInterceptor_Factory() { return new LoaderHttpInterceptor(i0.inject(i1.TopLoaderService)); }, token: LoaderHttpInterceptor, providedIn: "root" });
    return LoaderHttpInterceptor;
}());
export { LoaderHttpInterceptor };
