import {
  ICategoricalQuestion,
  CategoricalQuestionBase
} from "./core/categorical-question-base";
import { QuestionType } from "./core/question-type.enum";

export interface ISingleQuestion extends ICategoricalQuestion { }

export class SingleQuestion extends CategoricalQuestionBase
  implements ICategoricalQuestion {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.SingleQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  constructor(options?: ISingleQuestion) {
    super(options as ICategoricalQuestion);
    this.questionType = QuestionType.Single;
    return this;
  }
}
