import { Type } from "@angular/core";
import { SampleCubeUserBase } from '../models/samplecube-user';

export interface ISampleCubeUserTypeRegistry {
  key?: string;
  type?: Type<SampleCubeUserBase>;
}

export class SampleCubeUserTypeRegistryCollection extends Array<ISampleCubeUserTypeRegistry> {
  constructor(array?: Array<ISampleCubeUserTypeRegistry>) {
    super();
    Object.setPrototypeOf(this, SampleCubeUserTypeRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as ISampleCubeUserTypeRegistry);
    });
  }

  resolve(key: string): ISampleCubeUserTypeRegistry {
    let registry: ISampleCubeUserTypeRegistry = null;
    this.forEach((item: ISampleCubeUserTypeRegistry) => {
      if (item.key === key) {
        registry = item;
        return;
      }
    });
    return registry;
  }
}
