import { Injector, Type, InjectionToken, StaticProvider } from '@angular/core';

export class InjectorInstance {

  static instance: Injector;

  static get hasInstance(): boolean {
    return <boolean>!!this.instance;
  }

  constructor() {
  }

  static get<T>(token: Type<T> | InjectionToken<T>): T {
    return this.instance.get<T>(token);
  }

  static create(options: { providers: StaticProvider[], parent?: Injector, name?: string }): void {
    Injector.create(options);
  }

  static setInstance(injector: Injector): void {
    if (!this.hasInstance) {
      this.instance = injector;
    }
  }
}
