import { OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-store';
import { Subject } from 'rxjs';

import { AlertType } from '../enums/alert-type';
import { AlertData } from '../interfaces/alert-data';
import { TopLoaderState } from '../interfaces/top-loader-state';
import { Parameter, ParameterCollection } from '../models/parameter';
import { IRespondent, Respondent } from '../models/respondent';
import { LoggerService } from '../services/logger.service';
import { SnackBarAlertComponent } from '../snack-bar-alert/snack-bar-alert.component';
import { Utilities } from '../utils/utilities';
import { ENV_NAME, IS_PROD_ENVIRONMENT } from './globals';
import { InjectorInstance } from './injector-instance';

export abstract class ComponentBaseComponent implements OnInit, OnDestroy {
  private _name: string;
  private _title: string;
  private logger: LoggerService;
  private titleService: Title;
  private snackBar: MatSnackBar;
  private routing: Router;
  protected sessionStorageService: SessionStorageService;
  protected localStorageService: LocalStorageService;
  public _envName: string;
  public _envIsProd: boolean = false;
  protected message: string = "";
  private loaderSubject = new Subject<TopLoaderState>();

  public get isTestUrl(): boolean {
    return (this.sessionParams["chktestlink"] || "false") === "true";
  }
  public get isTestCache(): boolean {
    return this.cachedRespondent.isTest;
  }
  public get cachedRespondent(): IRespondent {
    const respondent = this.sessionStorageService.get("sc.respondent");
    const respondentTest = this.sessionStorageService.get("sc.respondent.test");
    if (!!!respondent && !!!respondentTest) {
      return null;
    }
    // if(!!!respondent?.respondentToken && !!!respondentTest?.respondentToken){
    //   return null;
    // }
    const isTest = !!!respondent && !!respondentTest;
    const result = new Respondent(isTest ? respondentTest : respondent);
    if(!!!result.respondentToken){
      return null;
    }
    return result;
  }
  protected get path(): string {
    return Utilities.getCurrentPath();
  }

  constructor(title?: string) {
    // Manually retrieve the monitoring service from the injector
    // so that constructor has no dependencies that must be passed in from child
    this.titleService = InjectorInstance.get<Title>(Title);
    this.logger = InjectorInstance.get<LoggerService>(LoggerService);
    this.snackBar = InjectorInstance.get<MatSnackBar>(MatSnackBar);
    this.routing = InjectorInstance.get<Router>(Router);
    this.sessionStorageService = InjectorInstance.get<SessionStorageService>(SessionStorageService);
    this.localStorageService = InjectorInstance.get<LocalStorageService>(LocalStorageService);
    this._envName = InjectorInstance.get<string>(ENV_NAME);
    this._envIsProd = InjectorInstance.get<boolean>(IS_PROD_ENVIRONMENT);
    this.disableBackButton();

    this._name = this.constructor.name;
    this._title = title || "";
  }

  alert(data: AlertData, duration?: number) {
    setTimeout(() => {
      duration = duration || 0;
      if (duration <= 0) {
        this.snackBar.openFromComponent(SnackBarAlertComponent, { data: data });
      } else {
        this.snackBar.openFromComponent(SnackBarAlertComponent, {
          data: data,
          duration: duration
        });
      }
    });
  }

  navigateTo(path: string, changeLocation?: boolean) {
    changeLocation = changeLocation || false;
    this.routing.navigateByUrl(path, { skipLocationChange: !changeLocation });
  }

  ngOnInit() {
    this.titleService.setTitle(this._title);
    this.logNavigation();
  }

  ngOnDestroy() { }

  private logNavigation() {
    this.logger.trackPageView(this._name);
  }
  protected getParameters(): void {
    const sessionParams = this.sessionStorageService.get("sc.params");
    for (const p in sessionParams) {
      if (sessionParams.hasOwnProperty(p)) {
        this.params[p] = sessionParams[p];
      }
    }
  }

  protected get storage(): { [name: string]: any } {
    const obj: { [name: string]: any } = {};
    const prefix = this.sessionStorageService.config.prefix;
    const keys = this.sessionStorageService.keys;
    keys.forEach(k => {
      k = k.replace(prefix, "");
      obj[k] = this.sessionStorageService.get(k);
    });
    return obj;
  }

  protected get sessionParams(): { [name: string]: string } {
    const sessionParams: { [name: string]: string } = this.sessionStorageService.get("sc.params");
    return sessionParams || {};
  }

  protected get urlParams(): { [name: string]: string | number | boolean } {
    return this.__urlParams;
  }
  protected set urlParams(value: { [name: string]: string | number | boolean }) {
    this.__urlParams = value;
  }
  private disableBackButton() {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  }
  displayAlert(message?: string, type?: AlertType): void {
    const self = this;
    message = message || this.message;
    if (!!message) {
      self.alert({
        alertType: type || AlertType.Info,
        text: message,
        title: "Message"
      });
    }
  }
  protected params: { [name: string]: string | number | boolean } = {};
  getUrlData(): ParameterCollection {
    const urlParams = this.sessionStorageService.get("sc.params");
    const urlData: ParameterCollection = new ParameterCollection();
    for (const key in urlParams) {
      if (urlParams.hasOwnProperty(key)) {
        let valueData: any = urlParams[key].toString();
        if (key.toLowerCase() === "gender" && (valueData == 111 || valueData == 112)) {
          valueData = valueData === 111 ? 1 : 2;
        }
        urlData.$values.push(
          new Parameter({
            key: key.toLowerCase(),
            value: valueData
          })
        );
      }
    }
    return urlData;
  }

  protected getGenderFromUrl(): number {
    let urlGender: number = 0;
    if (this.params["gender"] === 'm' || this.params["gender"] === 'M'){
			urlGender = 1;
		}
		else if (this.params["gender"] === 'f' || this.params["gender"] === 'F'){
			urlGender = 2;
		} else {
			urlGender = Number(this.params["gender"] || 0);
		}
		if (urlGender === 111 || urlGender === 112) {
			urlGender = urlGender === 111 ? 1 : 2;
    }
    return urlGender;
  }

  public showLoader(): void {
    this.loaderSubject.next(<TopLoaderState>{ show: true });
  }

  public hideLoader(): void {
    this.loaderSubject.next(<TopLoaderState>{ show: false });
  }

  loaderState = this.loaderSubject.asObservable();
  private __urlParams: { [name: string]: string | number | boolean } = {};
}
