export enum QuestionType {
  Single = 1,
  Multi = 2,
  Open = 3,
  Numeric = 4,
  MultiOpen = 5,
  Range = 6,
  EmailType = 7,
  Info = 8,
  Compound = 9,
  Calendar = 10,
  SinglePunchImage = 11,
  MultiPunchImage = 12,
  VideoType=14
}
