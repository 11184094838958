export enum CheesecakeStep {
  Landed = 0,
  CreateSession = 1,
  CreateProfile = 2,
  ShowQualifications = 3,
  RedirectToClient = 4,
  RedirectToSupplier = 5,
  BackFromClient = 6,
  LinkedInStart = 7,
  LinkedInResponse = 8,
  LinkedInFailed = 9,
  AfterComplete = 10,
  MBDGoogleStart = 11,
  AfterCompleteReconfirmContact = 12,
  AfterCompleteConsent = 13
}
