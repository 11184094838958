import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { TopLoaderState } from "../interfaces/top-loader-state";
import { TopLoaderService } from "../services/top-loader.service";
import { Subscription } from "rxjs";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from "@angular/router";

@Component({
  selector: "app-top-loader",
  templateUrl: "./top-loader.component.html",
  styleUrls: ["./top-loader.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TopLoaderComponent implements OnInit, OnDestroy {
  show = false;
  private httpSubscription: Subscription;
  private routerEventsSubscription: Subscription;

  constructor(
    private router: Router,
    private loaderService: TopLoaderService
  ) {}

  ngOnInit() {
    this.httpSubscription = this.loaderService.loaderState.subscribe(
      (state: TopLoaderState) => {
        this.show = state.show;
      }
    );

    this.routerEventsSubscription = this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.show = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.show = false;
        }
      },
      () => {
        this.show = false;
      }
    );
  }

  ngOnDestroy() {
    this.httpSubscription.unsubscribe();
    this.routerEventsSubscription.unsubscribe();
  }
}
