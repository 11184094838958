export enum CallType {
  NoData = 0,
  Cookie = 1,
  Call = 2,
  Failed = 3
}

export enum APICallType {
  ThirdPartyAPI = 0,
  MarketCubeAPI = 1
}
