import { Injectable } from "@angular/core";
import { InjectorInstance } from '../core/injector-instance';
import { ICON_MAP } from '../core/globals';

export interface IIconRegistry {
  name?: string;
  path?: string;
}

@Injectable({
  providedIn: "root"
})
export class IconFactoryService {

  get registeredIcons(): IconRegistryCollection {
    return this.__registeredIcons;
  }

  constructor() {
    this.__registeredIcons = InjectorInstance.get<IconRegistryCollection>(ICON_MAP);
  }

  resolve(name: string): string {
    name = this.normalizedName(name || "");
    if (!!!name || !this.__hasPath(name)) {
      return null;
    }

    let path: string = null;
    this.__registeredIcons.forEach(item => {
      if (this.normalizedName(item.name) === name) {
        path = item.path;
        return false;
      }
    });

    return path;
  }

  registerPath(registry?: IIconRegistry): boolean {
    registry = registry || {};
    registry.name = this.normalizedName(registry.name || "");
    if (!!!registry.name) {
      return false;
    }
    if (!this.__hasPath(registry.name)) {
      this.__registeredIcons.push(registry);
      return true;
    }
    return false;
  }

  private __hasPath(name: string): boolean {
    let ok: boolean = false;
    name = this.normalizedName(name || "");
    if (!!!name) {
      return false;
    }
    this.__registeredIcons.forEach(item => {
      if (this.normalizedName(item.name) === name) {
        ok = true;
        return false;
      }
    });

    return ok;
  }

  private normalizedName(name: string): string {
    return name.toString().toLowerCase();
  }

  private __registeredIcons: IconRegistryCollection;
}

export class IconRegistryCollection extends Array<IIconRegistry> {
  constructor(array?: Array<IIconRegistry>) {
    super();
    Object.setPrototypeOf(this, IconRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as IIconRegistry);
    });
  }
}
