import { EndingInputDataBase } from "./ending";

export class SSIData extends EndingInputDataBase {
  $type: string =
    "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SSISetStatusRequestApiDTO, SampleCube.UserFlow.API.Models.EndingPage";

  profileParameter: string = "";
  hHash: string = "";
  public parseUrl(): string {
    return "profile=" + this.profileParameter;
  }

  protected handleLogic(urlParams: {
    [name: string]: string | number | boolean;
  }) {
    if (!!urlParams["profile"]) {
      this.profileParameter = urlParams["profile"] as string;
      this.__logicHandled = true;
    }
    if (!!urlParams["h"]) {
      this.hHash = urlParams["h"] as string;
      this.__logicHandled = true;
    }
  }
}
