import { CheckQualificationResponseType } from './enums/check-qualification-response-type.enum';
import { CheckQualificationClientResponse } from './models/survey-engine/check-qualification-client-response-dto';
import { CheckQualificationSuccessResponse } from './models/survey-engine/check-qualification-success-response-dto';
import { CheckQualificationRedirectResponse } from './models/survey-engine/check-qualification-redirect-response-dto';
import { CheckQualificationResponseRegistryCollection } from './services/check-qualification-response-factory.service';
import { CheckQualificationLinkedInResponse } from './models/survey-engine/check-qualification-linkedIn-response-dto';
import { CheckQualificationRoutedResponse } from './models/survey-engine/check-qualification-routed-response-dto';
import { CheckQualificationMBDGoogleResponse } from './models/survey-engine/check-qualification-mbd-google-response-dto';
var ɵ0 = function () {
    return new CheckQualificationResponseRegistryCollection([
        {
            key: CheckQualificationResponseType[CheckQualificationResponseType.Client],
            type: CheckQualificationClientResponse
        },
        {
            key: CheckQualificationResponseType[CheckQualificationResponseType.Redirect],
            type: CheckQualificationRedirectResponse
        },
        {
            key: CheckQualificationResponseType[CheckQualificationResponseType.Success],
            type: CheckQualificationSuccessResponse
        },
        {
            key: CheckQualificationResponseType[CheckQualificationResponseType.LinkedIn],
            type: CheckQualificationLinkedInResponse
        },
        {
            key: CheckQualificationResponseType[CheckQualificationResponseType.Routed],
            type: CheckQualificationRoutedResponse
        },
        {
            key: CheckQualificationResponseType[CheckQualificationResponseType.MBDGoogleStart],
            type: CheckQualificationMBDGoogleResponse
        }
    ]);
};
var CheckQualificationResponseRegistryModule = /** @class */ (function () {
    function CheckQualificationResponseRegistryModule() {
    }
    return CheckQualificationResponseRegistryModule;
}());
export { CheckQualificationResponseRegistryModule };
export { ɵ0 };
