import { NgModule } from '@angular/core';

import { environment } from '../environments/environment';
import { RVID_KEY, RVID_LIB, RVID_TIMEOUT, RVID_USE_CACHE } from './core/globals';

@NgModule({
  providers: [
    {
      provide: RVID_KEY,
      useValue: environment.rvid.key
    },
    {
      provide: RVID_LIB,
      useValue: environment.rvid.lib
    },
    {
      provide: RVID_TIMEOUT,
      useValue: environment.rvid.timeout
    },
    {
      provide: RVID_USE_CACHE,
      useValue: environment.rvid.cache
    }
  ]
})
export class RvidConfigModule {}
