import { IPageCollection, PageCollection } from '../survey-engine/page';
import { CheckProfileResponseBase, ICheckProfileResponse, ISurveyCheckProfileResponse, IPolfishResponse } from './check-profile-response-base';

export interface ITargetedProfileResponse extends ISurveyCheckProfileResponse, IPolfishResponse,ICheckProfileResponse {
  pages: IPageCollection;
}

export class TargetedProfileResponse extends CheckProfileResponseBase implements ITargetedProfileResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.ProfileTargetedResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  pages: IPageCollection;
  isPollfishEnabled: boolean;
  isPLRouterEnabled: boolean;
  clientInvokeUrl: string;
  isSampleChainEnabled: boolean;
  verifyRelevantId: boolean;
  sampleChainDestinationPlatformId: string;
  sampleChainDestinationSurveyNumber: string;
  verifyRFGFingerPrint: boolean;

  constructor(options?: ITargetedProfileResponse) {
    super(options as ICheckProfileResponse);
    options = options || ({} as ITargetedProfileResponse);
    this.isPLRouterEnabled = options.isPLRouterEnabled;
    this.isPollfishEnabled = options.isPollfishEnabled;
    this.clientInvokeUrl = options.clientInvokeUrl;
    this.isSampleChainEnabled = options.isSampleChainEnabled;
    this.sampleChainDestinationPlatformId = options.sampleChainDestinationPlatformId;
    this.sampleChainDestinationSurveyNumber = options.sampleChainDestinationSurveyNumber;
	  this.pages = new PageCollection(options.pages);
	  this.verifyRelevantId = options.verifyRelevantId;
    this.verifyRFGFingerPrint = options.verifyRFGFingerPrint;
  }
}
