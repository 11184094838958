var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CheckQualificationResponseBase } from './check-qualification-response-base';
var CheckQualificationLinkedInResponse = /** @class */ (function (_super) {
    __extends(CheckQualificationLinkedInResponse, _super);
    function CheckQualificationLinkedInResponse(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationLinkedInResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
        options = options || {};
        _this.redirectUrl = options.redirectUrl || "";
        _this.respondentId = options.respondentId || 0;
        _this.respondentToken = options.respondentToken || "";
        _this.surveyId = options.surveyId || 0;
        _this.scUserId = options.scUserId || 0;
        _this.linkedInTerminate = options.linkedInTerminate || false;
        _this.isRouterSession = options.isRouterSession || false;
        _this.clientSurveyLiveURL = options.clientSurveyLiveURL || '';
        return _this;
    }
    return CheckQualificationLinkedInResponse;
}(CheckQualificationResponseBase));
export { CheckQualificationLinkedInResponse };
