export class RandomStringService {
  max: number = 100;
  /**
   * Default range of characters to generate from.
   */
  get defaultRange() {
    return (this._range = this._range || new DRange(32, 126));
  }

  set defaultRange(range) {
    this._range = range;
  }
  /**
   * @constructor
   * @param {RegExp|String} regexp
   * @param {String} m
   */
  constructor(regexp: string | RegExp, m?: string) {
    this._setDefaults(regexp);
    if (regexp instanceof RegExp) {
      this.ignoreCase = regexp.ignoreCase;
      this.multiline = regexp.multiline;
      regexp = regexp.source;
    } else if (typeof regexp === "string") {
      this.ignoreCase = m && m.indexOf("i") !== -1;
      this.multiline = m && m.indexOf("m") !== -1;
    } else {
      throw new Error("Expected a regexp or string");
    }

    this.tokens = Tokenizer.tokenize(regexp);
  }

  /**
   * Generates the random string.
   *
   * @return {String}
   */
  generate() {
    return this._gen(this.tokens, []);
  }

  /**
   * Checks if some custom properties have been set for this regexp.
   *
   * @param {RandomStringService} randexp
   * @param {RegExp} regexp
   */
  private _setDefaults(regexp) {
    // When a repetitional token has its max set to Infinite,
    // randexp won't actually generate a random amount between min and Infinite
    // instead it will see Infinite as min + 100.
    this.max = regexp.max != null ? regexp.max : this.max != null ? this.max : 100;

    // This allows expanding to include additional characters
    // for instance: RandExp.defaultRange.add(0, 65535);
    this.defaultRange = regexp.defaultRange ? regexp.defaultRange : this.defaultRange.clone();

    if (regexp.randInt) {
      this.randInt = regexp.randInt;
    }
  }

  /**
   * Generate random string modeled after given tokens.
   *
   * @param {Object} token
   * @param {Array.<String>} groups
   * @return {String}
   */
  _gen(token, groups) {
    var stack, str, n, i, l;

    switch (token.type) {
      case Types.ROOT:
      case Types.GROUP:
        // Ignore lookaheads for now.
        if (token.followedBy || token.notFollowedBy) {
          return "";
        }

        // Insert placeholder until group string is generated.
        if (token.remember && token.groupNumber === undefined) {
          token.groupNumber = groups.push(null) - 1;
        }

        stack = token.options.length ? this._randSelect(token.options) : token.stack;

        str = "";
        for (i = 0, l = stack.length; i < l; i++) {
          str += this._gen(stack[i], groups);
        }

        if (token.remember) {
          groups[token.groupNumber] = str;
        }
        return str;

      case Types.POSITION:
        // Do nothing for now.
        return "";

      case Types.SET:
        var expandedSet = this._expand(token);
        if (!expandedSet.length) {
          return "";
        }
        return String.fromCharCode(this._randSelect(expandedSet));

      case Types.REPETITION:
        // Randomly generate number between min and max.
        n = this.randInt(token.min, token.max === Infinity ? token.min + this.max : token.max);

        str = "";
        for (i = 0; i < n; i++) {
          str += this._gen(token.value, groups);
        }

        return str;

      case Types.REFERENCE:
        return groups[token.value - 1] || "";

      case Types.CHAR:
        var code = this.ignoreCase && this._randBool() ? this._toOtherCase(token.value) : token.value;
        return String.fromCharCode(code);
    }
  }

  /**
   * If code is alphabetic, converts to other case.
   * If not alphabetic, returns back code.
   *
   * @param {Number} code
   * @return {Number}
   */
  _toOtherCase(code) {
    return code + (97 <= code && code <= 122 ? -32 : 65 <= code && code <= 90 ? 32 : 0);
  }

  /**
   * Randomly returns a true or false value.
   *
   * @return {Boolean}
   */
  _randBool() {
    return !this.randInt(0, 1);
  }

  /**
   * Randomly selects and returns a value from the array.
   *
   * @param {Array.<Object>} arr
   * @return {Object}
   */
  _randSelect(arr) {
    if (arr instanceof DRange) {
      return arr.index(this.randInt(0, arr.length - 1));
    }
    return arr[this.randInt(0, arr.length - 1)];
  }

  /**
   * expands a token to a DiscontinuousRange of characters which has a
   * length and an index function (for random selecting)
   *
   * @param {Object} token
   * @return {DiscontinuousRange}
   */
  _expand(token) {
    if (token.type === Types.CHAR) {
      return new DRange(token.value, null);
    } else if (token.type === Types.RANGE) {
      return new DRange(token.from, token.to);
    } else {
      let drange = new DRange(null, null);
      for (let i = 0; i < token.set.length; i++) {
        let subrange = this._expand(token.set[i]);
        drange.add(subrange, null);
        if (this.ignoreCase) {
          for (let j = 0; j < subrange.length; j++) {
            let code = subrange.index(j);
            let otherCaseCode = this._toOtherCase(code);
            if (code !== otherCaseCode) {
              drange.add(otherCaseCode, null);
            }
          }
        }
      }
      if (token.not) {
        return this.defaultRange.clone().subtract(drange, null);
      } else {
        return this.defaultRange.clone().intersect(drange, null);
      }
    }
  }

  /**
   *
   * Enables use of randexp with a shorter call.
   *
   * @param {RegExp|String| regexp}
   * @param {String} m
   * @return {String}
   */
  static randexp(regexp, m) {
    var randexp;
    if (typeof regexp === "string") {
      regexp = new RegExp(regexp, m);
    }

    if (regexp._randexp === undefined) {
      randexp = new RandomStringService(regexp, m);
      regexp._randexp = randexp;
    } else {
      randexp = regexp._randexp;
      randexp._setDefaults(regexp);
    }
    return randexp.gen();
  }

  /**
   * Randomly generates and returns a number between a and b (inclusive).
   *
   * @param {Number} a
   * @param {Number} b
   * @return {Number}
   */
  private randInt(a, b) {
    return a + Math.floor(Math.random() * (1 + b - a));
  }

  private tokens = { type: Types.ROOT, stack: [], options: [] };
  private ignoreCase;
  private multiline;
  private _range: DRange;
}

class Tokenizer {
  static tokenize = regexpStr => {
    var i = 0,
      l,
      c,
      start = { type: Types.ROOT, stack: [], options: [] },
      // Keep track of last clause/group and stack.
      lastGroup = start,
      last = start.stack,
      groupStack = [];

    var repeatErr = i => {
      Utils.error(regexpStr, `Nothing to repeat at column ${i - 1}`);
    };

    // Decode a few escaped characters.
    var str = Utils.strToChars(regexpStr);
    l = str.length;

    // Iterate through each character in string.
    while (i < l) {
      c = str[i++];

      switch (c) {
        // Handle escaped characters, inclues a few sets.
        case "\\":
          c = str[i++];

          switch (c) {
            case "b":
              last.push(Positions.wordBoundary());
              break;

            case "B":
              last.push(Positions.nonWordBoundary());
              break;

            case "w":
              last.push(Sets.words());
              break;

            case "W":
              last.push(Sets.notWords());
              break;

            case "d":
              last.push(Sets.ints());
              break;

            case "D":
              last.push(Sets.notInts());
              break;

            case "s":
              last.push(Sets.whitespace());
              break;

            case "S":
              last.push(Sets.notWhitespace());
              break;

            default:
              // Check if c is integer.
              // In which case it's a reference.
              if (/\d/.test(c)) {
                last.push({ type: Types.REFERENCE, value: parseInt(c, 10) });

                // Escaped character.
              } else {
                last.push({ type: Types.CHAR, value: c.charCodeAt(0) });
              }
          }

          break;

        // Positionals.
        case "^":
          last.push(Positions.begin());
          break;

        case "$":
          last.push(Positions.end());
          break;

        // Handle custom sets.
        case "[":
          // Check if this class is 'anti' i.e. [^abc].
          var not;
          if (str[i] === "^") {
            not = true;
            i++;
          } else {
            not = false;
          }

          // Get all the characters in class.
          var classTokens = Utils.tokenizeClass(str.slice(i), regexpStr);

          // Increase index by length of class.
          i += classTokens[1];
          last.push({
            type: Types.SET,
            set: classTokens[0],
            not
          });

          break;

        // Class of any character except \n.
        case ".":
          last.push(Sets.anyChar());
          break;

        // Push group onto stack.
        case "(":
          // Create group.
          var group = {
            type: Types.GROUP,
            stack: [],
            remember: true,
            followedBy: false,
            notFollowedBy: false,
            options: []
          };

          c = str[i];

          // If if this is a special kind of group.
          if (c === "?") {
            c = str[i + 1];
            i += 2;

            // Match if followed by.
            if (c === "=") {
              group.followedBy = true;

              // Match if not followed by.
            } else if (c === "!") {
              group.notFollowedBy = true;
            } else if (c !== ":") {
              Utils.error(regexpStr, `Invalid group, character '${c}'` + ` after '?' at column ${i - 1}`);
            }

            group.remember = false;
          }

          // Insert subgroup into current group stack.
          last.push(group);

          // Remember the current group for when the group closes.
          groupStack.push(lastGroup);

          // Make this new group the current group.
          lastGroup = group;
          last = group.stack;
          break;

        // Pop group out of stack.
        case ")":
          if (groupStack.length === 0) {
            Utils.error(regexpStr, `Unmatched ) at column ${i - 1}`);
          }
          lastGroup = groupStack.pop();

          // Check if this group has a PIPE.
          // To get back the correct last stack.
          last = lastGroup.options ? lastGroup.options[lastGroup.options.length - 1] : lastGroup.stack;
          break;

        // Use pipe character to give more choices.
        case "|":
          // Create array where options are if this is the first PIPE
          // in this clause.
          if (!lastGroup.options) {
            lastGroup.options = [lastGroup.stack];
            delete lastGroup.stack;
          }

          // Create a new stack and add to options for rest of clause.
          var stack = [];
          lastGroup.options.push(stack);
          last = stack;
          break;

        // Repetition.
        // For every repetition, remove last element from last stack
        // then insert back a RANGE object.
        // This design is chosen because there could be more than
        // one repetition symbols in a regex i.e. `a?+{2,3}`.
        case "{":
          var rs = /^(\d+)(,(\d+)?)?\}/.exec(str.slice(i)),
            min,
            max;
          if (rs !== null) {
            if (last.length === 0) {
              repeatErr(i);
            }
            min = parseInt(rs[1], 10);
            max = rs[2] ? (rs[3] ? parseInt(rs[3], 10) : Infinity) : min;
            i += rs[0].length;

            last.push({
              type: Types.REPETITION,
              min,
              max,
              value: last.pop()
            });
          } else {
            last.push({
              type: Types.CHAR,
              value: 123
            });
          }
          break;

        case "?":
          if (last.length === 0) {
            repeatErr(i);
          }
          last.push({
            type: Types.REPETITION,
            min: 0,
            max: 1,
            value: last.pop()
          });
          break;

        case "+":
          if (last.length === 0) {
            repeatErr(i);
          }
          last.push({
            type: Types.REPETITION,
            min: 1,
            max: Infinity,
            value: last.pop()
          });
          break;

        case "*":
          if (last.length === 0) {
            repeatErr(i);
          }
          last.push({
            type: Types.REPETITION,
            min: 0,
            max: Infinity,
            value: last.pop()
          });
          break;

        // Default is a character that is not `\[](){}?+*^$`.
        default:
          last.push({
            type: Types.CHAR,
            value: c.charCodeAt(0)
          });
      }
    }

    // Check if any groups have not been closed.
    if (groupStack.length !== 0) {
      Utils.error(regexpStr, "Unterminated group");
    }

    return start;
  };
}

class SubRange {
  constructor(low, high) {
    this.low = low;
    this.high = high;
    this.length = 1 + high - low;
  }

  overlaps(range) {
    return !(this.high < range.low || this.low > range.high);
  }

  touches(range) {
    return !(this.high + 1 < range.low || this.low - 1 > range.high);
  }

  // Returns inclusive combination of SubRanges as a SubRange.
  add(range) {
    return new SubRange(Math.min(this.low, range.low), Math.max(this.high, range.high));
  }

  // Returns subtraction of SubRanges as an array of SubRanges.
  // (There's a case where subtraction divides it in 2)
  subtract(range) {
    if (range.low <= this.low && range.high >= this.high) {
      return [];
    } else if (range.low > this.low && range.high < this.high) {
      return [new SubRange(this.low, range.low - 1), new SubRange(range.high + 1, this.high)];
    } else if (range.low <= this.low) {
      return [new SubRange(range.high + 1, this.high)];
    } else {
      return [new SubRange(this.low, range.low - 1)];
    }
  }

  toString() {
    return this.low == this.high ? this.low.toString() : this.low + "-" + this.high;
  }
  private low;
  private high;
  private length;
}

class DRange {
  ranges = [];
  length = 0;

  constructor(a, b) {
    this._a = a;
    this._b = b;
    if (a != null) this.add(a, b);
  }

  _update_length() {
    this.length = this.ranges.reduce((previous, range) => {
      return previous + range.length;
    }, 0);
  }

  add(a, b) {
    const _add = subrange => {
      let i = 0;
      while (i < this.ranges.length && !subrange.touches(this.ranges[i])) {
        i++;
      }
      const newRanges = this.ranges.slice(0, i);
      while (i < this.ranges.length && subrange.touches(this.ranges[i])) {
        subrange = subrange.add(this.ranges[i]);
        i++;
      }
      newRanges.push(subrange);
      this.ranges = newRanges.concat(this.ranges.slice(i));
      this._update_length();
    };

    if (a instanceof DRange) {
      a.ranges.forEach(_add);
    } else {
      if (b == null) b = a;
      _add(new SubRange(a, b));
    }
    return this;
  }

  subtract(a, b) {
    const _subtract = subrange => {
      let i = 0;
      while (i < this.ranges.length && !subrange.overlaps(this.ranges[i])) {
        i++;
      }
      let newRanges = this.ranges.slice(0, i);
      while (i < this.ranges.length && subrange.overlaps(this.ranges[i])) {
        newRanges = newRanges.concat(this.ranges[i].subtract(subrange));
        i++;
      }
      this.ranges = newRanges.concat(this.ranges.slice(i));
      this._update_length();
    };

    if (a instanceof DRange) {
      a.ranges.forEach(_subtract);
    } else {
      if (b == null) b = a;
      _subtract(new SubRange(a, b));
    }
    return this;
  }

  intersect(a, b) {
    const newRanges = [];
    const _intersect = subrange => {
      let i = 0;
      while (i < this.ranges.length && !subrange.overlaps(this.ranges[i])) {
        i++;
      }
      while (i < this.ranges.length && subrange.overlaps(this.ranges[i])) {
        let low = Math.max(this.ranges[i].low, subrange.low);
        let high = Math.min(this.ranges[i].high, subrange.high);
        newRanges.push(new SubRange(low, high));
        i++;
      }
    };

    if (a instanceof DRange) {
      a.ranges.forEach(_intersect);
    } else {
      if (b == null) b = a;
      _intersect(new SubRange(a, b));
    }
    this.ranges = newRanges;
    this._update_length();
    return this;
  }

  index(index) {
    let i = 0;
    while (i < this.ranges.length && this.ranges[i].length <= index) {
      index -= this.ranges[i].length;
      i++;
    }
    return this.ranges[i].low + index;
  }

  toString() {
    return "[ " + this.ranges.join(", ") + " ]";
  }

  clone() {
    return new DRange(this._a, this._b);
  }

  numbers() {
    return this.ranges.reduce((result, subrange) => {
      let i = subrange.low;
      while (i <= subrange.high) {
        result.push(i);
        i++;
      }
      return result;
    }, []);
  }

  subranges() {
    return this.ranges.map(subrange => ({
      low: subrange.low,
      high: subrange.high,
      length: 1 + subrange.high - subrange.low
    }));
  }

  private _a;
  private _b;
}

class Sets {
  private static INTS = () => [{ type: Types.RANGE, from: 48, to: 57 }];

  private static WORDS = () => {
    return [{ type: Types.CHAR, value: 95 }, { type: Types.RANGE, from: 97, to: 122 }, { type: Types.RANGE, from: 65, to: 90 }].concat(Sets.INTS());
  };

  private static WHITESPACE = () => {
    return [
      { type: Types.CHAR, value: 9 },
      { type: Types.CHAR, value: 10 },
      { type: Types.CHAR, value: 11 },
      { type: Types.CHAR, value: 12 },
      { type: Types.CHAR, value: 13 },
      { type: Types.CHAR, value: 32 },
      { type: Types.CHAR, value: 160 },
      { type: Types.CHAR, value: 5760 },
      { type: Types.RANGE, from: 8192, to: 8202 },
      { type: Types.CHAR, value: 8232 },
      { type: Types.CHAR, value: 8233 },
      { type: Types.CHAR, value: 8239 },
      { type: Types.CHAR, value: 8287 },
      { type: Types.CHAR, value: 12288 },
      { type: Types.CHAR, value: 65279 }
    ];
  };

  private static NOTANYCHAR = () => {
    return [{ type: Types.CHAR, value: 10 }, { type: Types.CHAR, value: 13 }, { type: Types.CHAR, value: 8232 }, { type: Types.CHAR, value: 8233 }];
  };

  // Predefined class objects.
  static words = () => ({ type: Types.SET, set: Sets.WORDS(), not: false });
  static notWords = () => ({ type: Types.SET, set: Sets.WORDS(), not: true });
  static ints = () => ({ type: Types.SET, set: Sets.INTS(), not: false });
  static notInts = () => ({ type: Types.SET, set: Sets.INTS(), not: true });
  static whitespace = () => ({ type: Types.SET, set: Sets.WHITESPACE(), not: false });
  static notWhitespace = () => ({ type: Types.SET, set: Sets.WHITESPACE(), not: true });
  static anyChar = () => ({ type: Types.SET, set: Sets.NOTANYCHAR(), not: true });
}

enum Types {
  ROOT = 0,
  GROUP = 1,
  POSITION = 2,
  SET = 3,
  RANGE = 4,
  REPETITION = 5,
  REFERENCE = 6,
  CHAR = 7
}

class Utils {
  private static CTRL = "@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^ ?";
  private static SLSH = { "0": 0, t: 9, n: 10, v: 11, f: 12, r: 13 };

  /**
   * Finds character representations in str and convert all to
   * their respective characters
   *
   * @param {String} str
   * @return {String}
   */
  static strToChars = str => {
    /* jshint maxlen: false */
    var chars_regex = /(\[\\b\])|(\\)?\\(?:u([A-F0-9]{4})|x([A-F0-9]{2})|(0?[0-7]{2})|c([@A-Z[\\\]^?])|([0tnvfr]))/g;
    str = str.replace(chars_regex, function(s, b, lbs, a16, b16, c8, dctrl, eslsh) {
      if (lbs) {
        return s;
      }

      var code = b ? 8 : a16 ? parseInt(a16, 16) : b16 ? parseInt(b16, 16) : c8 ? parseInt(c8, 8) : dctrl ? Utils.CTRL.indexOf(dctrl) : Utils.SLSH[eslsh];

      var c = String.fromCharCode(code);

      // Escape special regex characters.
      if (/[[\]{}^$.|?*+()]/.test(c)) {
        c = "\\" + c;
      }

      return c;
    });

    return str;
  };

  /**
   * turns class into tokens
   * reads str until it encounters a ] not preceeded by a \
   *
   * @param {String} str
   * @param {String} regexpStr
   * @return {Array.<Array.<Object>, Number>}
   */
  static tokenizeClass = (str, regexpStr): [any, number] => {
    /* jshint maxlen: false */
    var tokens = [];
    var regexp = /\\(?:(w)|(d)|(s)|(W)|(D)|(S))|((?:(?:\\)(.)|([^\]\\]))-(?:\\)?([^\]]))|(\])|(?:\\)?([^])/g;
    var rs, c;

    while ((rs = regexp.exec(str)) != null) {
      if (rs[1]) {
        tokens.push(Sets.words());
      } else if (rs[2]) {
        tokens.push(Sets.ints());
      } else if (rs[3]) {
        tokens.push(Sets.whitespace());
      } else if (rs[4]) {
        tokens.push(Sets.notWords());
      } else if (rs[5]) {
        tokens.push(Sets.notInts());
      } else if (rs[6]) {
        tokens.push(Sets.notWhitespace());
      } else if (rs[7]) {
        tokens.push({
          type: Types.RANGE,
          from: (rs[8] || rs[9]).charCodeAt(0),
          to: rs[10].charCodeAt(0)
        });
      } else if ((c = rs[12])) {
        tokens.push({
          type: Types.CHAR,
          value: c.charCodeAt(0)
        });
      } else {
        return [tokens, regexp.lastIndex];
      }
    }
    Utils.error(regexpStr, "Unterminated character class");
  };

  /**
   * Shortcut to throw errors.
   *
   * @param {String} regexp
   * @param {String} msg
   */
  static error = (regexp, msg) => {
    throw new SyntaxError("Invalid regular expression: /" + regexp + "/: " + msg);
  };
}

class Positions {
  static wordBoundary = () => ({ type: Types.POSITION, value: "b" });
  static nonWordBoundary = () => ({ type: Types.POSITION, value: "B" });
  static begin = () => ({ type: Types.POSITION, value: "^" });
  static end = () => ({ type: Types.POSITION, value: "$" });
}
