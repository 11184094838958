import { CategoricalQuestionBase, ICategoricalQuestion } from './core/categorical-question-base';
import { QuestionType } from './core/question-type.enum';

export interface IMultiQuestionValidation extends ICategoricalQuestion {
  validators: {
    required: boolean;
    minAnswers?: number;
    maxAnswers?: number;
  };
}

export interface IMultiQuestion extends IMultiQuestionValidation {}

export class MultiQuestion extends CategoricalQuestionBase implements IMultiQuestionValidation {
  validators: {
    required: boolean;
    minAnswers?: number;
    maxAnswers?: number;
  } = {
    required: true
  };
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.MultiQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  constructor(options?: IMultiQuestion) {
    super(options as ICategoricalQuestion);
    this.questionType = QuestionType.Multi;
    options = options || ({} as IMultiQuestionValidation);
    options.validators = options.validators || { required: true };
    this.validators.required = options.validators.required;
    this.validators.minAnswers = options.validators.minAnswers;
    this.validators.maxAnswers = options.validators.maxAnswers;
  }
}
