import { IOpenQuestion, IOpenValidation, OpenQuestionBase } from './core/open-question-base';
import { QuestionType } from './core/question-type.enum';

export interface IOpenTextValidation extends IOpenValidation {
  validators: {
    required: boolean;
    pattern: string;
    minLength?: number;
    maxLength?: number;
  };
}

export interface IOpenTextQuestion extends IOpenTextValidation {
  validators: {
    required: boolean;
    pattern: string;
    minLength?: number;
    maxLength?: number;
  };
}

export class OpenTextQuestion extends OpenQuestionBase implements IOpenTextQuestion {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.OpenTextQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  validators: {
    required: boolean;
    pattern: string;
    minLength?: number;
    maxLength?: number;
  } = {
    required: true,
    pattern: ".*"
  };
  constructor(options?: IOpenTextQuestion) {
    super(options as IOpenQuestion);

    options = options || ({} as IOpenTextQuestion);
    this.questionType = QuestionType.Open;
    options.validators = options.validators || { required: true, pattern: ".*" };
    this.validators.required = options.validators.required;
    this.validators.pattern = options.validators.pattern;
    this.validators.minLength = options.validators.minLength;
    this.validators.maxLength = options.validators.maxLength;
  }
}
