export enum LanguageCountry {
  GB = 1,
  FR = 2,
  US = 3,
  AU = 4,
  CA = 5,
  IN = 6,
  DE = 7,
  BR = 8,
  PL = 10,
  IE = 11,
  MX = 12,
  AR = 14,
  SG = 15,
  NL = 16,
  IT = 17,
  BE = 18,
  ZA = 19,
  HK = 20,
  DK = 21,
  AT = 23,
  PT = 24,
  RU = 25,
  CN = 26,
  CH = 28,
  JP = 30,
  GR = 31,
  NO = 32,
  CL = 33,
  PE = 34,
  ES = 35,
  SE = 36,
  TH = 37,
  TR = 38,
  CO = 39,
  SA = 40,
  IL = 41,
  MY = 42,
  KR = 44,
  PH = 45,
  AE = 47,
  TW = 50,
  ID = 51,
  VN = 53,
  EG = 54,
  NZ = 55,
  SCT = 58,
  FI = 65,
  CZ = 67,
  PR = 69,
  HU = 71,
  RO = 73,
  NG = 76,
  KE = 79,
  PK = 81,
  KZ = 82,
  UA = 83
}
