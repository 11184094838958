var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OpenAnswer } from "../../../../models/survey-engine/open-answer";
import { QuestionBaseComponent } from "./question-base.component";
import { Message } from "../../../../localization/message";
import "../../../../extensions/string-extensions";
import { RandomStringService } from "../../../../services/random-string.service";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";
var OpenQuestionBaseComponent = /** @class */ (function (_super) {
    __extends(OpenQuestionBaseComponent, _super);
    function OpenQuestionBaseComponent() {
        var _this = _super.call(this) || this;
        _this._example = "";
        _this.__patternErrorMessage = "";
        return _this;
    }
    OpenQuestionBaseComponent.prototype.setQuestionAnswer = function (value) {
        var self = this;
        self.__questionAnswer = new OpenAnswer({
            value: value
        });
        self.__questionAnswer.answerType = AnswerType.Open;
    };
    Object.defineProperty(OpenQuestionBaseComponent.prototype, "example", {
        get: function () {
            var self = this;
            return self._example;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenQuestionBaseComponent.prototype, "patternErrorMessage", {
        get: function () {
            return this.__patternErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    OpenQuestionBaseComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        var self = this;
        var question_validators = self.question.validators;
        if (self.question.response && self.question.response.value) {
            self.ctrl.setValue(self.question.response.value);
        }
        if (question_validators.pattern) {
            var stringGenerator = new RandomStringService(question_validators.pattern);
            self._example = stringGenerator.generate();
        }
        self.__patternErrorMessage = String.format(Message.InvalidFormatMessage, this.example);
    };
    OpenQuestionBaseComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return OpenQuestionBaseComponent;
}(QuestionBaseComponent));
export { OpenQuestionBaseComponent };
