import { NgModule } from '@angular/core';

import { ENDING_DATA } from '../../core/globals';
import { EndingInputData } from '../../models/ending';
import { FedData } from '../../models/fed-data';
import { PureSpectrumData } from '../../models/pure-spectrum-data';
import { SSIData } from '../../models/ssi-data';
import { EndingDataTypeRegistryCollection } from '../../services/ending-data-factory.service';

@NgModule({
  providers: [
    {
      provide: ENDING_DATA,
      useFactory: function() {
        return new EndingDataTypeRegistryCollection([
          {
            key: "FedData",
            type: FedData
          },
          {
            key: "PureSpectrumData",
            type: PureSpectrumData
          },
          {
            key: "SSIData",
            type: SSIData
          },
          {
            key: "EndingInputData",
            type: EndingInputData
          }
        ]);
      }
    }
  ]
})
export class EndingInputDataModule {}
