var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SampleCubeUserBase } from "./samplecube-user";
var FedSampleCubeUser = /** @class */ (function (_super) {
    __extends(FedSampleCubeUser, _super);
    function FedSampleCubeUser() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.FedSampleCubeUserApiModel, SampleCube.UserFlow.API.Models";
        _this.fulcrumSupplierCode = '';
        return _this;
    }
    FedSampleCubeUser.prototype.handleLogic = function (options) {
        var self = this;
        var _loop_1 = function (p) {
            if (options.hasOwnProperty(p)) {
                var keys = Object.keys(self);
                keys.forEach(function (key) {
                    if (key.toLowerCase() === p) {
                        self[key] = options[p];
                        self.logicHandled = true;
                        return;
                    }
                });
            }
        };
        for (var p in options) {
            _loop_1(p);
        }
    };
    return FedSampleCubeUser;
}(SampleCubeUserBase));
export { FedSampleCubeUser };
