import { IPageCollection, PageCollection } from '../survey-engine/page';
import { CheckProfileResponseBase, ICheckProfileResponse, IRoutedResponse } from './check-profile-response-base';

export interface IRoutedProfileResponse extends IRoutedResponse, ICheckProfileResponse {

}

export class RoutedProfileResponse extends CheckProfileResponseBase implements IRoutedProfileResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.ProfileRoutedResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  pages: IPageCollection;
  respondentId: number;
  respondentToken: string;
  surveyId: number;
  surveyToken: string;
  isPollfishEnabled: boolean;
  isPLRouterEnabled: boolean;
  clientInvokeUrl: string;
  isSampleChainEnabled: boolean;
  verifyRelevantId: boolean;
  isPIDRouting: boolean = false;
  isRecontact: boolean = false;
  originalUserId?:number=0;
  sampleChainDestinationPlatformId: string = "";
  sampleChainDestinationSurveyNumber: string = "";
  verifyRFGFingerPrint: boolean = false;

  constructor(options?: IRoutedProfileResponse) {
    super(options as ICheckProfileResponse);
    options = options || ({} as IRoutedProfileResponse);
    this.pages = new PageCollection(options.pages);
    this.respondentId = options.respondentId;
    this.respondentToken = options.respondentToken;
    this.surveyId = options.surveyId;
    this.surveyToken = options.surveyToken;
    this.isPLRouterEnabled = options.isPLRouterEnabled;
    this.isPollfishEnabled = options.isPollfishEnabled;
    this.clientInvokeUrl = options.clientInvokeUrl;
    this.isSampleChainEnabled = options.isSampleChainEnabled;
    this.verifyRelevantId = options.verifyRelevantId;
    this.isPIDRouting = options.isPIDRouting;
    this.isRecontact = options.isRecontact;
    this.originalUserId=options.originalUserId || 0;
    this.sampleChainDestinationPlatformId=options.sampleChainDestinationPlatformId || "";
    this.sampleChainDestinationSurveyNumber=options.sampleChainDestinationSurveyNumber || "";
    this.verifyRFGFingerPrint = options.verifyRFGFingerPrint;
  }
}
