
import { EndingInputDataBase } from "./ending";
import { IPageCollection, PageCollection } from "./survey-engine/page";
import { ISurveyCheckProfileResponse } from "./profile/check-profile-response-base";
import { URLType } from "../enums/url-type";
import { IEndingOutputBase, EndingOutputBase } from "./ending-output-base";

export interface ISetStatusSuccess extends IEndingOutputBase {
}

export class SetStatusSuccessOutputData extends EndingOutputBase implements ISetStatusSuccess {
  $type: string =
    "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusSuccessResponseApiDTO, SampleCube.UserFlow.API.Models.EndingPage";

  constructor(options?: ISetStatusSuccess) {
    super(options as IEndingOutputBase);
    options = options || ({} as ISetStatusSuccess);
  }
}
