import { Injector } from '@angular/core';
var InjectorInstance = /** @class */ (function () {
    function InjectorInstance() {
    }
    Object.defineProperty(InjectorInstance, "hasInstance", {
        get: function () {
            return !!this.instance;
        },
        enumerable: true,
        configurable: true
    });
    InjectorInstance.get = function (token) {
        return this.instance.get(token);
    };
    InjectorInstance.create = function (options) {
        Injector.create(options);
    };
    InjectorInstance.setInstance = function (injector) {
        if (!this.hasInstance) {
            this.instance = injector;
        }
    };
    return InjectorInstance;
}());
export { InjectorInstance };
