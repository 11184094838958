import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var PageLoadService = /** @class */ (function () {
    function PageLoadService() {
        this.loaded = new Subject();
        this.loaded$ = this.loaded.asObservable();
        this.__questionLoadSubscriptions = [];
        this.__registered = {};
        this.__loadedQuestions = {};
        var self = this;
        self.loaded.next(false);
    }
    PageLoadService.prototype.registerQuestion = function (name) {
        var self = this;
        self.__registered[name] = new QuestionLoadRegistry();
        self.__loadedQuestions[name] = false;
        self.__questionLoadSubscriptions.push(self.__registered[name].loaded$.subscribe(function (loaded) {
            self.__loadedQuestions[name] = loaded;
            var pageLoaded = !(Object.keys(self.__loadedQuestions)
                .some(function (qName) { return !self.__loadedQuestions[qName]; }));
            self.loaded.next(pageLoaded);
        }));
        return self.__registered[name];
    };
    PageLoadService.prototype.getQuestionLoadRegistry = function (name) {
        return this.__registered[name];
    };
    PageLoadService.prototype.unsubscribe = function () {
        var self = this;
        self.__questionLoadSubscriptions.forEach(function (subscription) {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        });
    };
    PageLoadService.ngInjectableDef = i0.defineInjectable({ factory: function PageLoadService_Factory() { return new PageLoadService(); }, token: PageLoadService, providedIn: "root" });
    return PageLoadService;
}());
export { PageLoadService };
var QuestionLoadRegistry = /** @class */ (function () {
    function QuestionLoadRegistry() {
        this.loaded = new Subject();
        this.loaded$ = this.loaded.asObservable();
        this.loaded.next(false);
    }
    QuestionLoadRegistry.prototype.triggerQuestionLoaded = function () {
        this.loaded.next(true);
    };
    return QuestionLoadRegistry;
}());
export { QuestionLoadRegistry };
