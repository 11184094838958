var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { SingleAnswer } from "../../../../models/survey-engine/single-answer";
import { CategoricalQuestionBaseComponent } from "../core/categorical-question-base.component";
import "../../../../extensions/string-extensions";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";
var SingleQuestionComponent = /** @class */ (function (_super) {
    __extends(SingleQuestionComponent, _super);
    function SingleQuestionComponent() {
        return _super.call(this) || this;
    }
    SingleQuestionComponent.prototype.setQuestionAnswer = function (value) {
        var self = this;
        self.__questionAnswer = new SingleAnswer({
            value: self.getCategory(value).id
        });
        self.__questionAnswer.answerType = AnswerType.Single;
    };
    Object.defineProperty(SingleQuestionComponent.prototype, "categories", {
        get: function () {
            var categories = [];
            this.question.categories.$values.forEach(function (c) {
                categories.push(c);
            });
            return categories;
        },
        enumerable: true,
        configurable: true
    });
    SingleQuestionComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        var self = this;
        var validators = [];
        if (self.question.validators.required) {
            validators.push(Validators.required);
        }
        Object.keys(self.question.validators).forEach(function (validator) {
            if (validator !== "required" && self.question.validators[validator]) {
                validators.push(Validators[validator].apply(undefined, [self.question.validators[validator]]));
            }
        });
        self.ctrl.setValidators(validators);
        if (self.question.response && self.question.response.value) {
            var category = self.getCategoryById(self.question.response.value);
            if (category && category.code) {
                self.ctrl.setValue(category.code);
            }
        }
        self.triggerQuestionLoaded();
    };
    SingleQuestionComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    SingleQuestionComponent.prototype.categoryChanged = function (option, i) {
        // const self = this;
        // super.categoryChanged(option, i);
        // const code: number = option.value;
        // const category: Category = self.getCategory(code);
        // const isExclusive: boolean = category.isExclusive;
        // if (!isExclusive) {
        //   self.categories.forEach((item: Category) => {
        //     if (item.isOther && item.code !== code) {
        //       self.getAnswer(item.code).checked = false;
        //       self.getAnswer(item.code).value = "";
        //     }
        //   });
        // }
    };
    return SingleQuestionComponent;
}(CategoricalQuestionBaseComponent));
export { SingleQuestionComponent };
