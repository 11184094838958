import { MatSnackBar } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-store';
import { Subject } from 'rxjs';
import { AlertType } from '../enums/alert-type';
import { Parameter, ParameterCollection } from '../models/parameter';
import { Respondent } from '../models/respondent';
import { LoggerService } from '../services/logger.service';
import { SnackBarAlertComponent } from '../snack-bar-alert/snack-bar-alert.component';
import { Utilities } from '../utils/utilities';
import { ENV_NAME, IS_PROD_ENVIRONMENT } from './globals';
import { InjectorInstance } from './injector-instance';
var ComponentBaseComponent = /** @class */ (function () {
    function ComponentBaseComponent(title) {
        this._envIsProd = false;
        this.message = "";
        this.loaderSubject = new Subject();
        this.params = {};
        this.loaderState = this.loaderSubject.asObservable();
        this.__urlParams = {};
        // Manually retrieve the monitoring service from the injector
        // so that constructor has no dependencies that must be passed in from child
        this.titleService = InjectorInstance.get(Title);
        this.logger = InjectorInstance.get(LoggerService);
        this.snackBar = InjectorInstance.get(MatSnackBar);
        this.routing = InjectorInstance.get(Router);
        this.sessionStorageService = InjectorInstance.get(SessionStorageService);
        this.localStorageService = InjectorInstance.get(LocalStorageService);
        this._envName = InjectorInstance.get(ENV_NAME);
        this._envIsProd = InjectorInstance.get(IS_PROD_ENVIRONMENT);
        this.disableBackButton();
        this._name = this.constructor.name;
        this._title = title || "";
    }
    Object.defineProperty(ComponentBaseComponent.prototype, "isTestUrl", {
        get: function () {
            return (this.sessionParams["chktestlink"] || "false") === "true";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComponentBaseComponent.prototype, "isTestCache", {
        get: function () {
            return this.cachedRespondent.isTest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComponentBaseComponent.prototype, "cachedRespondent", {
        get: function () {
            var respondent = this.sessionStorageService.get("sc.respondent");
            var respondentTest = this.sessionStorageService.get("sc.respondent.test");
            if (!!!respondent && !!!respondentTest) {
                return null;
            }
            // if(!!!respondent?.respondentToken && !!!respondentTest?.respondentToken){
            //   return null;
            // }
            var isTest = !!!respondent && !!respondentTest;
            var result = new Respondent(isTest ? respondentTest : respondent);
            if (!!!result.respondentToken) {
                return null;
            }
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComponentBaseComponent.prototype, "path", {
        get: function () {
            return Utilities.getCurrentPath();
        },
        enumerable: true,
        configurable: true
    });
    ComponentBaseComponent.prototype.alert = function (data, duration) {
        var _this = this;
        setTimeout(function () {
            duration = duration || 0;
            if (duration <= 0) {
                _this.snackBar.openFromComponent(SnackBarAlertComponent, { data: data });
            }
            else {
                _this.snackBar.openFromComponent(SnackBarAlertComponent, {
                    data: data,
                    duration: duration
                });
            }
        });
    };
    ComponentBaseComponent.prototype.navigateTo = function (path, changeLocation) {
        changeLocation = changeLocation || false;
        this.routing.navigateByUrl(path, { skipLocationChange: !changeLocation });
    };
    ComponentBaseComponent.prototype.ngOnInit = function () {
        this.titleService.setTitle(this._title);
        this.logNavigation();
    };
    ComponentBaseComponent.prototype.ngOnDestroy = function () { };
    ComponentBaseComponent.prototype.logNavigation = function () {
        this.logger.trackPageView(this._name);
    };
    ComponentBaseComponent.prototype.getParameters = function () {
        var sessionParams = this.sessionStorageService.get("sc.params");
        for (var p in sessionParams) {
            if (sessionParams.hasOwnProperty(p)) {
                this.params[p] = sessionParams[p];
            }
        }
    };
    Object.defineProperty(ComponentBaseComponent.prototype, "storage", {
        get: function () {
            var _this = this;
            var obj = {};
            var prefix = this.sessionStorageService.config.prefix;
            var keys = this.sessionStorageService.keys;
            keys.forEach(function (k) {
                k = k.replace(prefix, "");
                obj[k] = _this.sessionStorageService.get(k);
            });
            return obj;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComponentBaseComponent.prototype, "sessionParams", {
        get: function () {
            var sessionParams = this.sessionStorageService.get("sc.params");
            return sessionParams || {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ComponentBaseComponent.prototype, "urlParams", {
        get: function () {
            return this.__urlParams;
        },
        set: function (value) {
            this.__urlParams = value;
        },
        enumerable: true,
        configurable: true
    });
    ComponentBaseComponent.prototype.disableBackButton = function () {
        history.pushState(null, null, location.href);
        window.onpopstate = function () {
            history.go(1);
        };
    };
    ComponentBaseComponent.prototype.displayAlert = function (message, type) {
        var self = this;
        message = message || this.message;
        if (!!message) {
            self.alert({
                alertType: type || AlertType.Info,
                text: message,
                title: "Message"
            });
        }
    };
    ComponentBaseComponent.prototype.getUrlData = function () {
        var urlParams = this.sessionStorageService.get("sc.params");
        var urlData = new ParameterCollection();
        for (var key in urlParams) {
            if (urlParams.hasOwnProperty(key)) {
                var valueData = urlParams[key].toString();
                if (key.toLowerCase() === "gender" && (valueData == 111 || valueData == 112)) {
                    valueData = valueData === 111 ? 1 : 2;
                }
                urlData.$values.push(new Parameter({
                    key: key.toLowerCase(),
                    value: valueData
                }));
            }
        }
        return urlData;
    };
    ComponentBaseComponent.prototype.getGenderFromUrl = function () {
        var urlGender = 0;
        if (this.params["gender"] === 'm' || this.params["gender"] === 'M') {
            urlGender = 1;
        }
        else if (this.params["gender"] === 'f' || this.params["gender"] === 'F') {
            urlGender = 2;
        }
        else {
            urlGender = Number(this.params["gender"] || 0);
        }
        if (urlGender === 111 || urlGender === 112) {
            urlGender = urlGender === 111 ? 1 : 2;
        }
        return urlGender;
    };
    ComponentBaseComponent.prototype.showLoader = function () {
        this.loaderSubject.next({ show: true });
    };
    ComponentBaseComponent.prototype.hideLoader = function () {
        this.loaderSubject.next({ show: false });
    };
    return ComponentBaseComponent;
}());
export { ComponentBaseComponent };
