import { EndingInputDataBase } from "./ending";

export class PureSpectrumData extends EndingInputDataBase {
  $type: string =
    "SampleCube.UserFlow.API.Models.EndingPage.DTOs.PureSpectrumSetStatusRequestApiDTO, SampleCube.UserFlow.API.Models.EndingPage";

  returnStatus: string = "";
  sHash: string = "";
  userId: string = "";
  public parseUrl(): string {
    return "";
  }
  protected handleLogic(urlParams: {
    [name: string]: string | number | boolean;
  }) {
    if (!!urlParams["ps_rstatus"]) {
      this.returnStatus = urlParams["ps_rstatus"] as string;
      this.__logicHandled = true;
    }
    if (!!urlParams["ps_s_hash"]) {
      this.sHash = urlParams["ps_s_hash"] as string;
      this.__logicHandled = true;
    }
    if (!!urlParams["ps_psid"]) {
      this.userId = urlParams["ps_psid"] as string;
      this.__logicHandled = true;
    }
  }
}
