import { IRedirectCheckQualificationResponse, CheckQualificationResponseBase, ICheckQualificationResponse } from './check-qualification-response-base';

export interface ICheckQualificationSuccessResponse extends ICheckQualificationResponse {
	skipLogicEnabled: boolean;
	nextQualificationId: number;
	isSkipLogicJumpToClient: boolean;
}

export class CheckQualificationSuccessResponse extends CheckQualificationResponseBase implements ICheckQualificationSuccessResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationSuccessResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  skipLogicEnabled: boolean;
  nextQualificationId: number;
  isSkipLogicJumpToClient: boolean;
  constructor(options?: ICheckQualificationSuccessResponse) {
    super(options as ICheckQualificationResponse);
	options = options || ({} as ICheckQualificationSuccessResponse);
	this.skipLogicEnabled = options.skipLogicEnabled;
	this.nextQualificationId = options.nextQualificationId;
	this.isSkipLogicJumpToClient = options.isSkipLogicJumpToClient;
  }
}
