import { ErrorHandler, Injectable, Injector, Inject, forwardRef, NgZone } from '@angular/core';

import { LoggerService } from '../services/logger.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material';
import { SnackBarAlertComponent } from '../snack-bar-alert/snack-bar-alert.component';
import { AlertData } from '../interfaces/alert-data';
import { AlertType } from '../enums/alert-type';
import { Message } from '../localization/message';
import { Exception } from '../core/exception';
import { SessionStorageService, LocalStorageService } from 'ngx-store';
import { ErrorLoggerService, IErrorResponse, ErrorResponse } from '../services/error-logger.service';
import { IRespondent } from '../models/respondent';
import { SampleCubeUser } from '../models/samplecube-user';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector,
    @Inject(forwardRef(() => NgZone))
    private zone: NgZone
  ) {
    this.loggerService = this.injector.get(LoggerService);
    this.sessionStorageService = this.injector.get(SessionStorageService);
    this.localStorageService = this.injector.get(LocalStorageService);
    this.errorLoggerService = this.injector.get(ErrorLoggerService);
    this.snackBar = this.injector.get(MatSnackBar);
  }

  handleError(exception) {
    const self = this;
    // in case of promise error
    if (!!exception.rejection) {
      exception = exception.rejection;
    }


    self.captureError(exception);

    if (!!exception && !!exception.data && !!exception.data.errorCode) {
      // User flow Exceptions
    }
    else {
      self.loggerService.error(exception);
    }


    const data: AlertData = {
      alertType: AlertType.Error,
      text: self.isTest && exception instanceof Exception ? exception.message : Message.GenericErrorMessage,
      code: exception instanceof Exception ? exception.code : null,
      url: self.url,
      title: "Message",
      isTest: self.isTest
    }
    this.zone.run(() => {
      self.snackBar.openFromComponent(SnackBarAlertComponent, { data: data });
    });
  }

  captureError(exception: any) {
    const self = this;
    let input: IErrorResponse = new ErrorResponse();
    const respondent = self.sessionStorageService.get(self.isTest ? "sc.respondent.test" : "sc.respondent");
    const user = self.localStorageService.get(self.isTest ? "sc.profile.test" : "sc.profile");
    const url = self.sessionStorageService.get("sc.landing-url");
    if (!!respondent || !!user) {
      if (!!respondent) {
        input.respondentId = respondent.id || 0;
        input.sessionId = respondent.sessionId || 0;
        input.surveyId = respondent.surveyId || 0;
      }
      if (!!user) {
        input.userId = user.userId || 0;
      }
    }

    if (!!exception.data) {
      input.errorFrom = "API";
      if (!!exception.data.errorCode) {
        input.message = exception.message || "";
        input.errorDetails = exception.details || "";
        input.errorStack = "";
      }
      else if (!!exception.data.exceptionMessage) {
        input.message = exception.data.exceptionMessage || "";
        input.errorDetails = exception.data.exceptionType || "";
        input.errorStack = exception.data.stackTrace || "";
      }
      else {
        input.message = exception.data.Message || "";
        input.errorDetails = exception.data.ExceptionMethod || exception.data.InnerException || "";
        input.errorStack = exception.data.StackTraceString || "";
      }

      if (!input.message) {
        input.message = exception.message || "";
        input.errorDetails = exception.details || "";
        input.errorStack = exception.stack || "";
      }

    }
    else {
      input.errorFrom = "UI";
      if (!exception.message) {
        input.message = exception.toString() || "";
      }
      else {
        input.message = exception.message || "";
        input.errorDetails = exception.details || "";
        input.errorStack = exception.stack || "";
      }
    }

    input.isTest = self.isTest || false;
    input.errorCode = exception.code || 0;
    input.url = url || "";

    self.errorLoggerService.saveErrorResponse(input);
  }

  get url(): string {
    return this.isTest ? this.sessionStorageService.get("sc.landing-url") : "";
  }

  get isTest(): boolean {
    return this.sessionStorageService.get("sc.is-test");
  }

  private snackBar: MatSnackBar;
  private loggerService: LoggerService;
  private sessionStorageService: SessionStorageService;
  private localStorageService: LocalStorageService;
  private errorLoggerService: ErrorLoggerService;
}
