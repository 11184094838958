var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EndingInputDataBase } from "./ending";
var SSIData = /** @class */ (function (_super) {
    __extends(SSIData, _super);
    function SSIData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SSISetStatusRequestApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
        _this.profileParameter = "";
        _this.hHash = "";
        return _this;
    }
    SSIData.prototype.parseUrl = function () {
        return "profile=" + this.profileParameter;
    };
    SSIData.prototype.handleLogic = function (urlParams) {
        if (!!urlParams["profile"]) {
            this.profileParameter = urlParams["profile"];
            this.__logicHandled = true;
        }
        if (!!urlParams["h"]) {
            this.hHash = urlParams["h"];
            this.__logicHandled = true;
        }
    };
    return SSIData;
}(EndingInputDataBase));
export { SSIData };
