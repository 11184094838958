var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QuestionAnswerBase } from './core/question-answer-base';
var SingleImageAnswer = /** @class */ (function (_super) {
    __extends(SingleImageAnswer, _super);
    function SingleImageAnswer(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.SinglePunchImageAnswerApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
        _this.value = 0;
        options = options || { value: 0 };
        _this.value = options.value || 0;
        return _this;
    }
    return SingleImageAnswer;
}(QuestionAnswerBase));
export { SingleImageAnswer };
