var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Type } from "@angular/core";
import { InjectorInstance } from '../core/injector-instance';
import { CATEGORY_REGISTRY } from '../core/globals';
import { CategoryType } from "../models/survey-engine/core/category-type.enum";
import * as i0 from "@angular/core";
var CategoryFactoryService = /** @class */ (function () {
    function CategoryFactoryService() {
        this.__registeredTypes = InjectorInstance.get(CATEGORY_REGISTRY);
    }
    CategoryFactoryService.prototype.createInstance = function (options) {
        var _this = this;
        options = options || {};
        options.categoryType = options.categoryType || 0;
        var key = CategoryType[options.categoryType].toString();
        key = this.normalizedKey(key || "");
        if (!!!key || !this.__hasType(key)) {
            return null;
        }
        var type = null;
        this.__registeredTypes.forEach(function (item) {
            if (_this.normalizedKey(item.key) === key) {
                type = item.type;
                return false;
            }
        });
        return new type(options);
    };
    CategoryFactoryService.prototype.registerType = function (registry) {
        registry = registry || {};
        registry.key = this.normalizedKey(registry.key || "");
        if (!!!registry.key) {
            return false;
        }
        if (!this.__hasType(registry.key)) {
            this.__registeredTypes.push(registry);
            return true;
        }
        return false;
    };
    CategoryFactoryService.prototype.__hasType = function (key) {
        var _this = this;
        var ok = false;
        key = this.normalizedKey(key || "");
        if (!!!key) {
            return false;
        }
        this.__registeredTypes.forEach(function (item) {
            if (_this.normalizedKey(item.key) === key) {
                ok = true;
                return false;
            }
        });
        return ok;
    };
    CategoryFactoryService.prototype.normalizedKey = function (key) {
        return key.toString().toLowerCase();
    };
    CategoryFactoryService.ngInjectableDef = i0.defineInjectable({ factory: function CategoryFactoryService_Factory() { return new CategoryFactoryService(); }, token: CategoryFactoryService, providedIn: "root" });
    return CategoryFactoryService;
}());
export { CategoryFactoryService };
var CategoryTypeRegistryCollection = /** @class */ (function (_super) {
    __extends(CategoryTypeRegistryCollection, _super);
    function CategoryTypeRegistryCollection(array) {
        var _this = _super.call(this) || this;
        Object.setPrototypeOf(_this, CategoryTypeRegistryCollection.prototype);
        var self = _this;
        array = array || [];
        array.forEach(function (item) {
            self.push(item);
        });
        return _this;
    }
    return CategoryTypeRegistryCollection;
}(Array));
export { CategoryTypeRegistryCollection };
