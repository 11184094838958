var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ENDING_DATA } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { Utilities } from '../utils/utilities';
import { Parameter, ParameterCollection } from './parameter';
import { Respondent } from './respondent';
var EndingInputDataBase = /** @class */ (function () {
    function EndingInputDataBase() {
        this.$type = "";
        // urlData: ParameterCollection = [];
        this.respondent = new Respondent();
        this.absUrl = "";
        this.extraParameters = new ParameterCollection();
        this.isTest = false;
        this.relevantId = "";
        this.fraudProfileScore = 0;
        this.country = "";
        this.__logicHandled = false;
    }
    Object.defineProperty(EndingInputDataBase.prototype, "logicHandled", {
        get: function () {
            return this.__logicHandled;
        },
        enumerable: true,
        configurable: true
    });
    EndingInputDataBase.processParameters = function (urlParams) {
        var instance = null;
        if (!!!this.__registeredTypes) {
            this.__registeredTypes = InjectorInstance.get(ENDING_DATA);
        }
        instance = new (this.__registeredTypes.resolve("EndingInputData")).type();
        this.__registeredTypes.forEach(function (registeredType) {
            var concrete = new registeredType.type();
            concrete.handleLogic(urlParams);
            if (concrete.logicHandled) {
                instance = concrete;
                return;
            }
        });
        var absUrl = Utilities.absoluteUrl;
        instance.absUrl = absUrl;
        instance.extraParameters.$values.push(new Parameter({ key: "absUrl", value: absUrl }), new Parameter({
            key: "clientReferralInfo",
            value: window.document.referrer
        }));
        if (!!urlParams["rid"]) {
            instance.respondent.respondentToken = urlParams["rid"];
        }
        else if (!!urlParams["sub_id"]) {
            instance.respondent.respondentToken = urlParams["sub_id"];
        }
        else if (!!urlParams["clickid"]) {
            instance.respondent.respondentToken = urlParams["clickid"];
        }
        else if (!!urlParams["request_uuid"]) {
            instance.respondent.respondentToken = urlParams["request_uuid"];
        }
        instance.respondent.deviceType = Utilities.getDeviceType();
        instance.respondent.returnStatusId = urlParams["rs"];
        return instance;
    };
    return EndingInputDataBase;
}());
export { EndingInputDataBase };
var EndingInputData = /** @class */ (function (_super) {
    __extends(EndingInputData, _super);
    function EndingInputData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusRequestApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
        return _this;
    }
    EndingInputData.prototype.handleLogic = function (urlParams) { };
    EndingInputData.prototype.parseUrl = function () {
        return "";
    };
    return EndingInputData;
}(EndingInputDataBase));
export { EndingInputData };
