var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Exception = /** @class */ (function (_super) {
    __extends(Exception, _super);
    function Exception(options) {
        var _newTarget = this.constructor;
        if (options === void 0) { options = { message: "Unknown Error" }; }
        var _this = _super.call(this, options.message) || this;
        _this.details = null;
        _this.code = 0;
        _this.data = null;
        // restore prototype chain   
        var actualProto = _newTarget.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(_this, actualProto);
        }
        else {
            _this.__proto__ = actualProto;
        }
        _this.code = options.code || _this.code;
        _this.data = options.data || _this.data;
        _this.code = !!_this.data && !!_this.data.errorCode ? _this.data.errorCode : _this.code;
        _this.message = !!_this.data && !!_this.data.message ? _this.data.message : _this.message;
        _this.details = !!_this.data && !!_this.data.details ? _this.data.details : _this.details;
        return _this;
    }
    return Exception;
}(Error));
export { Exception };
var ApiError = /** @class */ (function () {
    function ApiError(options) {
        if (options === void 0) { options = {}; }
        this.message = "";
        this.details = "";
        this.errorCode = 0;
        this.debugData = null;
        this.message = options.message || "";
        this.details = options.details || "";
        this.errorCode = options.errorCode || 0;
        this.debugData = options.debugData || null;
    }
    return ApiError;
}());
export { ApiError };
export function isApiError(data) {
    if (!!data) {
        return true;
    }
    else {
        return data.details !== undefined
            && data.errorCode !== undefined;
    }
}
