import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var QuestionAnswerService = /** @class */ (function () {
    function QuestionAnswerService() {
        // getQualificationData(): IQuestionAnswerDTO {
        //   let questionCommonData: IQuestionAnswerDTO;
        //   questionCommonData.isTest = false;
        //   questionCommonData.respondId = 1;
        //   questionCommonData.respondentId = "1";
        //   questionCommonData.respondentToken = "";
        //   questionCommonData.surveyId = 1;
        //   questionCommonData.vendorId = 1;
        //   return questionCommonData;
        // }
        this.__registered = {};
    }
    QuestionAnswerService.prototype.registerQuestion = function (name) {
        this.__registered[name] = new QuestionAnswerRegistry();
        return this.__registered[name];
    };
    QuestionAnswerService.prototype.getQuestionAnswerRegistry = function (name) {
        return this.__registered[name];
    };
    QuestionAnswerService.ngInjectableDef = i0.defineInjectable({ factory: function QuestionAnswerService_Factory() { return new QuestionAnswerService(); }, token: QuestionAnswerService, providedIn: "root" });
    return QuestionAnswerService;
}());
export { QuestionAnswerService };
var QuestionAnswerRegistry = /** @class */ (function () {
    function QuestionAnswerRegistry() {
        this.answer = new Subject();
        this.answer$ = this.answer.asObservable();
    }
    QuestionAnswerRegistry.prototype.setAnswer = function (answer) {
        this.answer.next(answer);
    };
    return QuestionAnswerRegistry;
}());
export { QuestionAnswerRegistry };
