var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InjectorInstance } from '../core/injector-instance';
import { ICON_MAP } from '../core/globals';
import * as i0 from "@angular/core";
var IconFactoryService = /** @class */ (function () {
    function IconFactoryService() {
        this.__registeredIcons = InjectorInstance.get(ICON_MAP);
    }
    Object.defineProperty(IconFactoryService.prototype, "registeredIcons", {
        get: function () {
            return this.__registeredIcons;
        },
        enumerable: true,
        configurable: true
    });
    IconFactoryService.prototype.resolve = function (name) {
        var _this = this;
        name = this.normalizedName(name || "");
        if (!!!name || !this.__hasPath(name)) {
            return null;
        }
        var path = null;
        this.__registeredIcons.forEach(function (item) {
            if (_this.normalizedName(item.name) === name) {
                path = item.path;
                return false;
            }
        });
        return path;
    };
    IconFactoryService.prototype.registerPath = function (registry) {
        registry = registry || {};
        registry.name = this.normalizedName(registry.name || "");
        if (!!!registry.name) {
            return false;
        }
        if (!this.__hasPath(registry.name)) {
            this.__registeredIcons.push(registry);
            return true;
        }
        return false;
    };
    IconFactoryService.prototype.__hasPath = function (name) {
        var _this = this;
        var ok = false;
        name = this.normalizedName(name || "");
        if (!!!name) {
            return false;
        }
        this.__registeredIcons.forEach(function (item) {
            if (_this.normalizedName(item.name) === name) {
                ok = true;
                return false;
            }
        });
        return ok;
    };
    IconFactoryService.prototype.normalizedName = function (name) {
        return name.toString().toLowerCase();
    };
    IconFactoryService.ngInjectableDef = i0.defineInjectable({ factory: function IconFactoryService_Factory() { return new IconFactoryService(); }, token: IconFactoryService, providedIn: "root" });
    return IconFactoryService;
}());
export { IconFactoryService };
var IconRegistryCollection = /** @class */ (function (_super) {
    __extends(IconRegistryCollection, _super);
    function IconRegistryCollection(array) {
        var _this = _super.call(this) || this;
        Object.setPrototypeOf(_this, IconRegistryCollection.prototype);
        var self = _this;
        array = array || [];
        array.forEach(function (item) {
            self.push(item);
        });
        return _this;
    }
    return IconRegistryCollection;
}(Array));
export { IconRegistryCollection };
