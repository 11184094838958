import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggerService } from '../services/logger.service';
import { Utilities } from '../utils/utilities';


@Injectable({
  providedIn: 'root'
})
export class LoggerHttpInterceptor implements HttpInterceptor {

  constructor(
    private loggerService: LoggerService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const path: string = Utilities.getRequestPath(req.url);
    this.loggerService.log(`before_${req.method}_${path}`, { request: JSON.stringify(req.body) });
    const resp: Observable<HttpEvent<any>> = next.handle(req);
    return resp
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              const response = <HttpResponse<any>>event;
              this.loggerService.log(`after_${req.method}_${path}`, { request: JSON.stringify(req.body), response: JSON.stringify(response) });
            }
          },
          (error: any) => {
            this.loggerService.log(`failed_${req.method}_${path}`, { request: JSON.stringify(req.body), error: JSON.stringify(error) });
            this.loggerService.error(JSON.stringify(error), JSON.stringify(req.body));
          }
        ));
  }
}
