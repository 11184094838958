import { IValidation } from '../../../interfaces/validation';
import { IQuestion, QuestionBase } from './question-base';
import { QuestionType } from './question-type.enum';

export interface IOpenValidation extends IValidation {
  regEx?: string;
  validators: {
    required: boolean;
    pattern: string;
  };
}

export interface IOpenQuestion extends IOpenValidation, IQuestion {
  validators: {
    required: boolean;
    pattern: string;
  };
}

export abstract class OpenQuestionBase extends QuestionBase implements IOpenQuestion {
  validators: {
    required: boolean;
    pattern: string;
  } = {
    required: true,
    pattern: ".*"
  };
  constructor(options?: IOpenQuestion) {
    super(options as IQuestion);

    this.questionType = QuestionType.Single;
    options = options || ({} as IOpenQuestion);
    options.validators = options.validators || { required: true, pattern: ".*" };
    this.validators.required = options.validators.required;
  }
}
