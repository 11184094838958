export interface ICategoricalAnswer {
  $type?: string;
  categoryId?: number;
  checked?: boolean;
  code?: number;
  value?: string;
}

export class CategoricalAnswer implements ICategoricalAnswer {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.CategoricalAnswerApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  categoryId: number;
  checked: boolean;
  code: number;
  value: string;

  constructor(options?: ICategoricalAnswer) {
    options = options || ({} as ICategoricalAnswer);
    this.categoryId = options.categoryId || 0;
    this.checked = options.checked || false;
    this.code = options.code || 0;
    this.value = options.value || "";
  }
}

export interface ICategoricalAnswerCollection {
  $type?: string;
  $values?: Array<ICategoricalAnswer>;
  add?: (item: ICategoricalAnswer) => void;
}

export class CategoricalAnswerCollection implements ICategoricalAnswerCollection {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.CategoricalAnswerCollectionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  $values: Array<ICategoricalAnswer> = [];

  constructor(options?: Array<ICategoricalAnswer> | ICategoricalAnswerCollection) {
    Object.setPrototypeOf(this, CategoricalAnswerCollection.prototype);
    const self = this;
    let array: Array<ICategoricalAnswer> = null;
    if (!!options && !!(options as ICategoricalAnswerCollection).$values) {
      array = (options as ICategoricalAnswerCollection).$values || [];
    } else {
      array = (options as Array<ICategoricalAnswer>) || [];
    }
    if (array && array.length) {
      array.forEach(item => {
        if (!(item instanceof CategoricalAnswer)) {
          item = new CategoricalAnswer(item);
        }
        this.$values.push(item as CategoricalAnswer);
      });
    }
  }

  add(item: ICategoricalAnswer) {
    this.$values.push(item);
  }
}
