import { Injectable, Injector } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { APP_INSIGHTS_CONFIG } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private config: Microsoft.ApplicationInsights.IConfig;

  constructor(
    private injector?: Injector
  ) {
    if (!InjectorInstance.hasInstance && this.injector) {
      InjectorInstance.setInstance(this.injector);
    }
    this.injector = this.injector || InjectorInstance;
    this.config = this.injector.get<Microsoft.ApplicationInsights.IConfig>(APP_INSIGHTS_CONFIG);
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
    }
  }

  logPageView(name?: string, url?: string, properties?: {[name: string]: string}, measurements?: any, duration?: number) {
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  logEvent(name: string, properties?: {[name: string]: string}, measurements?: any) {
    AppInsights.trackEvent(name, properties, measurements);
  }

  logException(exception: Error, properties?: {[name: string]: string}) {
    AppInsights.trackException(exception, undefined, properties);
  }
}
