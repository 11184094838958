var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EndingInputDataBase } from "./ending";
var FedData = /** @class */ (function (_super) {
    __extends(FedData, _super);
    function FedData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.EndingPage.DTOs.FedSetStatusRequestApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
        return _this;
    }
    FedData.prototype.parseUrl = function () {
        return "";
    };
    FedData.prototype.handleLogic = function (urlParams) {
        if (!!urlParams["cost"]) {
            this.cost = urlParams["cost"];
            this.__logicHandled = true;
        }
        if (!!urlParams["fstatus"]) {
            this.status = urlParams["fstatus"];
            this.__logicHandled = true;
        }
        if (!!urlParams["fquota"]) {
            this.quota = urlParams["fquota"];
            this.__logicHandled = true;
        }
        if (!!urlParams["rpi"]) {
            this.rpi = urlParams["rpi"];
            this.__logicHandled = true;
        }
    };
    return FedData;
}(EndingInputDataBase));
export { FedData };
