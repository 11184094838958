import { CheckProfileResponseBase, ICheckProfileResponse } from './check-profile-response-base';

export interface IRedirectProfileResponse extends ICheckProfileResponse {
  redirectUrl: string;
}

export class RedirectProfileResponse extends CheckProfileResponseBase implements IRedirectProfileResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.ProfileRedirectResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  redirectUrl: string;

  constructor(options?: IRedirectProfileResponse) {
    super(options as ICheckProfileResponse);
    options = options || ({} as IRedirectProfileResponse);
    this.redirectUrl = options.redirectUrl || "";
  }
}
