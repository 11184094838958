import { Injectable, Type } from '@angular/core';

import { CHECK_PROFILE_RESPONSE_REGISTRY } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { CheckProfileResponseBase, ICheckProfileResponse } from '../models/profile/check-profile-response-base';
import { ProfileResponseType } from '../models/profile/profile-response-type.enum';

export interface ICheckProfileResponseTypeRegistry {
  key?: string;
  type?: Type<CheckProfileResponseBase>;
}

@Injectable({
  providedIn: "root"
})
export class CheckProfileResponseService {
  constructor() {
    this.__registeredTypes = InjectorInstance.get<CheckProfileResponseRegistryCollection>(CHECK_PROFILE_RESPONSE_REGISTRY);
  }
  createInstance(options?: ICheckProfileResponse): CheckProfileResponseBase {
    options = options || ({} as ICheckProfileResponse);
    options.profileResponseType = options.profileResponseType || 0;
    let key = ProfileResponseType[options.profileResponseType].toString();
    key = this.normalizedKey(key || "");
    if (!!!key || !this.__hasType(key)) {
      return null;
    }

    let type: Type<CheckProfileResponseBase> = null;
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        type = item.type;
        return false;
      }
    });

    return new type(options);
  }

  registerType(registry?: ICheckProfileResponseTypeRegistry): boolean {
    registry = registry || {};
    registry.key = this.normalizedKey(registry.key || "");
    if (!!!registry.key) {
      return false;
    }
    if (!this.__hasType(registry.key)) {
      this.__registeredTypes.push(registry);
      return true;
    }
    return false;
  }

  private __hasType(key: string): boolean {
    let ok: boolean = false;
    key = this.normalizedKey(key || "");
    if (!!!key) {
      return false;
    }
    this.__registeredTypes.forEach(item => {
      if (this.normalizedKey(item.key) === key) {
        ok = true;
        return false;
      }
    });

    return ok;
  }

  private normalizedKey(key: string): string {
    return key.toString().toLowerCase();
  }

  private __registeredTypes: CheckProfileResponseRegistryCollection;
}

export class CheckProfileResponseRegistryCollection extends Array<ICheckProfileResponseTypeRegistry> {
  constructor(array?: Array<ICheckProfileResponseTypeRegistry>) {
    super();
    Object.setPrototypeOf(this, CheckProfileResponseRegistryCollection.prototype);

    const self = this;
    array = array || [];
    array.forEach(item => {
      self.push(item as ICheckProfileResponseTypeRegistry);
    });
  }
}
