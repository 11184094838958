var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import '../../../../extensions/string-extensions';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Message } from '../../../../localization/message';
import { OpenQuestionBaseComponent } from '../core/open-end-question-base.component';
var OpenQuestionComponent = /** @class */ (function (_super) {
    __extends(OpenQuestionComponent, _super);
    function OpenQuestionComponent() {
        return _super.call(this) || this;
    }
    Object.defineProperty(OpenQuestionComponent.prototype, "minLengthErrorMessage", {
        get: function () {
            return this.__minLengthErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenQuestionComponent.prototype, "maxLengthErrorMessage", {
        get: function () {
            return this.__maxLengthErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    OpenQuestionComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        var self = this;
        var question_validators = self.question.validators;
        var validators = [];
        if (question_validators.required) {
            validators.push(Validators.required);
        }
        Object.keys(question_validators).forEach(function (validator) {
            if (validator !== "required" && question_validators[validator] && Validators[validator]) {
                if (validator === "pattern") {
                    validators.push(Validators[validator].apply(undefined, [new RegExp(question_validators["pattern"], "i")]));
                }
                else {
                    validators.push(Validators[validator].apply(undefined, [question_validators[validator]]));
                }
            }
        });
        self.ctrl.setValidators(validators);
        self.__minLengthErrorMessage = String.format(Message.MinLengthExceededMessage, "Value", this.question.validators.minLength);
        self.__maxLengthErrorMessage = String.format(Message.MaxLengthExceededMessage, "Value", this.question.validators.maxLength);
        self.triggerQuestionLoaded();
    };
    OpenQuestionComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return OpenQuestionComponent;
}(OpenQuestionBaseComponent));
export { OpenQuestionComponent };
