var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OpenQuestionBase } from './core/open-question-base';
import { QuestionType } from './core/question-type.enum';
var NumericQuestion = /** @class */ (function (_super) {
    __extends(NumericQuestion, _super);
    function NumericQuestion(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.NumericQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
        _this.validators = {
            required: true,
            pattern: "[0-9]*"
        };
        options = options || {};
        _this.questionType = QuestionType.Numeric;
        options.validators = options.validators || { required: true, pattern: "[0-9]*" };
        _this.validators.min = options.validators.min;
        _this.validators.max = options.validators.max;
        _this.validators.pattern = options.validators.pattern;
        _this.validators.required = options.validators.required;
        return _this;
    }
    return NumericQuestion;
}(OpenQuestionBase));
export { NumericQuestion };
