var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QuestionBaseComponent } from './question-base.component';
var CategoricalQuestionBaseComponent = /** @class */ (function (_super) {
    __extends(CategoricalQuestionBaseComponent, _super);
    function CategoricalQuestionBaseComponent() {
        return _super.call(this) || this;
    }
    CategoricalQuestionBaseComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        var self = this;
    };
    CategoricalQuestionBaseComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    CategoricalQuestionBaseComponent.prototype.clickHandler = function (code, $event) {
        var self = this;
        var category = self.getCategory(code);
        if (category.isOther) {
            $event.preventDefault();
        }
    };
    Object.defineProperty(CategoricalQuestionBaseComponent.prototype, "otherCtrls", {
        get: function () {
            var self = this;
            var obj = {};
            self.categories
                .filter(function (c) { return c.isOther; })
                .forEach(function (c) {
                var key = self.question.name + "_" + c.code + "_other";
                if (self.form.contains(key)) {
                    obj[c.code] = self.form.get(key);
                }
            });
            return Object.keys(obj).length ? obj : null;
        },
        enumerable: true,
        configurable: true
    });
    CategoricalQuestionBaseComponent.prototype.getCategoryById = function (id) {
        var categories = this.categories.filter(function (c) { return c.id === id; });
        return categories.length ? categories[0] : null;
    };
    CategoricalQuestionBaseComponent.prototype.getCategory = function (code) {
        var category = null;
        this.categories.forEach(function (c) {
            if (c.code.toString() === code.toString()) {
                category = c;
                return;
            }
        });
        return category;
    };
    return CategoricalQuestionBaseComponent;
}(QuestionBaseComponent));
export { CategoricalQuestionBaseComponent };
