import { Component, OnDestroy, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { MatRadioButton } from "@angular/material";

import { Category } from "../../../../models/survey-engine/category";
import { SingleAnswer } from "../../../../models/survey-engine/single-answer";
import { SingleQuestion } from "../../../../models/survey-engine/single-question";
import { CategoricalQuestionBaseComponent } from "../core/categorical-question-base.component";

import "../../../../extensions/string-extensions";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";

@Component({
  selector: "app-single-question",
  templateUrl: "./single-question.component.html",
  styleUrls: ["./single-question.component.scss"]
})
export class SingleQuestionComponent extends CategoricalQuestionBaseComponent implements OnInit, OnDestroy {
  searchText: string;
  protected setQuestionAnswer(value: any) {
    const self = this;
    self.__questionAnswer = new SingleAnswer({
      value: self.getCategory(value).id
    });
    self.__questionAnswer.answerType = AnswerType.Single;
  }
  get categories(): Array<Category> {
    const categories: Array<Category> = [];
    (this.question as SingleQuestion).categories.$values.forEach(c => {
      categories.push(c as Category);
    });
    return categories;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const self = this;

    const validators = [];
    if ((self.question as SingleQuestion).validators.required) {
      validators.push(Validators.required);
    }

    Object.keys((self.question as SingleQuestion).validators).forEach(validator => {
      if (validator !== "required" && (self.question as SingleQuestion).validators[validator]) {
        validators.push(Validators[validator].apply(undefined, [(self.question as SingleQuestion).validators[validator]]));
      }
    });

    self.ctrl.setValidators(validators);
    if (self.question.response && (self.question.response as SingleAnswer).value) {
      const category = self.getCategoryById((self.question.response as SingleAnswer).value);
      if (category && category.code) {
        self.ctrl.setValue(category.code);
      }
    }
    self.triggerQuestionLoaded();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  categoryChanged(option: MatRadioButton, i): void {
    // const self = this;
    // super.categoryChanged(option, i);
    // const code: number = option.value;
    // const category: Category = self.getCategory(code);
    // const isExclusive: boolean = category.isExclusive;
    // if (!isExclusive) {
    //   self.categories.forEach((item: Category) => {
    //     if (item.isOther && item.code !== code) {
    //       self.getAnswer(item.code).checked = false;
    //       self.getAnswer(item.code).value = "";
    //     }
    //   });
    // }
  }
}
