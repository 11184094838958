import { CategoricalQuestionBase, ICategoricalQuestion } from './core/categorical-question-base';
import { QuestionType } from './core/question-type.enum';

export interface IMultiQuestionValidation extends ICategoricalQuestion {
  validators: {
    required: boolean;
    minAnswers?: number;
    maxAnswers?: number;
  };
}
export interface IMultiImageQuestionData {
  imageName?: string;
  imagePath?: string;
  imageSelected?: boolean;
  questionId?:number;
  categoryId?:number;
  code?: number;
}


export interface IMultiImageQuestion extends IMultiQuestionValidation {}

export class MultiImageQuestion extends CategoricalQuestionBase implements IMultiQuestionValidation {
  validators: {
    required: boolean;
    minAnswers?: number;
    maxAnswers?: number;
  } = {
    required: true
  };
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.MultiImageQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  constructor(options?: IMultiImageQuestion) {
    super(options as ICategoricalQuestion);
    this.questionType = QuestionType.MultiPunchImage;
    options = options || ({} as IMultiQuestionValidation);
    options.validators = options.validators || { required: true };
    this.validators.required = options.validators.required;
    this.validators.minAnswers = options.validators.minAnswers;
    this.validators.maxAnswers = options.validators.maxAnswers;
  }
}
