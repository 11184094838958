import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { LoggerService } from '../services/logger.service';
import { Utilities } from '../utils/utilities';
import * as i0 from "@angular/core";
import * as i1 from "../services/logger.service";
var AppInsightsHttpInterceptor = /** @class */ (function () {
    function AppInsightsHttpInterceptor(loggerService) {
        this.loggerService = loggerService;
    }
    AppInsightsHttpInterceptor.prototype.intercept = function (req, next) {
        var path = Utilities.getRequestPath(req.url);
        this.loggerService.sendLogToAppInsights(req.method + "_" + path, req.body);
        return next.handle(req);
    };
    AppInsightsHttpInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function AppInsightsHttpInterceptor_Factory() { return new AppInsightsHttpInterceptor(i0.inject(i1.LoggerService)); }, token: AppInsightsHttpInterceptor, providedIn: "root" });
    return AppInsightsHttpInterceptor;
}());
export { AppInsightsHttpInterceptor };
