import { CategoricalAnswerCollection, ICategoricalAnswerCollection } from './core/categorical-answer';
import { IQuestionAnswer, QuestionAnswerBase } from './core/question-answer-base';

export interface IMultiAnswer extends IQuestionAnswer {
  values: ICategoricalAnswerCollection;
}

export class MultiAnswer extends QuestionAnswerBase implements IMultiAnswer {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.MultiAnswerApiModel, SampleCube.UserFlow.API.Models.SurveyPage";

  values: ICategoricalAnswerCollection = null;
  constructor(options?: IMultiAnswer) {
    super(options as IQuestionAnswer);
    options = options || ({} as IMultiAnswer);
    this.values = new CategoricalAnswerCollection(options.values || {});
  }
}
