import { NgModule } from '@angular/core';

import {
  CalendarQuestionComponent,
} from './components/survey-page/survey-components/calendar-question/calendar-question.component';
import { MultiQuestionComponent } from './components/survey-page/survey-components/multi-question/multi-question.component';
import {
  NumericQuestionComponent,
} from './components/survey-page/survey-components/numeric-question/numeric-question.component';
import { OpenQuestionComponent } from './components/survey-page/survey-components/open-question/open-question.component';
import {
  SingleQuestionComponent,
} from './components/survey-page/survey-components/single-question/single-question.component';
import { QUESTION_REGISTRY } from './core/globals';
import { CalendarQuestion } from './models/survey-engine/calendar-question';
import { QuestionType } from './models/survey-engine/core/question-type.enum';
import { MultiQuestion } from './models/survey-engine/multi-question';
import { NumericQuestion } from './models/survey-engine/numeric-question';
import { OpenTextQuestion } from './models/survey-engine/open-text-question';
import { SingleQuestion } from './models/survey-engine/single-question';
import { QuestionRegistryCollection } from './services/question-factory.service';
import { SingleImageQuestionComponent } from './components/survey-page/survey-components/single-image-question/single-image-question.component';
import { SingleImageQuestion } from './models/survey-engine/single-image-question';
import { MultiImageQuestionComponent } from './components/survey-page/survey-components/multi-image-question/multi-image-question.component';
import { MultiImageQuestion } from './models/survey-engine/multi-image-question';
import { VideoQuestionComponent } from './components/survey-page/survey-components/video-question/video-question.component';
import { VideoQuestion } from './models/survey-engine/video-question';

@NgModule({
  providers: [
    {
      provide: QUESTION_REGISTRY,
      useFactory: function() {
        return new QuestionRegistryCollection([
          {
            componentType: SingleQuestionComponent,
            key: QuestionType[QuestionType.Single],
            type: SingleQuestion
          },
          {
            componentType: MultiQuestionComponent,
            key: QuestionType[QuestionType.Multi],
            type: MultiQuestion
          },
          {
            componentType: NumericQuestionComponent,
            key: QuestionType[QuestionType.Numeric],
            type: NumericQuestion
          },
          {
            componentType: OpenQuestionComponent,
            key: QuestionType[QuestionType.Open],
            type: OpenTextQuestion
          },
          {
            componentType: CalendarQuestionComponent,
            key: QuestionType[QuestionType.Calendar],
            type: CalendarQuestion
          },
          {
            componentType: SingleImageQuestionComponent,
            key: QuestionType[QuestionType.SinglePunchImage],
            type: SingleImageQuestion
          },
          {
            componentType: MultiImageQuestionComponent,
            key: QuestionType[QuestionType.MultiPunchImage],
            type: MultiImageQuestion
          },
          {
            componentType: VideoQuestionComponent,
            key: QuestionType[QuestionType.VideoType],
            type: VideoQuestion
          }
        ]);
      }
    }
  ]
})
export class QuestionRegistryModule {}
