var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PageCollection } from '../survey-engine/page';
import { CheckProfileResponseBase } from './check-profile-response-base';
var TargetedProfileResponse = /** @class */ (function (_super) {
    __extends(TargetedProfileResponse, _super);
    function TargetedProfileResponse(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.ProfileTargetedResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
        options = options || {};
        _this.isPLRouterEnabled = options.isPLRouterEnabled;
        _this.isPollfishEnabled = options.isPollfishEnabled;
        _this.clientInvokeUrl = options.clientInvokeUrl;
        _this.isSampleChainEnabled = options.isSampleChainEnabled;
        _this.sampleChainDestinationPlatformId = options.sampleChainDestinationPlatformId;
        _this.sampleChainDestinationSurveyNumber = options.sampleChainDestinationSurveyNumber;
        _this.pages = new PageCollection(options.pages);
        _this.verifyRelevantId = options.verifyRelevantId;
        _this.verifyRFGFingerPrint = options.verifyRFGFingerPrint;
        return _this;
    }
    return TargetedProfileResponse;
}(CheckProfileResponseBase));
export { TargetedProfileResponse };
