import {
  ICategoricalQuestion,
  CategoricalQuestionBase
} from "./core/categorical-question-base";
import { QuestionType } from "./core/question-type.enum";

export interface ISingleImageQuestionData {
  imageName?: string;
  imagePath?: string;
  imageSelected?: boolean;
  questionId?:number;
  categoryId?:number;
  code?: number;
}

export interface ISingleImageQuestion extends ICategoricalQuestion,ISingleImageQuestionData {
}

export class SingleImageQuestion extends CategoricalQuestionBase
  implements ICategoricalQuestion {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.SingleImageQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";

  imageName: string;
  imagePath: string;
  imageSelected: boolean;
  constructor(options?: ISingleImageQuestion) {
    super(options as ICategoricalQuestion);
    this.questionType = QuestionType.SinglePunchImage;
    this.imageName = options.imageName;
    this.imagePath = options.imagePath;
    this.imageSelected = options.imageSelected;
    return this;
  }
}
