import { NgModule } from '@angular/core';

import { ANSWER_REGISTRY } from './core/globals';
import { AnswerType } from './models/survey-engine/core/answer-type.enum';
import { MultiAnswer } from './models/survey-engine/multi-answer';
import { OpenAnswer } from './models/survey-engine/open-answer';
import { SingleAnswer } from './models/survey-engine/single-answer';
import { AnswerRegistryCollection } from './services/answer-factory.service';

@NgModule({
  providers: [
    {
      provide: ANSWER_REGISTRY,
      useFactory: function() {
        return new AnswerRegistryCollection([
          {
            key: AnswerType[AnswerType.Single],
            type: SingleAnswer
          },
          {
            key: AnswerType[AnswerType.Multi],
            type: MultiAnswer
          },
          {
            key: AnswerType[AnswerType.Open],
            type: OpenAnswer
          }
        ]);
      }
    }
  ]
})
export class AnswerRegistryModule {}
