import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError, timeout } from 'rxjs/operators';
import { InjectorInstance } from './injector-instance';
import { API_BASE } from './globals';
import { APICallType } from '../enums/call-type.enum';

export abstract class HttpServiceBase {
  private http: HttpClient;

  private rootUri: string;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor() {
    this.rootUri = InjectorInstance.get<string>(API_BASE);
    this.http = InjectorInstance.get<HttpClient>(HttpClient);
  }

  protected async get<TRequest, TResponse>(path: string, headers?: HttpHeaders, errorCallback?: (error: Error) => void, callType?: APICallType, timeOut?: number, withCredentials?: boolean): Promise<TResponse> {
    return await this.executeAction<TRequest, TResponse>(Method.Get, path, <TRequest>{}, headers, errorCallback, callType, timeOut, withCredentials).toPromise<TResponse>();
  }

  protected async delete<TRequest, TResponse>(path: string, headers?: HttpHeaders, errorCallback?: (error: Error) => void, callType?: APICallType): Promise<TResponse> {
    return await this.executeAction<TRequest, TResponse>(Method.Delete, path, <TRequest>{}, headers, errorCallback, callType).toPromise<TResponse>();
  }

  protected async post<TRequest, TResponse>(path: string, request?: TRequest, headers?: HttpHeaders, errorCallback?: (error: Error) => void, callType?: APICallType, timeOut?: number): Promise<TResponse> {
    return await this.executeAction<TRequest, TResponse>(Method.Post, path, <TRequest>request, headers, errorCallback, callType, timeOut).toPromise<TResponse>();
  }

  protected async put<TRequest, TResponse>(path: string, request?: TRequest, headers?: HttpHeaders, errorCallback?: (error: Error) => void, callType?: APICallType): Promise<TResponse> {
    return await this.executeAction<TRequest, TResponse>(Method.Put, path, <TRequest>request, headers, errorCallback, callType).toPromise<TResponse>();
  }

  private executeAction<TRequest, TResponse>(method: Method, path: string, request?: TRequest, headers?: HttpHeaders, errorCallback?: (error: Error) => void, callType?: APICallType, timeOut: number = null, withCredentials: boolean = null): Observable<TResponse> {
    const self = this;
    let url = `${self.rootUri}${path}`;
    let options = {
      headers: self.getRequestHeaders(headers)
    };
    if (!!withCredentials) {
      options['withCredentials'] = withCredentials;
    }
    if (callType === APICallType.ThirdPartyAPI) {
      url = path;
      options.headers = null;
    }

    let call: Observable<HttpEvent<TResponse>> = null;

    switch (method) {
      case Method.Post: call = self.executePost(url, request, options); break;
      case Method.Put: call = self.executePut(url, request, options); break;
      case Method.Delete: call = self.executeDelete(url, options); break;
      default: call = self.executeGet(url, options); break;
    }
	if (!!timeOut) {
		return call.pipe(
			tap((result: TResponse) => {
			  return of(result);
			}),
			timeout(timeOut * 1000),
			catchError((error: Error) => {
			  if ('function' === typeof errorCallback) {
				errorCallback(error);
			  }
			  return of({} as TResponse);
			})
		  );
	}
    return call.pipe(
      tap((result: TResponse) => {
        return of(result);
	  }),
      catchError((error: Error) => {
        if ('function' === typeof errorCallback) {
          errorCallback(error);
        }
        return of({} as TResponse);
      })
    );
  }

  private executeGet<TResponse>(url: string, options?: any): Observable<HttpEvent<TResponse>> {
    return this.http.get<TResponse>(url, options);
  }
  private executeDelete<TResponse>(url: string, options?: any): Observable<HttpEvent<TResponse>> {
    return this.http.delete<TResponse>(url, options);
  }
  private executePost<TRequest, TResponse>(url: string, request?: TRequest, options?: any): Observable<HttpEvent<TResponse>> {
    return this.http.post<TResponse>(url, request, options);
  }
  private executePut<TRequest, TResponse>(url: string, request?: TRequest, options?: any): Observable<HttpEvent<TResponse>> {
    return this.http.put<TResponse>(url, request, options);
  }

  private getRequestHeaders(headers?: HttpHeaders) {
    const self = this;
    headers = headers || new HttpHeaders();

    const _headers: { [name: string]: string } = {};
    this.headers.keys().forEach(headerName => {
      const headerValue = self.headers.get(headerName);
      _headers[headerName] = headerValue;
    });
    headers.keys().forEach(headerName => {
      const headerValue = self.headers.get(headerName);
      _headers[headerName] = headerValue;
    });
    return new HttpHeaders(_headers);
  }
}

enum Method {
  Get = 0,
  Post = 1,
  Put = 2,
  Delete = 3
}
