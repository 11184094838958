import {
  ICategoricalQuestion,
  CategoricalQuestionBase
} from "./core/categorical-question-base";
import { QuestionType } from "./core/question-type.enum";

export interface IVideoQuestionData {
  videoPath?: string;
  questionId?:number;
  categoryId?:number;
  code?: number;
}

export interface IVideoQuestion extends ICategoricalQuestion,IVideoQuestionData {
}

export class VideoQuestion extends CategoricalQuestionBase
  implements ICategoricalQuestion {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.VideoQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";

  videoPath: string;
  constructor(options?: IVideoQuestion) {
    super(options as ICategoricalQuestion);
    this.questionType = QuestionType.VideoType;
    this.videoPath = options.videoPath;
    return this;
  }
}
