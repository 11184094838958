declare global {
  interface StringConstructor {
    format: (input: string, ...args: any[]) => string;
  }
}

String.format = function(input: string, ...args: any[]): string {
  if (!!!input || "string" !== typeof input || !!!args || !!!args.length) {
    return null;
  }
  let output: string = null;
  const pattern = new RegExp(/\{[0-9]+\}/gim);
  const results: RegExpMatchArray = input.match(pattern);
  if (!!results && !!results.length) {
    output = input;
    results.forEach((match, index) => {
      output = output.replace(match, args[index].toString());
    });
  }
  return output;
};

export {};
