var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Type } from '@angular/core';
import { ANSWER_REGISTRY } from '../core/globals';
import { InjectorInstance } from '../core/injector-instance';
import { AnswerType } from '../models/survey-engine/core/answer-type.enum';
import * as i0 from "@angular/core";
var AnswerFactoryService = /** @class */ (function () {
    function AnswerFactoryService() {
        this.__registeredTypes = InjectorInstance.get(ANSWER_REGISTRY);
    }
    AnswerFactoryService.prototype.createInstance = function (options) {
        var _this = this;
        options = options || {};
        options.answerType = options.answerType || 0;
        var key = AnswerType[options.answerType].toString();
        key = this.normalizedKey(key || "");
        if (!!!key || !this.__hasType(key)) {
            return null;
        }
        var type = null;
        this.__registeredTypes.forEach(function (item) {
            if (_this.normalizedKey(item.key) === key) {
                type = item.type;
                return false;
            }
        });
        return new type(options);
    };
    AnswerFactoryService.prototype.registerType = function (registry) {
        registry = registry || {};
        registry.key = this.normalizedKey(registry.key || "");
        if (!!!registry.key) {
            return false;
        }
        if (!this.__hasType(registry.key)) {
            this.__registeredTypes.push(registry);
            return true;
        }
        return false;
    };
    AnswerFactoryService.prototype.__hasType = function (key) {
        var _this = this;
        var ok = false;
        key = this.normalizedKey(key || "");
        if (!!!key) {
            return false;
        }
        this.__registeredTypes.forEach(function (item) {
            if (_this.normalizedKey(item.key) === key) {
                ok = true;
                return false;
            }
        });
        return ok;
    };
    AnswerFactoryService.prototype.normalizedKey = function (key) {
        return key.toString().toLowerCase();
    };
    AnswerFactoryService.ngInjectableDef = i0.defineInjectable({ factory: function AnswerFactoryService_Factory() { return new AnswerFactoryService(); }, token: AnswerFactoryService, providedIn: "root" });
    return AnswerFactoryService;
}());
export { AnswerFactoryService };
var AnswerRegistryCollection = /** @class */ (function (_super) {
    __extends(AnswerRegistryCollection, _super);
    function AnswerRegistryCollection(array) {
        var _this = _super.call(this) || this;
        Object.setPrototypeOf(_this, AnswerRegistryCollection.prototype);
        var self = _this;
        array = array || [];
        array.forEach(function (item) {
            self.push(item);
        });
        return _this;
    }
    return AnswerRegistryCollection;
}(Array));
export { AnswerRegistryCollection };
