var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CategoricalQuestionBase } from './core/categorical-question-base';
import { QuestionType } from './core/question-type.enum';
var MultiImageQuestion = /** @class */ (function (_super) {
    __extends(MultiImageQuestion, _super);
    function MultiImageQuestion(options) {
        var _this = _super.call(this, options) || this;
        _this.validators = {
            required: true
        };
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.MultiImageQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
        _this.questionType = QuestionType.MultiPunchImage;
        options = options || {};
        options.validators = options.validators || { required: true };
        _this.validators.required = options.validators.required;
        _this.validators.minAnswers = options.validators.minAnswers;
        _this.validators.maxAnswers = options.validators.maxAnswers;
        return _this;
    }
    return MultiImageQuestion;
}(CategoricalQuestionBase));
export { MultiImageQuestion };
