import { CheckQualificationResponseBase, ICheckQualificationResponse } from './check-qualification-response-base';
import { IRoutedResponse } from '../profile/check-profile-response-base';
import { IPageCollection, PageCollection } from './page';

export interface ICheckQualificationRoutedResponse extends IRoutedResponse, ICheckQualificationResponse {
}

export class CheckQualificationRoutedResponse extends CheckQualificationResponseBase implements ICheckQualificationRoutedResponse {
  $type?: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationRoutedResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  pages: IPageCollection;
  respondentId: number;
  respondentToken: string;
  surveyId: number;
  surveyToken: string;
  isPollfishEnabled: boolean;
  isPLRouterEnabled: boolean;
  clientInvokeUrl: string;
  isSampleChainEnabled: boolean;
  verifyRelevantId: boolean;
  isPIDRouting: boolean = false;
  isRecontact: boolean = false;
  originalUserId?: number = 0;
  sampleChainDestinationPlatformId: string = "";
  sampleChainDestinationSurveyNumber: string = "";
  verifyRFGFingerPrint: boolean = false;

  constructor(options?: ICheckQualificationRoutedResponse) {
    super(options as ICheckQualificationResponse);
    options = options || ({} as ICheckQualificationRoutedResponse);
    this.pages = new PageCollection(options.pages);
    this.respondentId = options.respondentId;
    this.respondentToken = options.respondentToken;
    this.surveyId = options.surveyId;
    this.surveyToken = options.surveyToken;
    this.isPLRouterEnabled = options.isPLRouterEnabled;
    this.isPollfishEnabled = options.isPollfishEnabled;
    this.clientInvokeUrl = options.clientInvokeUrl;
    this.isSampleChainEnabled = options.isSampleChainEnabled;
    this.verifyRelevantId = options.verifyRelevantId;
    this.isPIDRouting = options.isPIDRouting;
    this.isRecontact = options.isRecontact;
    this.originalUserId = options.originalUserId || 0;
    this.sampleChainDestinationPlatformId=options.sampleChainDestinationPlatformId || "";
    this.sampleChainDestinationSurveyNumber=options.sampleChainDestinationSurveyNumber || "";
  	this.verifyRFGFingerPrint = options.verifyRFGFingerPrint;
  }
}
