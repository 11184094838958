var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CheckQualificationResponseBase } from './check-qualification-response-base';
var CheckQualificationSuccessResponse = /** @class */ (function (_super) {
    __extends(CheckQualificationSuccessResponse, _super);
    function CheckQualificationSuccessResponse(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationSuccessResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
        options = options || {};
        _this.skipLogicEnabled = options.skipLogicEnabled;
        _this.nextQualificationId = options.nextQualificationId;
        _this.isSkipLogicJumpToClient = options.isSkipLogicJumpToClient;
        return _this;
    }
    return CheckQualificationSuccessResponse;
}(CheckQualificationResponseBase));
export { CheckQualificationSuccessResponse };
