import { QuestionCollection, QuestionBase } from './core/question-base';

export interface IPage {
  $type?: string;
  questions?: QuestionCollection;
  title?: string;
  id?: number;
  nextButtonText?: string;
}

export class Page implements IPage {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.PageApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  questions: QuestionCollection;
  title: string = "";
  id: number = 0;
  nextButtonText: string = "Next";
  constructor(options?: IPage) {
    options = options || ({} as IPage);
    this.id = options.id || 0;
    this.title = options.title || "";
    this.questions = new QuestionCollection(options.questions);
    this.nextButtonText = options.nextButtonText || "Next";
  }

  getQuestion(name: string): QuestionBase {
    const self = this;
    const questions = self.questions.$values.filter(q => q.name === name);
    return questions.length === 1 ? questions[0] as QuestionBase : null;
  }

}

export interface IPageCollection {
  $type?: string;
  $values?: Array<IPage>;
}

export class PageCollection implements IPageCollection {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.PageCollectionApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
  $values: Array<IPage> = [];

  constructor(options?: Array<IPage> | IPageCollection) {
    const self = this;
    let array: Array<IPage> = null;
    if (!!options && !!(options as IPageCollection).$values) {
      array = (options as IPageCollection).$values || [];
    } else {
      array = (options as Array<IPage>) || [];
    }
    array.forEach(item => {
      if (!(item instanceof Page)) {
        item = new Page(item as IPage);
      }
      this.$values.push(item as Page);
    });
  }

}
