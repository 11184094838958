var Message = /** @class */ (function () {
    function Message() {
    }
    Object.defineProperty(Message, "LanguageCode", {
        set: function (langCode) {
            this.__languageCode = langCode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "GenericErrorMessage", {
        get: function () {
            var message = this.__genericErrorMessage[this.__languageCode];
            if (!!!message) {
                return this.__genericErrorMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "InvalidRespondent", {
        get: function () {
            var message = this.__invalidRespondent[this.__languageCode];
            if (!!!message) {
                return this.__invalidRespondent[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "InvalidReturnStatus", {
        get: function () {
            var message = this.__invalidReturnStatus[this.__languageCode];
            if (!!!message) {
                return this.__invalidReturnStatus[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "RespondentNotFound", {
        get: function () {
            var message = this.__respondentNotFound[this.__languageCode];
            if (!!!message) {
                return this.__respondentNotFound[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "Revisited", {
        get: function () {
            var message = this.__revisited[this.__languageCode];
            if (!!!message) {
                return this.__revisited[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "ThanksForParticipation", {
        get: function () {
            var message = this.__thanksForParticipation[this.__languageCode];
            if (!!!message) {
                return this.__thanksForParticipation[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "InvalidNumberErrorMessage", {
        get: function () {
            var message = this.__invalidNumberErrorMessage[this.__languageCode];
            if (!!!message) {
                return this.__invalidNumberErrorMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "InvalidEmailErrorMessage", {
        get: function () {
            var message = this.__invalidEmailErrorMessage[this.__languageCode];
            if (!!!message) {
                return this.__invalidEmailErrorMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "InvalidMobileErrorMessage", {
        get: function () {
            var message = this.__invalidMobileErrorMessage[this.__languageCode];
            if (!!!message) {
                return this.__invalidMobileErrorMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "InvalidDateofbirthErrorMessage", {
        get: function () {
            var message = this.__invalidDateofbirthErrorMessage[this.__languageCode];
            if (!!!message) {
                return this.__invalidDateofbirthErrorMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "QuestionRequiredMessage", {
        get: function () {
            var message = this.__questionRequiredMessage[this.__languageCode];
            if (!!!message) {
                return this.__questionRequiredMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "InvalidFormatMessage", {
        get: function () {
            var message = this.__invalidPatternMessage[this.__languageCode];
            if (!!!message) {
                return this.__invalidPatternMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "MinLengthExceededMessage", {
        get: function () {
            var message = this.__minLengthExceededMessage[this.__languageCode];
            if (!!!message) {
                return this.__minLengthExceededMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "MaxLengthExceededMessage", {
        get: function () {
            var message = this.__maxLengthExceededMessage[this.__languageCode];
            if (!!!message) {
                return this.__maxLengthExceededMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "MinValueExceededMessage", {
        get: function () {
            var message = this.__minValueExceededMessage[this.__languageCode];
            if (!!!message) {
                return this.__minValueExceededMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "MaxValueExceededMessage", {
        get: function () {
            var message = this.__maxValueExceededMessage[this.__languageCode];
            if (!!!message) {
                return this.__maxValueExceededMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "MinDateExceededMessage", {
        get: function () {
            var message = this.__minDateExceededMessage[this.__languageCode];
            if (!!!message) {
                return this.__minDateExceededMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "MaxDateExceededMessage", {
        get: function () {
            var message = this.__maxDateExceededMessage[this.__languageCode];
            if (!!!message) {
                return this.__maxDateExceededMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "MinAnswersExceededMessage", {
        get: function () {
            var message = this.__minAnswersExceededMessage[this.__languageCode];
            if (!!!message) {
                return this.__minAnswersExceededMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Message, "MaxAnswersExceededMessage", {
        get: function () {
            var message = this.__maxAnswersExceededMessage[this.__languageCode];
            if (!!!message) {
                return this.__maxAnswersExceededMessage[this.__defaultLanguageCode];
            }
            return message;
        },
        enumerable: true,
        configurable: true
    });
    Message.__questionRequiredMessage = {
        "en-us": "<i>Value</i> is required.",
        //"fr-fr": "<i>Valeur</i> est requis.",
        "jp-jp": "<i>価値</i> が必要です.",
        "ja-jp": "<i>価値</i> が必要です."
    };
    Message.__invalidPatternMessage = {
        "en-us": "<i>Value</i> must be something like '{1}'",
        // "fr-fr": "La <i>Valeur</i> doit ressembler à '{1}'",
        "jp-jp": "<i>価値</i> のようなものでなければなりません '{1}'",
        "ja-jp": "<i>価値</i> のようなものでなければなりません '{1}'"
    };
    Message.__minLengthExceededMessage = {
        "en-us": "<i>Value</i> must be minimum {1} characters long.",
        // "fr-fr": "La <i>Valeur</i> doit comporter au moins {1} caractères.",
        "jp-jp": "<i>価値</i> 最小文字数でなければなりません {1}.",
        "ja-jp": "<i>価値</i> 最小文字数でなければなりません {1}."
    };
    Message.__maxLengthExceededMessage = {
        "en-us": "<i>Value</i> must be maximum {1} characters long.",
        //"fr-fr":" La <i>Valeur</i> doit comporter au maximum  {1} caractères.",
        "jp-jp": " <i>価値</i> 最大文字数でなければなりません {1}.",
        "ja-jp": " <i>価値</i> 最大文字数でなければなりません {1}."
    };
    Message.__minValueExceededMessage = {
        "en-us": "<i>Value</i> must be minimum {1}.",
        //"fr-fr": "La <i>Valeur</i> doit être minimale {1}.",
        "jp-jp": "<i>価値</i> 最小限でなければなりません {1}.",
        "ja-jp": "<i>価値</i> 最小限でなければなりません {1}."
    };
    Message.__maxValueExceededMessage = {
        "en-us": "<i>Value</i> must be maximum {1}.",
        //"fr-fr": "La <i>Valeur</i> doit être maximale {1}.",
        "jp-jp": "<i>価値</i> 最大でなければなりません {1}.",
        "ja-jp": "<i>価値</i> 最大でなければなりません {1}."
    };
    Message.__minDateExceededMessage = {
        "en-us": "<i>Value</i> must be minimum {1}.",
    };
    Message.__maxDateExceededMessage = {
        "en-us": "<i>Value</i> must be maximum {1}.",
    };
    Message.__minAnswersExceededMessage = {
        "en-us": "<i>Value</i> must have minimum {1} selections.",
        //"fr-fr": "La <i>Valeur</i> doit comporter au minimum {1} sélections.",
        "jp-jp": "<i>価値</i> 最小限の選択が必要です {1}.",
        "ja-jp": "<i>価値</i> 最小限の選択が必要です {1}."
    };
    Message.__maxAnswersExceededMessage = {
        "en-us": "<i>Value</i> must have maximum {1} selections.",
        // "fr-fr": "La <i>Valeur</i> doit avoir un maximum de {1} sélections.",
        "jp-jp": "<i>価値</i> 最小限の選択が必要です {1}.",
        "ja-jp": "<i>価値</i> 最小限の選択が必要です {1}."
    };
    Message.__genericErrorMessage = {
        "en-gb": "Oops! It looks like something went wrong...",
        "en-us": "Oops! It looks like something went wrong..."
    };
    Message.__thanksForParticipation = {
        "en-gb": "Thanks for participation.",
        "en-us": "Thanks for participation."
    };
    Message.__invalidNumberErrorMessage = {
        "en-gb": "Please add valid Number.",
        "en-us": "Please add valid Number."
    };
    Message.__invalidEmailErrorMessage = {
        "en-gb": "Please add valid Email Address.",
        "en-us": "Please add valid Email Address."
    };
    Message.__invalidMobileErrorMessage = {
        "en-gb": "Please add valid Mobile.",
        "en-us": "Please add valid Mobile."
    };
    Message.__invalidDateofbirthErrorMessage = {
        "en-gb": "Please add valid Dateofbirth.",
        "en-us": "Please add valid Dateofbirth"
    };
    Message.__respondentNotFound = Message.__genericErrorMessage;
    Message.__revisited = Message.__genericErrorMessage;
    Message.__invalidRespondent = Message.__genericErrorMessage;
    Message.__invalidReturnStatus = Message.__genericErrorMessage;
    Message.__defaultLanguageCode = "en-us";
    return Message;
}());
export { Message };
