import '../../../../extensions/string-extensions';

import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';

import { Message } from '../../../../localization/message';
import { OpenTextQuestion } from '../../../../models/survey-engine/open-text-question';
import { OpenQuestionBaseComponent } from '../core/open-end-question-base.component';

@Component({
  selector: "app-open-question",
  templateUrl: "./open-question.component.html",
  styleUrls: ["./open-question.component.scss"]
})
export class OpenQuestionComponent extends OpenQuestionBaseComponent implements OnInit, OnDestroy {
  get minLengthErrorMessage(): string {
    return this.__minLengthErrorMessage;
  }

  get maxLengthErrorMessage(): string {
    return this.__maxLengthErrorMessage;
  }

  @ViewChild("autosize") autosize: CdkTextareaAutosize;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const self = this;
    const question_validators = (self.question as OpenTextQuestion).validators;
    const validators = [];
    if (question_validators.required) {
      validators.push(Validators.required);
    }

    Object.keys(question_validators).forEach(validator => {
      if (validator !== "required" && question_validators[validator] && Validators[validator]) {
        if (validator === "pattern") {
          validators.push(Validators[validator].apply(undefined, [new RegExp(question_validators["pattern"], "i")]));
        } else {
          validators.push(Validators[validator].apply(undefined, [question_validators[validator]]));
        }
      }
    });

    self.ctrl.setValidators(validators);

    self.__minLengthErrorMessage = String.format(Message.MinLengthExceededMessage, "Value", (this.question as OpenTextQuestion).validators.minLength);
    self.__maxLengthErrorMessage = String.format(Message.MaxLengthExceededMessage, "Value", (this.question as OpenTextQuestion).validators.maxLength);
    self.triggerQuestionLoaded();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private __minLengthErrorMessage: string;
  private __maxLengthErrorMessage: string;
}
