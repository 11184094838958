var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { QuestionBase } from './question-base';
import { QuestionType } from './question-type.enum';
var OpenQuestionBase = /** @class */ (function (_super) {
    __extends(OpenQuestionBase, _super);
    function OpenQuestionBase(options) {
        var _this = _super.call(this, options) || this;
        _this.validators = {
            required: true,
            pattern: ".*"
        };
        _this.questionType = QuestionType.Single;
        options = options || {};
        options.validators = options.validators || { required: true, pattern: ".*" };
        _this.validators.required = options.validators.required;
        return _this;
    }
    return OpenQuestionBase;
}(QuestionBase));
export { OpenQuestionBase };
