var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CategoryCollection } from './category-base';
import { QuestionBase } from './question-base';
import { QuestionType } from './question-type.enum';
var CategoricalQuestionBase = /** @class */ (function (_super) {
    __extends(CategoricalQuestionBase, _super);
    function CategoricalQuestionBase(options) {
        var _this = _super.call(this, options) || this;
        _this.validators = {
            required: true
        };
        _this.error = "";
        _this.questionType = QuestionType.Single;
        options = options || {};
        _this.categories = new CategoryCollection(options.categories);
        options.validators = options.validators || { required: true };
        _this.validators.required = options.validators.required;
        return _this;
    }
    CategoricalQuestionBase.prototype.getCategory = function (code) {
        var self = this;
        var categories = self.categories.$values.filter(function (c) { return c.code === code; });
        return categories.length === 1 ? categories[0] : null;
    };
    return CategoricalQuestionBase;
}(QuestionBase));
export { CategoricalQuestionBase };
