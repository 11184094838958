import { InjectionToken } from '@angular/core';

import { AnswerRegistryCollection } from '../services/answer-factory.service';
import { CategoryTypeRegistryCollection } from '../services/category-factory.service';
import { CheckProfileResponseRegistryCollection } from '../services/check-profile-response-factory.service';
import { EndingDataTypeRegistryCollection } from '../services/ending-data-factory.service';
import { IconRegistryCollection } from '../services/icon-factory.service';
import { QuestionRegistryCollection } from '../services/question-factory.service';
import { SampleCubeUserTypeRegistryCollection } from '../services/samplecube-user-factory.service';
import { LanguageCountry } from '../enums/language-country-code';
import { CheckQualificationResponseRegistryCollection } from '../services/check-qualification-response-factory.service';
import { EndingPageResponseRegistryCollection } from '../services/ending-page-response-factory.service';

export const API_BASE = new InjectionToken<string>("BaseUrl");
export const LINKED_IN_AUTH = new InjectionToken<string>("BaseUrl");
export const ENV_NAME = new InjectionToken<string>("EnvName");
export const IS_PROD_ENVIRONMENT = new InjectionToken<boolean>("EnvironmentType");
export const APP_INSIGHTS_CONFIG = new InjectionToken<Microsoft.ApplicationInsights.IConfig>("AppInsightsConfig");
export const MBD_PIXEL_URL = new InjectionToken<string>("MBDPixelUrl");

export const ENDING_DATA = new InjectionToken<EndingDataTypeRegistryCollection>("EndingDataTypeRegistry");
export const ICON_MAP = new InjectionToken<IconRegistryCollection>("IconMap");
// export const ENDING_OUTPUT_DATA = new InjectionToken<EndingOutputDataTypeRegistryCollection>("EndingOutputDataTypeRegistry");
export const SAMPLECUBE_USER_TYPES = new InjectionToken<SampleCubeUserTypeRegistryCollection>("SampleCubeUserTypeRegistry");

export const RVID_KEY = new InjectionToken<string>("RvidKey");
export const RVID_LIB = new InjectionToken<string>("RvidLib");
export const RVID_TIMEOUT = new InjectionToken<string>("RvidTimeout");
export const RVID_USE_CACHE = new InjectionToken<string>("RvidCaching");

export const SAMPLE_CHAIN_KEY = new InjectionToken<string>("SampleChainKey");
export const SAMPLE_CHAIN_PRIVATE_KEY = new InjectionToken<string>("SampleChainPrivateKey");
export const SAMPLE_CHAIN_LIB = new InjectionToken<string>("SampleChainLib");
export const SAMPLE_CHAIN_TIMEOUT = new InjectionToken<string>("SampleChainTimeout");
export const SAMPLE_CHAIN_USE_CACHE = new InjectionToken<string>("SampleChainCaching");
export const SAMPLE_CHAIN_API_URL = new InjectionToken<string>("SampleChainApiUrl");

export const CORE_SURVEY_DROP_COOKIE_LIB = new InjectionToken<string>("CoreSurveyDropCookieLib");
export const CORE_SURVEY_DROP_COOKIE_TIMEOUT = new InjectionToken<string>("CoreSurveyDropCookieTimeout");

export const QUESTION_REGISTRY = new InjectionToken<QuestionRegistryCollection>("QuestionRegistry");
export const CATEGORY_REGISTRY = new InjectionToken<CategoryTypeRegistryCollection>("CategoryRegistry");
export const ANSWER_REGISTRY = new InjectionToken<AnswerRegistryCollection>("AnswerRegistry");
export const CHECK_PROFILE_RESPONSE_REGISTRY = new InjectionToken<CheckProfileResponseRegistryCollection>("CheckProfileResponse");
export const MBD_COUNTRY_REGISTRY = new InjectionToken<Array<LanguageCountry>>("MBDCoutryRegistry");
export const CHECK_QUALIFICATION_RESPONSE_REGISTRY = new InjectionToken<CheckQualificationResponseRegistryCollection>("CheckQualificationResponse");
export const ENDING_PAGE_RESPONSE_REGISTRY = new InjectionToken<EndingPageResponseRegistryCollection>("EndingPageResponseRegistry");
export const RFG_FINGERPRINT_LIB = new InjectionToken<string>("RfgFingerPrintLib");
export const RFG_FINGERPRINT_TIMEOUT = new InjectionToken<string>("RfgFingerPrintTimeout");
export const RFG_FINGERPRINT_USE_CACHE = new InjectionToken<string>("RfgFingerPrintCaching");

export const RECONFIRM_CONTACT_TIMEOUT = new InjectionToken<string>("ReconfirmContactTimeout");
export const RECONFIRM_CONTACT_USE_CACHE = new InjectionToken<string>("ReconfirmContactCaching");
export const RECONFIRM_CONTACT_API_GET_URL = new InjectionToken<string>("ReconfirmContactApiGetUrl");
export const RECONFIRM_CONTACT_API_UPDATE_URL = new InjectionToken<string>("ReconfirmContactApiUpdateUrl");
export const RECONFIRM_CONTACT_EMAIL_PATTERN = new InjectionToken<string>("ReconfirmContactEmailPattern");
export const USE_FINGERPRINT_PRO = new InjectionToken<boolean>("UseFingerprintPro");
export const FINGERPRINT_PRO_KEY = new InjectionToken<string>("FingerprintProKey");
export const FP_END_POINT = new InjectionToken<string>("FpEndpoint");
export const DAYS_FOR_DUID_REFRESH = new InjectionToken<number>("DaysForDuidRefresh");
export const COMSCORE_IMAGE_URL = new InjectionToken<string>("ComScoreImageUrl");
export const COMSCORE_TIMEOUT = new InjectionToken<string>("ComScoreTimeout");
