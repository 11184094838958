import { IOpenQuestion, IOpenValidation, OpenQuestionBase } from './core/open-question-base';
import { QuestionType } from './core/question-type.enum';

export interface INumericValidation extends IOpenValidation {
  validators: {
    required: boolean;
    pattern: string;
    min?: number;
    max?: number;
  };
}

export interface INumericQuestion extends IOpenQuestion, INumericValidation {
  validators: {
    required: boolean;
    pattern: string;
    min?: number;
    max?: number;
  };
}

export class NumericQuestion extends OpenQuestionBase implements INumericQuestion {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.NumericQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  validators: {
    required: boolean;
    pattern: string;
    min?: number;
    max?: number;
  } = {
    required: true,
    pattern: "[0-9]*"
  };
  constructor(options?: INumericQuestion) {
    super(options as IOpenQuestion);

    options = options || ({} as INumericQuestion);
    this.questionType = QuestionType.Numeric;
    options.validators = options.validators || { required: true, pattern: "[0-9]*" };
    this.validators.min = options.validators.min;
    this.validators.max = options.validators.max;
    this.validators.pattern = options.validators.pattern;
    this.validators.required = options.validators.required;
  }
}
