import { CategoryCollection } from './category-base';
import { IQuestion, QuestionBase } from './question-base';
import { QuestionType } from './question-type.enum';
import { Category } from '../category';

export interface ICategoricalValidation extends IQuestion {
  validators: {
    required: boolean;
  };
}

export interface ICategoricalQuestion extends ICategoricalValidation {
  categories?: CategoryCollection;
  validators: {
    required: boolean;
  };
}

export abstract class CategoricalQuestionBase extends QuestionBase implements ICategoricalQuestion {
  validators: {
    required: boolean;
  } = {
      required: true
    };
  categories: CategoryCollection;
  error: string = "";
  constructor(options?: ICategoricalQuestion) {
    super(options as IQuestion);

    this.questionType = QuestionType.Single;

    options = options || ({} as ICategoricalQuestion);

    this.categories = new CategoryCollection(options.categories);
    options.validators = options.validators || { required: true };
    this.validators.required = options.validators.required;
  }

  getCategory(code: number): Category {
    const self = this;
    const categories = self.categories.$values.filter(c => c.code === code);
    return categories.length === 1 ? categories[0] as Category : null;
  }
  
}
