import { CategoryType } from './models/survey-engine/core/category-type.enum';
import { Category } from "./models/survey-engine/category";
import { CategoryTypeRegistryCollection } from "./services/category-factory.service";
var ɵ0 = function () {
    return new CategoryTypeRegistryCollection([
        {
            key: CategoryType[CategoryType.Category],
            type: Category
        }
    ]);
};
var CategoryRegistryModule = /** @class */ (function () {
    function CategoryRegistryModule() {
    }
    return CategoryRegistryModule;
}());
export { CategoryRegistryModule };
export { ɵ0 };
