String.format = function (input) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (!!!input || "string" !== typeof input || !!!args || !!!args.length) {
        return null;
    }
    var output = null;
    var pattern = new RegExp(/\{[0-9]+\}/gim);
    var results = input.match(pattern);
    if (!!results && !!results.length) {
        output = input;
        results.forEach(function (match, index) {
            output = output.replace(match, args[index].toString());
        });
    }
    return output;
};
