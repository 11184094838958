var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { OpenQuestionBaseComponent } from "../core/open-end-question-base.component";
import "../../../../extensions/string-extensions";
import { Message } from "../../../../localization/message";
var NumericQuestionComponent = /** @class */ (function (_super) {
    __extends(NumericQuestionComponent, _super);
    function NumericQuestionComponent() {
        return _super.call(this) || this;
    }
    Object.defineProperty(NumericQuestionComponent.prototype, "minValueErrorMessage", {
        get: function () {
            return this.__minValueErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NumericQuestionComponent.prototype, "maxValueErrorMessage", {
        get: function () {
            return this.__maxValueErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    NumericQuestionComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        var self = this;
        var validators = [];
        if (self.question.validators.required) {
            validators.push(Validators.required);
        }
        Object.keys(self.question.validators).forEach(function (validator) {
            if (validator !== "required" && self.question.validators[validator]) {
                if (validator === "pattern") {
                    validators.push(Validators[validator].apply(undefined, [new RegExp(self.question.validators["pattern"], "i")]));
                }
                else {
                    validators.push(Validators[validator].apply(undefined, [self.question.validators[validator]]));
                }
            }
        });
        self.ctrl.setValidators(validators);
        self.__minValueErrorMessage = String.format(Message.MinValueExceededMessage, "Value", this.question.validators.min);
        self.__maxValueErrorMessage = String.format(Message.MaxValueExceededMessage, "Value", this.question.validators.max);
        if (self.question.response && self.question.response.value) {
            var response = self.question.response.value;
            self.ctrl.setValue(response);
        }
        self.triggerQuestionLoaded();
    };
    NumericQuestionComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return NumericQuestionComponent;
}(OpenQuestionBaseComponent));
export { NumericQuestionComponent };
