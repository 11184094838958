import { Component, OnDestroy, OnInit } from "@angular/core";
import { Validators, FormControl } from "@angular/forms";
import { MatRadioButton } from "@angular/material";
import { Category } from "../../../../models/survey-engine/category";
import { SingleImageAnswer } from "../../../../models/survey-engine/single-image-answer";
import { SingleQuestion } from "../../../../models/survey-engine/single-question";
import "../../../../extensions/string-extensions";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";
import { SingleImageQuestion, ISingleImageQuestionData } from "../../../../models/survey-engine/single-image-question";
import { CategoricalQuestionBaseComponent } from "../core/categorical-question-base.component";

@Component({
  selector: "app-single-image-question",
  templateUrl: "./single-image-question.component.html",
  styleUrls: ["./single-image-question.component.scss"]
})
export class SingleImageQuestionComponent extends CategoricalQuestionBaseComponent implements OnInit, OnDestroy {
  protected setQuestionAnswer(value: any) {
    const self = this;
    self.__questionAnswer = new SingleImageAnswer({
      value: self.getCategory(value).id
    });
    self.__questionAnswer.answerType = AnswerType.SinglePunchImage;
  }
  get categories(): Array<Category> {
    const categories: Array<Category> = [];
    (this.question as SingleImageQuestion).categories.$values.forEach(c => {
      categories.push(c as Category);
    });
    console.log(categories);
    return categories;
  }

  constructor() {
    super();
  }
  inputData: Array<ISingleImageQuestionData> = [];
  ngOnInit() {
    super.ngOnInit();
    const self = this;

    const validators = [];
    if ((self.question as SingleQuestion).validators.required) {
      validators.push(Validators.required);
    }

    Object.keys((self.question as SingleQuestion).validators).forEach(validator => {
      if (validator !== "required" && (self.question as SingleQuestion).validators[validator]) {
        validators.push(Validators[validator].apply(undefined, [(self.question as SingleQuestion).validators[validator]]));
      }
    });

    self.ctrl.setValidators(validators);
    if (self.question.response && (self.question.response as SingleImageAnswer).value) {
      const category = self.getCategoryById((self.question.response as SingleImageAnswer).value);
      if (category && category.code) {
        self.ctrl.setValue(category.code);
      }
    }
    self.triggerQuestionLoaded();

    (this.question as SingleImageQuestion).categories.$values.forEach(c => {
      this.inputData.push({
        imageName: c.rightText,
        imagePath: c.leftText,
        imageSelected: false,
        questionId: c.questionId,
        categoryId: c.id,
        code: c.code
      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  get getFormControlName(): FormControl {
    try {
      var radionbuttonData: any = document.getElementsByClassName("mat-radio-group singleImage");
      if (!!radionbuttonData) {
        for (var i = 0; i < radionbuttonData.length; i++) {
          var filterdata=radionbuttonData[i];
          var singleImageData: any = filterdata;//.getElementsByClassName("singleImage");
          if (!!singleImageData) {
            for (var j = 0; j < singleImageData.getElementsByClassName("mat-radio-container").length; j++) {
              var gg: any = singleImageData.getElementsByClassName("mat-radio-container")[j];
              gg.style.display = 'none'
            }
          }
        }
      }
    } catch (ex) {

    }
    return new FormControl();
  }

  categoryChanged(option: MatRadioButton, i): void {
  }

  public fixImageSelectedId(category: any) {
    const self = this;
    let selectedImageData = self.inputData.filter(k => k.imageSelected === true);
    if (!!selectedImageData && selectedImageData.length > 0) {
      self.imageId = 0;
      selectedImageData.forEach(element => {
        element.imageSelected = false;
      });
    }
    if (category.imageSelected == false) {
      self.imageId = category.code;
    }
    category.imageSelected = !category.imageSelected;
  }

  public imageId = 0;
}
