import { NgModule } from '@angular/core';

import { environment } from '../environments/environment';
import { RFG_FINGERPRINT_LIB ,RFG_FINGERPRINT_TIMEOUT,RFG_FINGERPRINT_USE_CACHE} from './core/globals';

@NgModule({
  providers: [
    {
      provide: RFG_FINGERPRINT_LIB,
      useValue: environment.rfgFingerPrint.lib
	},
	{
		provide: RFG_FINGERPRINT_TIMEOUT,
		useValue: environment.rfgFingerPrint.timeout
	  },
	  {
		provide: RFG_FINGERPRINT_USE_CACHE,
		useValue: environment.rfgFingerPrint.cache
	  }
  ]
})
export class RFGFingerPrintConfigModule {}
