import { OnDestroy, OnInit } from "@angular/core";
import { OpenAnswer } from "../../../../models/survey-engine/open-answer";

import { QuestionBaseComponent } from "./question-base.component";
import { Message } from "../../../../localization/message";

import "../../../../extensions/string-extensions";
import { RandomStringService } from "../../../../services/random-string.service";
import { OpenQuestionBase } from "../../../../models/survey-engine/core/open-question-base";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";

export abstract class OpenQuestionBaseComponent extends QuestionBaseComponent implements OnInit, OnDestroy {
  protected setQuestionAnswer(value: any) {
    const self = this;
    self.__questionAnswer = new OpenAnswer({
      value: value
    });
    self.__questionAnswer.answerType = AnswerType.Open;
  }

  get example(): string {
    const self = this;
    return self._example;
  }

  get patternErrorMessage(): string {
    return this.__patternErrorMessage;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const self = this;
    const question_validators = (self.question as OpenQuestionBase).validators;
    if (self.question.response && (self.question.response as OpenAnswer).value) {
      self.ctrl.setValue((self.question.response as OpenAnswer).value);
    }

    if (question_validators.pattern) {
      const stringGenerator = new RandomStringService(question_validators.pattern);
      self._example = stringGenerator.generate();
    }

    self.__patternErrorMessage = String.format(Message.InvalidFormatMessage, this.example);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected _example: string = "";
  protected __patternErrorMessage: string = "";
}
