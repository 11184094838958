import {
  SampleCubeUserTypeRegistryCollection,
  ISampleCubeUserTypeRegistry
} from "../services/samplecube-user-factory.service";
import { SAMPLECUBE_USER_TYPES } from "../core/globals";
import { InjectorInstance } from "../core/injector-instance";

export abstract class SampleCubeUserBase {
  abstract $type: string = "";
  isTest: boolean = false;
  userId: number = 0;
  userMainId: number = 0;
  languageId: number = 0;
  identificatinoCode: number = 0;
  age: number = 0;
  deviceId: number = 0;
  mob: number = 0;
  yob: number = 0;
  ipNumber: number = 0;
  duidHashCode: number = 0;
  vendorUserIdHashCode: number = 0;
  originalVendorId: number = 0;
  originalLanguageId: number = 0;
  visitedCount: number = 0;
  vendorId: number = 0;
  ipAddress: string = "";
  gender: string = "";
  zipCode: string = "";
  duid: string = "";
  vendorUserId: string = "";
  profileCreationDate: Date = new Date();
  updateTimeStamp: Date = new Date();
  isActive: boolean = false;
  isBeacon: boolean = false;
  emailHash:string="";
  qualityScore: number = 0;

  static instanceFrom(
    options:
      | {
          [name: string]: string | number | boolean;
        }
      | any
  ): SampleCubeUserBase {
    if (!options) {
      return null;
    }
    let instance: SampleCubeUserBase = null;

    if (!!!this.__registeredTypes) {
      this.__registeredTypes = InjectorInstance.get<
        SampleCubeUserTypeRegistryCollection
      >(SAMPLECUBE_USER_TYPES);
    }
    instance = new (this.__registeredTypes.resolve("SampleCubeUser")).type();
    for (const p in options) {
      if (options.hasOwnProperty(p)) {
        if (instance.hasOwnProperty(p)) {
          instance[p] = options[p];
        }
      }
    }
    this.__registeredTypes.forEach(
      (registeredType: ISampleCubeUserTypeRegistry) => {
        const concrete: SampleCubeUserBase = new registeredType.type();
        concrete.handleLogic(options);
        if (concrete.logicHandled) {
          instance = concrete;
          return;
        }
      }
    );
    instance.isTest = (options["chktestlink"] || "false") === "true";
    instance.languageId = (!options["lid"]) ? 0 : options["lid"] as number;
    instance.qualityScore = (!options["qscore"]) ? 0 : options["qscore"] as number;
    return instance;
  }
  protected abstract handleLogic(
    options:
      | {
          [name: string]: string | number | boolean;
        }
      | any
  );

  protected logicHandled: boolean = false;

  private static __registeredTypes: SampleCubeUserTypeRegistryCollection;
}
export class SampleCubeUser extends SampleCubeUserBase {
  $type: string =
    "SampleCube.UserFlow.API.Models.SampleCubeUserApiModel, SampleCube.UserFlow.API.Models";

  protected handleLogic(
    options:
      | {
          [name: string]: string | number | boolean;
        }
      | any
  ) {}
}
