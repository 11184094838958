import { AnswerType } from './models/survey-engine/core/answer-type.enum';
import { MultiAnswer } from './models/survey-engine/multi-answer';
import { OpenAnswer } from './models/survey-engine/open-answer';
import { SingleAnswer } from './models/survey-engine/single-answer';
import { AnswerRegistryCollection } from './services/answer-factory.service';
var ɵ0 = function () {
    return new AnswerRegistryCollection([
        {
            key: AnswerType[AnswerType.Single],
            type: SingleAnswer
        },
        {
            key: AnswerType[AnswerType.Multi],
            type: MultiAnswer
        },
        {
            key: AnswerType[AnswerType.Open],
            type: OpenAnswer
        }
    ]);
};
var AnswerRegistryModule = /** @class */ (function () {
    function AnswerRegistryModule() {
    }
    return AnswerRegistryModule;
}());
export { AnswerRegistryModule };
export { ɵ0 };
