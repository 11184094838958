import { InjectorInstance } from '../../../core/injector-instance';
import { CategoryFactoryService } from '../../../services/category-factory.service';
import { CategoryType } from './category-type.enum';

export interface ICategoryBase {
  $type?: string;
  id?: number;
  name?: string;
  categoryType?: CategoryType;

  rightText?: string;
  leftText?: string;
  isOther?: boolean;
  isExclusive?: boolean;
  code?: number;
  questionId?: number;
}

export abstract class CategoryBase implements ICategoryBase {
  abstract $type: string = "";
  id: number = 0;
  name: string = "";
  categoryType: CategoryType = CategoryType.Category;
  constructor(options?: ICategoryBase) {
    options = options || ({} as ICategoryBase);
    this.id = options.id || 0;
    this.name = options.name || "";
    this.categoryType = options.categoryType;
  }
}

export interface ICategoryCollection {
  $type?: string;
  $values?: Array<ICategoryBase>;
}

export class CategoryCollection implements ICategoryCollection {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.CategoryCollectionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  $values: Array<ICategoryBase> = [];

  constructor(options?: Array<ICategoryBase> | ICategoryCollection) {
    const __categoryFactoryService = InjectorInstance.get<CategoryFactoryService>(CategoryFactoryService);
    const self = this;
    let array: Array<ICategoryBase> = null;
    if (!!options && !!(options as ICategoryCollection).$values) {
      array = (options as ICategoryCollection).$values || [];
    } else {
      array = (options as Array<ICategoryBase>) || [];
    }

    if (array && array.length > 0) {
      array.forEach(item => {
        if (!(item instanceof CategoryBase)) {
          item = __categoryFactoryService.createInstance(item);
        }
        self.$values.push(item as CategoryBase);
      });
    }
  }
}
