import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TopLoaderState } from '../interfaces/top-loader-state';

@Injectable({
  providedIn: 'root'
})
export class TopLoaderService {
  private loaderSubject = new Subject<TopLoaderState>();

  loaderState = this.loaderSubject.asObservable();

  constructor() { }

  show() {
    this.loaderSubject.next(<TopLoaderState>{ show: true });
  }

  hide() {
    this.loaderSubject.next(<TopLoaderState>{ show: false });
  }
}
