import { NgModule } from '@angular/core';

import { ENDING_PAGE_RESPONSE_REGISTRY } from '../../core/globals';
import { SetStatusAfterCompleteOutputData } from '../../models/set-status-after-complete-output-data';
import { SetStatusRedirectOutputData } from '../../models/set-status-redirect-output-data';
import { SetStatusRoutedOutputData } from '../../models/set-status-routed-output-data';
import { SetStatusSuccessOutputData } from '../../models/set-status-success-output-data';
import { EndingPageResponseRegistryCollection } from '../../services/ending-page-response-factory.service';
import { SetStatusResponseType } from '../../enums/ending-page-response-type.enum';
import { SetStatusAfterCompleteReconfirmContactOutputData } from '../../models/set-status-after-complete-reconfirm-contact-output-data';
import { SetStatusAfterCompleteConsentOutputData } from '../../models/set-status-after-complete-consent-output-data';

@NgModule({
  providers: [
    {
      provide: ENDING_PAGE_RESPONSE_REGISTRY,
      useFactory: function () {
        return new EndingPageResponseRegistryCollection([
          {
            key: SetStatusResponseType[SetStatusResponseType.Redirect],
            type: SetStatusRedirectOutputData
          },
          {
            key: SetStatusResponseType[SetStatusResponseType.AfterComplete],
            type: SetStatusAfterCompleteOutputData
          },
          {
            key: SetStatusResponseType[SetStatusResponseType.Routed],
            type: SetStatusRoutedOutputData
          },
          {
            key: SetStatusResponseType[SetStatusResponseType.Success],
            type: SetStatusSuccessOutputData
          },
          {
            key: SetStatusResponseType[SetStatusResponseType.AfterCompleteReconfirmContact],
            type: SetStatusAfterCompleteReconfirmContactOutputData
          },
          {
            key: SetStatusResponseType[SetStatusResponseType.AfterCompleteConsent],
            type: SetStatusAfterCompleteConsentOutputData
          }
        ]);
      }
    }
  ]
})
export class EndingOutputDataModule { }
