import { IOpenQuestion, IOpenValidation, OpenQuestionBase } from './core/open-question-base';
import { QuestionType } from './core/question-type.enum';

export interface ICalendarValidation extends IOpenValidation {
  validators: {
    required: boolean;
    pattern: string;
    minDate?: number;
    maxDate?: number;
  };
}

export interface ICalendarQuestion extends ICalendarValidation {
  validators: {
    required: boolean;
    pattern: string;
    minDate?: number;
    maxDate?: number;
  };
}

export class CalendarQuestion extends OpenQuestionBase implements ICalendarQuestion {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.CalendarQuestionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  validators: {
    required: boolean;
    pattern: string;
    minDate?: number;
    maxDate?: number;
  } = (function(year: number, month: number) {
    return {
      required: true,
      pattern: "0([1-9]{1})|1([0-2]{1})\\/(19|20|21)[0-9]{2}",
      minDate: Date.UTC(year - 100, month),
      maxDate: Date.UTC(year, month)
    };
  })(new Date().getUTCFullYear(), new Date().getUTCMonth());

  constructor(options?: ICalendarQuestion) {
    super(options as IOpenQuestion);

    options = options || ({} as ICalendarQuestion);
    this.questionType = QuestionType.Calendar;
    options.validators = options.validators || { required: true, pattern: "0([1-9]{1})|1([0-2]{1})\\/(19|20|21)[0-9]{2}" };
    this.validators.minDate = options.validators.minDate;
    this.validators.maxDate = options.validators.maxDate;
  }
}
