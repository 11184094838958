import { NgModule } from "@angular/core";
import { MBD_COUNTRY_REGISTRY } from "./core/globals";
import { LanguageCountry } from "./enums/language-country-code";

@NgModule({
  providers: [
    {
      provide: MBD_COUNTRY_REGISTRY,
      useFactory: function() {
        return [
          LanguageCountry.US,
          LanguageCountry.CA,
          LanguageCountry.GB,
          LanguageCountry.AU,
          LanguageCountry.IN,
          LanguageCountry.JP,
          LanguageCountry.KR,
          LanguageCountry.BR,
          LanguageCountry.DE,
          LanguageCountry.FR,
          LanguageCountry.ES,
          LanguageCountry.AR,
          LanguageCountry.MX,
          LanguageCountry.IT,
          LanguageCountry.CN
        ];
      }
    }
  ]
})
export class MBDCountryRegistryModule {}
