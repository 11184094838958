import { ProfileResponseType } from './models/profile/profile-response-type.enum';
import { RedirectProfileResponse } from './models/profile/redirect-profile-response';
import { RoutedProfileResponse } from './models/profile/routed-profile-response';
import { TargetedProfileResponse } from './models/profile/targeted-profile-response';
import { CheckProfileResponseRegistryCollection } from './services/check-profile-response-factory.service';
var ɵ0 = function () {
    return new CheckProfileResponseRegistryCollection([
        {
            key: ProfileResponseType[ProfileResponseType.Targeted],
            type: TargetedProfileResponse
        },
        {
            key: ProfileResponseType[ProfileResponseType.Redirect],
            type: RedirectProfileResponse
        },
        {
            key: ProfileResponseType[ProfileResponseType.Routed],
            type: RoutedProfileResponse
        }
    ]);
};
var CheckProfileResponseRegistryModule = /** @class */ (function () {
    function CheckProfileResponseRegistryModule() {
    }
    return CheckProfileResponseRegistryModule;
}());
export { CheckProfileResponseRegistryModule };
export { ɵ0 };
