var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from "@angular/core";
import { Validators, FormControl } from "@angular/forms";
import { SingleImageAnswer } from "../../../../models/survey-engine/single-image-answer";
import "../../../../extensions/string-extensions";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";
import { CategoricalQuestionBaseComponent } from "../core/categorical-question-base.component";
var SingleImageQuestionComponent = /** @class */ (function (_super) {
    __extends(SingleImageQuestionComponent, _super);
    function SingleImageQuestionComponent() {
        var _this = _super.call(this) || this;
        _this.inputData = [];
        _this.imageId = 0;
        return _this;
    }
    SingleImageQuestionComponent.prototype.setQuestionAnswer = function (value) {
        var self = this;
        self.__questionAnswer = new SingleImageAnswer({
            value: self.getCategory(value).id
        });
        self.__questionAnswer.answerType = AnswerType.SinglePunchImage;
    };
    Object.defineProperty(SingleImageQuestionComponent.prototype, "categories", {
        get: function () {
            var categories = [];
            this.question.categories.$values.forEach(function (c) {
                categories.push(c);
            });
            console.log(categories);
            return categories;
        },
        enumerable: true,
        configurable: true
    });
    SingleImageQuestionComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        var self = this;
        var validators = [];
        if (self.question.validators.required) {
            validators.push(Validators.required);
        }
        Object.keys(self.question.validators).forEach(function (validator) {
            if (validator !== "required" && self.question.validators[validator]) {
                validators.push(Validators[validator].apply(undefined, [self.question.validators[validator]]));
            }
        });
        self.ctrl.setValidators(validators);
        if (self.question.response && self.question.response.value) {
            var category = self.getCategoryById(self.question.response.value);
            if (category && category.code) {
                self.ctrl.setValue(category.code);
            }
        }
        self.triggerQuestionLoaded();
        this.question.categories.$values.forEach(function (c) {
            _this.inputData.push({
                imageName: c.rightText,
                imagePath: c.leftText,
                imageSelected: false,
                questionId: c.questionId,
                categoryId: c.id,
                code: c.code
            });
        });
    };
    SingleImageQuestionComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    Object.defineProperty(SingleImageQuestionComponent.prototype, "getFormControlName", {
        get: function () {
            try {
                var radionbuttonData = document.getElementsByClassName("mat-radio-group singleImage");
                if (!!radionbuttonData) {
                    for (var i = 0; i < radionbuttonData.length; i++) {
                        var filterdata = radionbuttonData[i];
                        var singleImageData = filterdata; //.getElementsByClassName("singleImage");
                        if (!!singleImageData) {
                            for (var j = 0; j < singleImageData.getElementsByClassName("mat-radio-container").length; j++) {
                                var gg = singleImageData.getElementsByClassName("mat-radio-container")[j];
                                gg.style.display = 'none';
                            }
                        }
                    }
                }
            }
            catch (ex) {
            }
            return new FormControl();
        },
        enumerable: true,
        configurable: true
    });
    SingleImageQuestionComponent.prototype.categoryChanged = function (option, i) {
    };
    SingleImageQuestionComponent.prototype.fixImageSelectedId = function (category) {
        var self = this;
        var selectedImageData = self.inputData.filter(function (k) { return k.imageSelected === true; });
        if (!!selectedImageData && selectedImageData.length > 0) {
            self.imageId = 0;
            selectedImageData.forEach(function (element) {
                element.imageSelected = false;
            });
        }
        if (category.imageSelected == false) {
            self.imageId = category.code;
        }
        category.imageSelected = !category.imageSelected;
    };
    return SingleImageQuestionComponent;
}(CategoricalQuestionBaseComponent));
export { SingleImageQuestionComponent };
