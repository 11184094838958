import '../../../../extensions/string-extensions';

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDatepicker, DateAdapter } from '@angular/material';
import { Moment } from 'moment';
import * as moment from 'moment';

import { Message } from '../../../../localization/message';
import { CalendarQuestion } from '../../../../models/survey-engine/calendar-question';
import { OpenAnswer } from '../../../../models/survey-engine/open-answer';
import { OpenQuestionBaseComponent } from '../core/open-end-question-base.component';

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY"
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: "app-calendar-question",
  templateUrl: "./calendar-question.component.html",
  styleUrls: ["./calendar-question.component.scss"],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class CalendarQuestionComponent extends OpenQuestionBaseComponent implements OnInit, OnDestroy {
  @ViewChild(MatDatepicker)
  picker: MatDatepicker<Moment>;

  get minDate(): string {
    return moment(this._minDate).format("MM/YYYY");
  }

  get maxDate(): string {
    return moment(this._maxDate).format("MM/YYYY");
  }

  get minDateErrorMessage(): string {
    return this.__minDateErrorMessage;
  }

  get maxDateErrorMessage(): string {
    return this.__maxDateErrorMessage;
  }

  constructor(	private dateAdapter: DateAdapter<Date>) {
    super();
  }

  setLocale(val){
    this.dateAdapter.setLocale(val); 
  }

  ngOnInit() {
    super.ngOnInit();
    const self = this;
    // self.dateAdapter.setLocale("zh");
    const question_validators = (self.question as CalendarQuestion).validators;
    const validators = [];
    if (question_validators.required) {
      validators.push(Validators.required);
    }
    if (question_validators.minDate) {
      self._minDate = question_validators.minDate;
    }
    if (question_validators.maxDate) {
      self._maxDate = question_validators.maxDate;
    }

    Object.keys(question_validators).forEach(validator => {
      if (validator !== "required" && question_validators[validator] && CustomCalendarValidators[validator]) {
        validators.push(CustomCalendarValidators[validator].apply(undefined, [question_validators[validator]]));
      }
    });

    self.ctrl.setValidators(validators);

    self.ctrl.statusChanges.subscribe(status => {
      if (status === "INVALID") {
        self.picker.startAt = moment();
      } else {
        self.picker.startAt = self.picker._selected;
      }
    });
    self.__minDateErrorMessage = String.format(Message.MinDateExceededMessage, "Value", this.minDate);
    self.__maxDateErrorMessage = String.format(Message.MaxDateExceededMessage, "Value", this.maxDate);
  }

  ngAfterViewInit() {
    const self = this;
    if (self.question.response && (self.question.response as OpenAnswer).value) {
      let response = (self.question.response as OpenAnswer).value;
      let dateParts = response.split("/");
      if (dateParts.length === 2) {
        dateParts.splice(1, 0, "01");
        response = dateParts.join("/");
      }
      setTimeout(() => {
        const d: Moment = moment(new Date(response));
        self.picker.startAt = d;
        self.picker._select(d);
        self.triggerQuestionLoaded();
      }, 100);
    } else {
      self.triggerQuestionLoaded();
      self.picker.startAt = moment();
    }
  }

  openPicker($event: Event) {
    const opened: boolean = this.picker.opened;
    if (!opened) {
      this.picker.open();
      $event.preventDefault();
    }
  }

  chosenMonthHandler(newDate: Moment) {
    this.picker._select(newDate);
    this.picker.startAt = newDate;
    this.picker.close();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private _minDate: number = 0;
  private _maxDate: number = 0;

  private __minDateErrorMessage: string;
  private __maxDateErrorMessage: string;
}

class CustomCalendarValidators {
  static minDate(nr: number): ValidatorFn {
    return (fc: FormControl): { [key: string]: boolean } | null => {
      const m_value: Moment = fc.value;
      const value: number = m_value ? m_value.valueOf() : null;
      return !!value && value >= nr
        ? null
        : {
            minDate: true
          };
    };
  }
  static maxDate(nr: number): ValidatorFn {
    return (fc: FormControl): { [key: string]: boolean } | null => {
      const m_value: Moment = fc.value;
      const value: number = m_value ? m_value.valueOf() : null;
      return !!value && value <= nr
        ? null
        : {
            maxDate: true
          };
    };
  }
}
