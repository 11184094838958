export interface IException {
    message: string;
    details?: string;
    code?: number;
    data?: IApiError;
}
export class Exception extends Error implements IException {
    message: string;
    details?: string = null;
    code?: number = 0;
    data?: IApiError = null;

    constructor(options: IException = { message: "Unknown Error" }) {
        super(options.message);
        // restore prototype chain   
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) { Object.setPrototypeOf(this, actualProto); }
        else { this.__proto__ = actualProto; }
        this.code = options.code || this.code;
        this.data = options.data || this.data;
        this.code = !!this.data && !!this.data.errorCode ? this.data.errorCode : this.code;
        this.message = !!this.data && !!this.data.message ? this.data.message : this.message;
        this.details = !!this.data && !!this.data.details ? this.data.details : this.details;
    }

    private __proto__: any;
}

export interface IApiError {
    message?: string;
    details?: string;
    errorCode?: number;
    debugData?: any;
}
export class ApiError implements IApiError {
    message?: string = "";
    details?: string = "";
    errorCode?: number = 0;
    debugData?: any = null;

    constructor(options: IApiError = {}) {
        this.message = options.message || "";
        this.details = options.details || "";
        this.errorCode = options.errorCode || 0;
        this.debugData = options.debugData || null;
    }
}
export function isApiError(data: ApiError | any): data is ApiError {
    if (!!data) {
        return true;
    }
    else {
        return (<ApiError>data).details !== undefined
            && (<ApiError>data).errorCode !== undefined;
    }
}
