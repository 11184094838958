import { EndingInputDataBase } from "./ending";
import { IEndingOutputBase, EndingOutputBase } from "./ending-output-base";
import { URLType } from "../enums/url-type";

export interface IAfterCompleteReconfirmContact extends  IEndingOutputBase{
  redirectUrl: string;
  urlType:URLType;
}

export class SetStatusAfterCompleteReconfirmContactOutputData extends EndingOutputBase implements IAfterCompleteReconfirmContact {
  $type: string =
    "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusAfterCompleteReconfirmContactResponseApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
  redirectUrl: string;
  urlType:URLType;
  constructor(options?: IAfterCompleteReconfirmContact) {
    super(options as IEndingOutputBase);
    options = options || ({} as IAfterCompleteReconfirmContact);
    this.redirectUrl = options.redirectUrl || "";
    this.urlType=options.urlType || URLType.None;
  }
}
