var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CheckQualificationResponseBase } from './check-qualification-response-base';
import { PageCollection } from './page';
var CheckQualificationRoutedResponse = /** @class */ (function (_super) {
    __extends(CheckQualificationRoutedResponse, _super);
    function CheckQualificationRoutedResponse(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.SurveyPage.DTOs.CheckQualificationRoutedResponseApiDTO, SampleCube.UserFlow.API.Models.SurveyPage";
        _this.isPIDRouting = false;
        _this.isRecontact = false;
        _this.originalUserId = 0;
        _this.sampleChainDestinationPlatformId = "";
        _this.sampleChainDestinationSurveyNumber = "";
        _this.verifyRFGFingerPrint = false;
        options = options || {};
        _this.pages = new PageCollection(options.pages);
        _this.respondentId = options.respondentId;
        _this.respondentToken = options.respondentToken;
        _this.surveyId = options.surveyId;
        _this.surveyToken = options.surveyToken;
        _this.isPLRouterEnabled = options.isPLRouterEnabled;
        _this.isPollfishEnabled = options.isPollfishEnabled;
        _this.clientInvokeUrl = options.clientInvokeUrl;
        _this.isSampleChainEnabled = options.isSampleChainEnabled;
        _this.verifyRelevantId = options.verifyRelevantId;
        _this.isPIDRouting = options.isPIDRouting;
        _this.isRecontact = options.isRecontact;
        _this.originalUserId = options.originalUserId || 0;
        _this.sampleChainDestinationPlatformId = options.sampleChainDestinationPlatformId || "";
        _this.sampleChainDestinationSurveyNumber = options.sampleChainDestinationSurveyNumber || "";
        _this.verifyRFGFingerPrint = options.verifyRFGFingerPrint;
        return _this;
    }
    return CheckQualificationRoutedResponse;
}(CheckQualificationResponseBase));
export { CheckQualificationRoutedResponse };
