import { InjectorInstance } from '../../../core/injector-instance';
import { IValidation } from '../../../interfaces/validation';
import { QuestionFactoryService } from '../../../services/question-factory.service';
import { AnswerType } from './answer-type.enum';
import { ICategoryCollection } from './category-base';
import { QuestionAnswerBase } from './question-answer-base';
import { QuestionType } from './question-type.enum';

export interface IQuestion extends IValidation {
  $type?: string;
  title?: string;
  name?: string;
  id?: number;
  text?: string;
  questionType?: QuestionType;
  toggleText?: string;
  categories?: ICategoryCollection;
  minLength?: number;
  maxLength?: number;
  validators: {
    min?: number;
    max?: number;
    required: boolean;
    minAnswers?: number;
    maxAnswers?: number;
    minLength?: number;
    maxLength?: number;
    pattern?: string;
    minDate?: number;
    maxDate?: number;
  };

  response?: QuestionAnswerBase;
  answerType?: AnswerType;
}
export abstract class QuestionBase implements IQuestion {
  abstract $type: string = "";
  validators: {
    required: boolean;
  } = {
      required: true
    };
  response?: QuestionAnswerBase;
  title: string = "";
  name: string = "";
  id: number = 0;
  text: string = "";
  questionType: QuestionType = QuestionType.Info;
  answerType: AnswerType = AnswerType.Open;
  toggleText: string = "More details";
  constructor(options?: IQuestion) {
    options = options || ({} as IQuestion);
    this.id = options.id || 0;
    this.title = options.title || "";
    this.name = options.name || "";
    this.text = options.text || "";
    this.questionType = options.questionType || 0;
    this.answerType = options.answerType || 0;
    this.toggleText =  options.toggleText || "More details";
    if (options.response) {
      this.response = QuestionAnswerBase.createInstance(options.response);
    }
  }

  public static createInstance(options?: IQuestion) {
    const questionFactoryService = InjectorInstance.get<QuestionFactoryService>(QuestionFactoryService);
    return questionFactoryService.createInstance(options);
  }
}

export interface IQuestionCollection {
  $type?: string;
  $values?: Array<IQuestion>;
}

export class QuestionCollection implements IQuestionCollection {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.QuestionCollectionApiModel, SampleCube.UserFlow.API.Models.SurveyPage";
  $values: Array<IQuestion> = [];

  constructor(options?: Array<IQuestion> | IQuestionCollection) {
    const self = this;
    let array: Array<IQuestion> = null;
    if (!!options && !!(options as IQuestionCollection).$values) {
      array = (options as IQuestionCollection).$values || [];
    } else {
      array = (options as Array<IQuestion>) || [];
    }
    if (array && array.length > 0) {
      array.forEach(item => {
        if (!(item instanceof QuestionBase)) {
          item = QuestionBase.createInstance(item);
        }
        this.$values.push(item as QuestionBase);
      });
    }
  }
}
