import { NgModule } from '@angular/core';

import { CHECK_QUALIFICATION_RESPONSE_REGISTRY } from './core/globals';
import { CheckQualificationResponseType } from './enums/check-qualification-response-type.enum';
import { CheckQualificationClientResponse } from './models/survey-engine/check-qualification-client-response-dto';
import { CheckQualificationSuccessResponse } from './models/survey-engine/check-qualification-success-response-dto';
import { CheckQualificationRedirectResponse } from './models/survey-engine/check-qualification-redirect-response-dto';
import { CheckQualificationResponseRegistryCollection } from './services/check-qualification-response-factory.service';
import { CheckQualificationLinkedInResponse } from './models/survey-engine/check-qualification-linkedIn-response-dto';
import { CheckQualificationRoutedResponse } from './models/survey-engine/check-qualification-routed-response-dto';
import { CheckQualificationMBDGoogleResponse } from './models/survey-engine/check-qualification-mbd-google-response-dto';

@NgModule({
  providers: [
    {
      provide: CHECK_QUALIFICATION_RESPONSE_REGISTRY,
      useFactory: function() {
        return new CheckQualificationResponseRegistryCollection([
          {
            key: CheckQualificationResponseType[CheckQualificationResponseType.Client],
            type: CheckQualificationClientResponse
          },
          {
            key: CheckQualificationResponseType[CheckQualificationResponseType.Redirect],
            type: CheckQualificationRedirectResponse
          },
          {
            key: CheckQualificationResponseType[CheckQualificationResponseType.Success],
            type: CheckQualificationSuccessResponse
          },
          {
            key: CheckQualificationResponseType[CheckQualificationResponseType.LinkedIn],
            type: CheckQualificationLinkedInResponse
          },
          {
            key: CheckQualificationResponseType[CheckQualificationResponseType.Routed],
            type: CheckQualificationRoutedResponse
          },
          {
            key: CheckQualificationResponseType[CheckQualificationResponseType.MBDGoogleStart],
            type: CheckQualificationMBDGoogleResponse
          }
        ]);
      }
    }
  ]
})
export class CheckQualificationResponseRegistryModule {}
