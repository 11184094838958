/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-loader.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "@angular/common";
import * as i6 from "./top-loader.component";
import * as i7 from "@angular/router";
import * as i8 from "../services/top-loader.service";
var styles_TopLoaderComponent = [i0.styles];
var RenderType_TopLoaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TopLoaderComponent, data: {} });
export { RenderType_TopLoaderComponent as RenderType_TopLoaderComponent };
function View_TopLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "progress-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "progress-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(3, 49152, null, 0, i3.MatProgressBar, [i1.ElementRef, [2, i4.ANIMATION_MODULE_TYPE], [2, i5.Location]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).value; var currVal_1 = i1.ɵnov(_v, 3).mode; var currVal_2 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_TopLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TopLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-loader", [], null, null, null, View_TopLoaderComponent_0, RenderType_TopLoaderComponent)), i1.ɵdid(1, 245760, null, 0, i6.TopLoaderComponent, [i7.Router, i8.TopLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopLoaderComponentNgFactory = i1.ɵccf("app-top-loader", i6.TopLoaderComponent, View_TopLoaderComponent_Host_0, {}, {}, []);
export { TopLoaderComponentNgFactory as TopLoaderComponentNgFactory };
