var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EndingOutputBase } from "./ending-output-base";
import { URLType } from "../enums/url-type";
var SetStatusAfterCompleteReconfirmContactOutputData = /** @class */ (function (_super) {
    __extends(SetStatusAfterCompleteReconfirmContactOutputData, _super);
    function SetStatusAfterCompleteReconfirmContactOutputData(options) {
        var _this = _super.call(this, options) || this;
        _this.$type = "SampleCube.UserFlow.API.Models.EndingPage.DTOs.SetStatusAfterCompleteReconfirmContactResponseApiDTO, SampleCube.UserFlow.API.Models.EndingPage";
        options = options || {};
        _this.redirectUrl = options.redirectUrl || "";
        _this.urlType = options.urlType || URLType.None;
        return _this;
    }
    return SetStatusAfterCompleteReconfirmContactOutputData;
}(EndingOutputBase));
export { SetStatusAfterCompleteReconfirmContactOutputData };
