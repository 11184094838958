import { Component, OnDestroy, OnInit, ElementRef, Renderer, ViewChild } from "@angular/core";
import { Validators, FormControl } from "@angular/forms";
import { MatRadioButton } from "@angular/material";
import { Category } from "../../../../models/survey-engine/category";
import { SingleImageAnswer } from "../../../../models/survey-engine/single-image-answer";
import { SingleQuestion } from "../../../../models/survey-engine/single-question";
import "../../../../extensions/string-extensions";
import { AnswerType } from "../../../../models/survey-engine/core/answer-type.enum";
import { VideoQuestion, IVideoQuestionData } from "../../../../models/survey-engine/video-question";
import { CategoricalQuestionBaseComponent } from "../core/categorical-question-base.component";
import { NextBackService } from "../../../../services/next-back.service";
import { InjectorInstance } from "../../../../core/injector-instance";
import { debug } from "util";
import { LocalStorageService, SessionStorageService } from "ngx-store";
import { Respondent } from "../../../../models/respondent";

export interface IVideo {
  respondentToken: string,
  videoDuration: string,
  currentTime: string
}

@Component({
  selector: "app-video-question",
  templateUrl: "./video-question.component.html",
  styleUrls: ["./video-question.component.scss"]
})
export class VideoQuestionComponent extends CategoricalQuestionBaseComponent implements OnInit, OnDestroy {
  progressBarValue = 0;
  answerId: number = 0;
  respondent: Respondent = null;
  count: number = 0;
  private __nextBackService: NextBackService;
  protected setQuestionAnswer(value: any) {
    const self = this;
    self.__questionAnswer = new SingleImageAnswer({
      value: self.getCategory(value).id
    });
    self.__questionAnswer.answerType = AnswerType.SinglePunchImage;
  }

  vidplay(cid) {
    const video: any = document.getElementById("Video1_" + cid.toString() + "") as HTMLFormElement;
    if (video) {
      video.play();
    }
  }

  get categories(): Array<Category> {
    const categories: Array<Category> = [];
    (this.question as VideoQuestion).categories.$values.forEach(c => {
      categories.push(c as Category);
    });
    console.log(categories);
    return categories;
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer, private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService) {
    super();
    this.__nextBackService = InjectorInstance.get<NextBackService>(NextBackService);
  }

  updateProgressBar(obj) {
    let videoDesc = {
      respondentToken: this.respondent.respondentToken.toString(),
      videoDuration: null,
      currentTime: null
    };

    let qualificationid: string = this.inputData.categoryId.toString();

    if (this.localStorage.get("sc.videoDescriptionEnable_" + videoDesc.respondentToken + "_" + qualificationid.toString() + "") == true) {
      this.__nextBackService.enableNext(true);
    }
    const button = document.getElementById("play_" + qualificationid + "");
    button.className = '';
    var progressBar: any = document.getElementById('progressbar_' + qualificationid + '');
    var mediaPlayer: any = document.getElementById("Video1_" + qualificationid + "");

    if (mediaPlayer == null || mediaPlayer.duration == null && mediaPlayer.duration != "NaN")
      return;

    let videoData: IVideo = this.localStorage.get("sc.videoDescription_" + videoDesc.respondentToken +  "_" + qualificationid.toString() + "");
    if (!!videoData && this.count == 0) {
      if (videoData.currentTime != mediaPlayer.currentTime) {
        mediaPlayer.currentTime = videoData.currentTime;
      }
      videoDesc.currentTime = mediaPlayer.currentTime;
      this.localStorage.set("sc.videoDescription_" + videoDesc.respondentToken +  "_" + qualificationid.toString() + "", videoDesc)
    }
    else {
      videoDesc.videoDuration = mediaPlayer.duration;
      videoDesc.currentTime = mediaPlayer.currentTime;
      this.localStorage.set("sc.videoDescription_" + videoDesc.respondentToken +  "_" + qualificationid.toString() + "", videoDesc)
    }

    var percentage = Math.floor((100 / mediaPlayer.duration) * mediaPlayer.currentTime);
    progressBar.value = percentage; progressBar.innerHTML = percentage + '% played';
    this.progressBarValue = progressBar.value;
    const abc: any = document.getElementById("progress-value_" + qualificationid + "");
    var num = mediaPlayer.duration - mediaPlayer.currentTime
    const hours = Math.floor(num / 60);
    const minutes = Math.floor(num % 60);
    abc.textContent = `${hours}:${minutes}`;
    if (!!progressBar && progressBar.value == 100) {
      const button = document.getElementById("play_" + qualificationid + "");
      button.className = 'play';
      const buttonValue = this.localStorage.set("sc.videoDescriptionEnable_" + videoDesc.respondentToken + "_" + qualificationid.toString() + "", true);
      videoDesc.currentTime = 0;
      this.localStorage.set("sc.videoDescription_" + videoDesc.respondentToken +  "_" + qualificationid.toString() + "", videoDesc)
      this.__nextBackService = InjectorInstance.get<NextBackService>(NextBackService);
      this.__nextBackService.enableNext(true);
    }

    this.count++;
  }

  inputData: IVideoQuestionData = {};
  ngOnInit() {
    super.ngOnInit();
    const self = this;

    const validators = [];
    if ((self.question as SingleQuestion).validators.required) {
      validators.push(Validators.required);
    }
    self.triggerQuestionLoaded();

    (this.question as VideoQuestion).categories.$values.forEach(c => {
      this.inputData.categoryId = c.id;
      this.inputData.questionId = c.questionId;
      this.inputData.code = c.code;
      this.inputData.videoPath = c.leftText;
    });

    this.setQuestionAnswer(this.inputData.code);
    self.ctrl.setValue(this.inputData.code);
    setTimeout(() => {
      const video: any = this.elementRef.nativeElement.querySelector(".Video1_" + this.inputData.categoryId + "");
      video.addEventListener('timeupdate', this.updateProgressBar.bind(this));
      var canvas: any = document.getElementById("canvas_" + this.inputData.categoryId + "");
      var ctx = canvas.getContext('2d');
      video.addEventListener('play', function () {
        var $this = this; //cache
        (function loop() {
          if (!$this.paused && !$this.ended) {
            
            canvas.height = $this.videoHeight;
            canvas.width = $this.videoWidth;
            ctx.drawImage($this, 0, 0, canvas.width, canvas.height);
            setTimeout(loop, 1000 / 30); // drawing at 30fps
          }
        })();
      }, 0);
    }, 1);

    this.respondent = this.sessionStorage.get("sc.respondent");
    if (!this.respondent) {
      this.respondent = this.sessionStorage.get("sc.respondent.test");
    }
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
      //     this.video.nativeElement.srcObject = stream;
      //     this.video.nativeElement.play();
      // });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }


  categoryChanged(option: MatRadioButton, i): void {
  }
  public imageId = 0;
}
