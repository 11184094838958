import { CategoricalAnswerCollection, ICategoricalAnswerCollection } from './core/categorical-answer';
import { IQuestionAnswer, QuestionAnswerBase } from './core/question-answer-base';

export interface IMultiImageAnswer extends IQuestionAnswer {
  values: ICategoricalAnswerCollection;
}

export class MultiImageAnswer extends QuestionAnswerBase implements MultiImageAnswer {
  $type: string = "SampleCube.UserFlow.API.Models.SurveyPage.MultiPunchImageAnswerApiModel, SampleCube.UserFlow.API.Models.SurveyPage";

  values: ICategoricalAnswerCollection = null;
  constructor(options?: MultiImageAnswer) {
    super(options as IQuestionAnswer);
    options = options || ({} as MultiImageAnswer);
    this.values = new CategoricalAnswerCollection(options.values || {});
  }
}
