var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import '../../../../extensions/string-extensions';
import { OnDestroy, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDatepicker, DateAdapter } from '@angular/material';
import * as moment from 'moment';
import { Message } from '../../../../localization/message';
import { OpenQuestionBaseComponent } from '../core/open-end-question-base.component';
export var MY_FORMATS = {
    parse: {
        dateInput: "MM/YYYY"
    },
    display: {
        dateInput: "MM/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY"
    }
};
var CalendarQuestionComponent = /** @class */ (function (_super) {
    __extends(CalendarQuestionComponent, _super);
    function CalendarQuestionComponent(dateAdapter) {
        var _this = _super.call(this) || this;
        _this.dateAdapter = dateAdapter;
        _this._minDate = 0;
        _this._maxDate = 0;
        return _this;
    }
    Object.defineProperty(CalendarQuestionComponent.prototype, "minDate", {
        get: function () {
            return moment(this._minDate).format("MM/YYYY");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CalendarQuestionComponent.prototype, "maxDate", {
        get: function () {
            return moment(this._maxDate).format("MM/YYYY");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CalendarQuestionComponent.prototype, "minDateErrorMessage", {
        get: function () {
            return this.__minDateErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CalendarQuestionComponent.prototype, "maxDateErrorMessage", {
        get: function () {
            return this.__maxDateErrorMessage;
        },
        enumerable: true,
        configurable: true
    });
    CalendarQuestionComponent.prototype.setLocale = function (val) {
        this.dateAdapter.setLocale(val);
    };
    CalendarQuestionComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        var self = this;
        // self.dateAdapter.setLocale("zh");
        var question_validators = self.question.validators;
        var validators = [];
        if (question_validators.required) {
            validators.push(Validators.required);
        }
        if (question_validators.minDate) {
            self._minDate = question_validators.minDate;
        }
        if (question_validators.maxDate) {
            self._maxDate = question_validators.maxDate;
        }
        Object.keys(question_validators).forEach(function (validator) {
            if (validator !== "required" && question_validators[validator] && CustomCalendarValidators[validator]) {
                validators.push(CustomCalendarValidators[validator].apply(undefined, [question_validators[validator]]));
            }
        });
        self.ctrl.setValidators(validators);
        self.ctrl.statusChanges.subscribe(function (status) {
            if (status === "INVALID") {
                self.picker.startAt = moment();
            }
            else {
                self.picker.startAt = self.picker._selected;
            }
        });
        self.__minDateErrorMessage = String.format(Message.MinDateExceededMessage, "Value", this.minDate);
        self.__maxDateErrorMessage = String.format(Message.MaxDateExceededMessage, "Value", this.maxDate);
    };
    CalendarQuestionComponent.prototype.ngAfterViewInit = function () {
        var self = this;
        if (self.question.response && self.question.response.value) {
            var response_1 = self.question.response.value;
            var dateParts = response_1.split("/");
            if (dateParts.length === 2) {
                dateParts.splice(1, 0, "01");
                response_1 = dateParts.join("/");
            }
            setTimeout(function () {
                var d = moment(new Date(response_1));
                self.picker.startAt = d;
                self.picker._select(d);
                self.triggerQuestionLoaded();
            }, 100);
        }
        else {
            self.triggerQuestionLoaded();
            self.picker.startAt = moment();
        }
    };
    CalendarQuestionComponent.prototype.openPicker = function ($event) {
        var opened = this.picker.opened;
        if (!opened) {
            this.picker.open();
            $event.preventDefault();
        }
    };
    CalendarQuestionComponent.prototype.chosenMonthHandler = function (newDate) {
        this.picker._select(newDate);
        this.picker.startAt = newDate;
        this.picker.close();
    };
    CalendarQuestionComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return CalendarQuestionComponent;
}(OpenQuestionBaseComponent));
export { CalendarQuestionComponent };
var CustomCalendarValidators = /** @class */ (function () {
    function CustomCalendarValidators() {
    }
    CustomCalendarValidators.minDate = function (nr) {
        return function (fc) {
            var m_value = fc.value;
            var value = m_value ? m_value.valueOf() : null;
            return !!value && value >= nr
                ? null
                : {
                    minDate: true
                };
        };
    };
    CustomCalendarValidators.maxDate = function (nr) {
        return function (fc) {
            var m_value = fc.value;
            var value = m_value ? m_value.valueOf() : null;
            return !!value && value <= nr
                ? null
                : {
                    maxDate: true
                };
        };
    };
    return CustomCalendarValidators;
}());
